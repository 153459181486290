import React, { useEffect, useState } from 'react';
import { _get } from '../../../utils/lodashUtils';
import { Table, Typography } from 'antd';
import { postAPI } from '../../../utils/apiRequest';
import { MANUFACTURER_CODE_LIST_NEW } from '../../../constants/api';
import { generatePaginationObj } from '../../../utils/helper';

const { Title, Text } = Typography;

const tableColumns = (offset) => [
  {
    title: 'Sr. No.',
    key: 'serial_no',
    width: 100,
    render: (item, record, index) => <Text>{offset + index + 1}</Text>,
  },
  {
    title: 'Code',
    key: 'code',
    width: 150,
    dataIndex: 'code',
  },
  {
    title: 'Description',
    key: 'description',
    dataIndex: 'description',
  },
];

const ManufacturerCode = () => {
  const [codeData, setCodeData] = useState({
    data: null,
    pagination: {
      offset: 0,
      limit: 10,
      total: 0,
      currrent: 1,
    },
  });
  const [loading, setLoading] = useState(false);

  const fetchManufacturerCode = (page = 1, limit = 10) => {
    setLoading(true);
    const pageObj = generatePaginationObj(page, limit);

    const suucessFn = function (result) {
      setLoading(false);
      setCodeData({
        data: _get(result, 'data'),
        pagination: {
          ...pageObj,
          total: _get(result, 'total'),
          currrent: page,
        },
      });
    };

    const errorFn = function (error) {
      setLoading(false);
    };

    postAPI(MANUFACTURER_CODE_LIST_NEW, { ...pageObj }, suucessFn, errorFn);
  };

  useEffect(() => {
    fetchManufacturerCode();
  }, []);

  return (
    <>
      <Title className={'page-title'}>Manufacturer Codes</Title>
      <Table
        className='data-table full-height-table mt-20'
        size={'small'}
        scroll={{ y: 'calc(100vh - 400px)' }}
        loading={loading}
        rowKey={'id'}
        dataSource={_get(codeData, 'data')}
        columns={tableColumns(_get(codeData, 'pagination.offset'))}
        showSorterTooltip={false}
        pagination={{
          showSizeChanger: false,
          position: ['bottomRight'],
          hideOnSinglePage: true,
          pageSize: _get(codeData, 'pagination.limit'),
          total: _get(codeData, 'pagination.total'),
          current: _get(codeData, 'pagination.current'),
        }}
        onChange={(paginationObj) => {
          fetchManufacturerCode(
            _get(paginationObj, 'current'),
            _get(codeData, 'pagination.limit')
          );
        }}
      />
    </>
  );
};

export default ManufacturerCode;
