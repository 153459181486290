import debounce from "lodash/debounce";
import get from "lodash/get";
import map from "lodash/map";
import filter from "lodash/filter";
import indexOf from "lodash/indexOf";
import pullAt from "lodash/pullAt";

export const _debounce = debounce;
export const _get = get;
export const _map = map;
export const _filter = filter;
export const _indexOf = indexOf;
export const _pullAt = pullAt;
