import { Input, Switch } from 'antd';
import React from 'react';
import { _get } from '../utils/lodashUtils';

export const maintenanceTableColumns = (setRectificationData, updateStatus,) => [
    {
        title: "Status",
        key: 'is_active',
        // dataIndex: 'is_active',
        render: (record) => (
            <Switch
                checked={true}
                onChange={() => updateStatus(true, _get(record, 'id'))}
            />
        ),
    },
    {
        title: 'Item Id',
        key: 'item_id',
        dataIndex: 'item_id',
    },
    {
        title: 'MPN',
        key: 'mpn',
        dataIndex: ["ItemMaster", "manufacturer_item_number"],
    },
    {
        title: 'SPN',
        key: 'spn',
        dataIndex: ["ItemMaster", "spn"],
    },
    {
        title: 'Sync Connector',
        key: 'sync_connector',
        dataIndex: ["SyncConnector", "name"],
    },
    {
        title: 'CPN',
        key: 'cpn',
        render: (record) => (
            <Input
                showCount
                maxLength={_get(record, 'SyncConnector.SyncConnectorType.max_spn_length')}
                onChange={(e) => {
                    if(e.target.value){
                        setRectificationData((prev) => {
                            return {
                                ...prev,
                                [_get(record, 'id')] : {
                                    bad_item_id: _get(record, 'item_id'),
                                    sync_connector_id: _get(record, 'sync_connector_id'),
                                    connector_part_number: e.target.value,
                                }
                            }
                        })
                    } else {
                        console.log("Else")
                        setRectificationData((prev) => {
                            const data = {...prev};
                            delete data[_get(record, 'id')];

                            return {...data};
                        })
                    }
                }}
            />
        )
    },
    {
        title: 'Despcription',
        key: 'despcription',
        dataIndex: ["ItemMaster", "description"],
    },
];