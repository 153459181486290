import { PAGES_CODE as PAGES } from '../../constants/hardData';

export const GLOBAL_PAGES_PAGINATION_DEFAULT_STATE = {
  [PAGES.BATCH_ALL]: { limit: 500 },
  [PAGES.BATCH_ARCHIVED]: { limit: 500 },
  [PAGES.BATCH_ITEMS_ARCHIVED]: { limit: 100 },
  [PAGES.BATCH_ITEMS_UPDATED]: { limit: 100 },
  [PAGES.BATCH_ITEMS_DISCONTINUED]: { limit: 100 },
  [PAGES.BATCH_ITEMS_UNCHANGED]: { limit: 100 },
  [PAGES.BATCH_ITEMS_NEW]: { limit: 100 },
  [PAGES.BATCH_ITEMS_NEW_ARCHIVED]: { limit: 100 },
  [PAGES.BATCH_ITEMS_UPDATED_ARCHIVED]: { limit: 100 },
  [PAGES.BATCH_ITEMS_UNCHANGED_ARCHIVED]: { limit: 100 },
  [PAGES.CATALOG]: { limit: 100 },
  [PAGES.CATAGORIES_MAPPING]: { limit: 500 },
  [PAGES.CATAGORIES_LABOR]: { limit: 10 },
  [PAGES.MANUFACTURER]: { limit: 500, sortBy: 'name', sortDir: 'ASC' },
  [PAGES.USERS]: { limit: 10, filters: { is_active: true } },
  [PAGES.SYSTEM_API_KEYS]: { limit: 10 },
  [PAGES.SYSTEM_SYNC_CONNECTORS]: { limit: 10 },
};
export const GLOBAL_PAGES_TOGGLE_FILTERS_DEFAULT_STATE = {
  [PAGES.BATCH_ALL]: true,
  [PAGES.BATCH_ARCHIVED]: true,
  [PAGES.BATCH_ITEMS_ARCHIVED]: true,
  [PAGES.BATCH_ITEMS_UPDATED]: true,
  [PAGES.BATCH_ITEMS_DISCONTINUED]: true,
  [PAGES.BATCH_ITEMS_UNCHANGED]: true,
  [PAGES.BATCH_ITEMS_NEW]: true,
  [PAGES.BATCH_ITEMS_NEW_ARCHIVED]: true,
  [PAGES.BATCH_ITEMS_UPDATED_ARCHIVED]: true,
  [PAGES.BATCH_ITEMS_UNCHANGED_ARCHIVED]: true,
  [PAGES.CATALOG]: true,
  [PAGES.CATAGORIES_MAPPING]: true,
  [PAGES.CATAGORIES_LABOR]: true,
  [PAGES.MANUFACTURER]: true,
  [PAGES.USERS]: true,
  [PAGES.SYSTEM_API_KEYS]: true,
  [PAGES.SYSTEM_SYNC_CONNECTORS]: true,
};
export const GLOBAL_PAGES_ACTIVE_FILTER_DEFAULT_STATE = {
  [PAGES.BATCH_ALL]: {},
  [PAGES.BATCH_ARCHIVED]: {},
  [PAGES.BATCH_ITEMS_ARCHIVED]: {},
  [PAGES.BATCH_ITEMS_UPDATED]: {},
  [PAGES.BATCH_ITEMS_DISCONTINUED]: {},
  [PAGES.BATCH_ITEMS_UNCHANGED]: {},
  [PAGES.BATCH_ITEMS_NEW]: {
    missing_labor_category: false,
  },
  [PAGES.BATCH_ITEMS_NEW_ARCHIVED]: { missing_labor_category: false },
  [PAGES.BATCH_ITEMS_UPDATED_ARCHIVED]: {},
  [PAGES.BATCH_ITEMS_UNCHANGED_ARCHIVED]: {},
  [PAGES.CATALOG]: {},
  [PAGES.CATAGORIES_MAPPING]: {},
  [PAGES.CATAGORIES_LABOR]: {},
  [PAGES.MANUFACTURER]: {},
  [PAGES.USERS]: {
    is_active: true,
  },
  [PAGES.SYSTEM_API_KEYS]: {},
  [PAGES.SYSTEM_SYNC_CONNECTORS]: {},
};
