import React, { useEffect, useState } from 'react';
import {
  Button,
  Card,
  Col,
  Dropdown,
  Form,
  Input,
  Row,
  Select,
  Space,
  Switch,
  Table,
  Tooltip,
  Typography,
} from 'antd';
import {
  CaretLeftOutlined,
  CaretRightOutlined,
  CloseOutlined,
  DownOutlined,
  FormOutlined,
  PlusOutlined,
  SlidersOutlined,
  SyncOutlined,
} from '@ant-design/icons';
import { _get } from '../../../utils/lodashUtils';
import {
  LABOR_CATEGORY_ADD_SINGLE_DATA,
  LABOR_CATEGORY_LIST,
  LABOR_CATEGORY_SINGLE_DATA,
} from '../../../constants/api';
import { patchAPI, postAPI } from '../../../utils/apiRequest';
import { interpolate } from '../../../utils/common';
import {
  ACTIVE_INACTIVE_FILTER_LIST,
  DEFAULT_TABLE_PAGINATION_DROPDOWN_PAGE_COUNT,
  PAGES_CODE as PAGES,
} from '../../../constants/hardData';
import { REQUIRED_FIELD_WITH_NAME_MESSAGE } from '../../../constants/message';
import { useDispatch, useSelector } from 'react-redux';
import {
  globalPagesFilterData,
  globalPagesFilterToggle,
  globalPagesPagination,
} from '../../../redux/actions/globalReducActions';
import ModalCom from '../../common/Modal';

const { Title } = Typography;

function LaborManagement(props) {
  const [toggleEditLaborModal, setToggleEditLaborModal] = useState(false);
  const [checkAddLabor, setCheckAddLabor] = useState(false);
  const [laborData, setLaborData] = useState({});
  const [laborDataLoading, toggleLaborDataLoading] = useState(false);
  const [laborDataActionLoading, toggleLaborDataActionLoading] =
    useState(false);
  const tablePaginationParams = useSelector(
    (state) =>
      _get(state, 'global.globalPagesPagination')[PAGES.CATAGORIES_LABOR]
  );
  const toggleFilters = useSelector(
    (state) =>
      _get(state, 'global.globalPagesFilterToggle')[PAGES.CATAGORIES_LABOR]
  );
  const activeFilters = useSelector(
    (state) =>
      _get(state, 'global.globalPagesFilterData')[PAGES.CATAGORIES_LABOR]
  );
  const dispatch = useDispatch();
  const setTablePaginationParams = (param) => {
    if (param && typeof param == 'function') {
      dispatch(
        globalPagesPagination(
          PAGES.CATAGORIES_LABOR,
          param(tablePaginationParams)
        )
      );
    } else {
      dispatch(globalPagesPagination(PAGES.CATAGORIES_LABOR, param));
    }
  };
  const setToggleFilters = (param) => {
    if (param && typeof param == 'function') {
      dispatch(
        globalPagesFilterToggle(PAGES.CATAGORIES_LABOR, param(toggleFilters))
      );
    } else {
      dispatch(globalPagesFilterToggle(PAGES.CATAGORIES_LABOR, param));
    }
  };
  const setActiveFilters = (param) => {
    if (param && typeof param == 'function') {
      dispatch(
        globalPagesFilterData(PAGES.CATAGORIES_LABOR, param(activeFilters))
      );
    } else {
      dispatch(globalPagesFilterData(PAGES.CATAGORIES_LABOR, param));
    }
  };
  useEffect(() => {
    loadLaborsData();
  }, [tablePaginationParams]);
  const tableColumns = [
    {
      title: 'Edit',
      key: 'Actions',
      width: 60,
      render: (value, record) => (
        <Space>
          <Tooltip title={'Edit'} overlayClassName='tooltip'>
            <FormOutlined onClick={() => setToggleEditLaborModal(record)} />
          </Tooltip>{' '}
        </Space>
      ),
    },
    {
      title: 'Labor Category',
      key: 'name',
      dataIndex: 'name',
      render: (value) => value || '-',
    },
  ];
  const loadLaborsData = () => {
    toggleLaborDataLoading(true);
    let params = {
      ...tablePaginationParams,
    };
    let successFn = function (result) {
      setLaborData(result);
      toggleLaborDataLoading(false);
    };
    let errorFn = function () {
      toggleLaborDataLoading(false);
    };
    postAPI(LABOR_CATEGORY_LIST, params, successFn, errorFn);
  };
  const deleteLabor = (data) => {};
  const updateLabor = (id, data) => {
    let reqData = {
      ...data,
      is_active: undefined,
    };
    toggleLaborDataActionLoading(true);
    let successFn = function () {
      toggleLaborDataActionLoading(false);
      setToggleEditLaborModal(false);
      loadLaborsData();
    };
    let errorFn = function () {
      toggleLaborDataActionLoading(false);
    };
    patchAPI(
      interpolate(LABOR_CATEGORY_SINGLE_DATA, [id]),
      reqData,
      successFn,
      errorFn
    );
  };
  const addLabor = (data) => {
    let reqData = {
      ...data,
      is_active: undefined,
    };
    toggleLaborDataActionLoading(true);
    let successFn = function () {
      toggleLaborDataActionLoading(false);
      setToggleEditLaborModal(false);
      setCheckAddLabor(false);
      loadLaborsData();
    };
    let errorFn = function () {
      toggleLaborDataActionLoading(false);
    };
    postAPI(LABOR_CATEGORY_ADD_SINGLE_DATA, reqData, successFn, errorFn);
  };
  const setTableSortingParams = (field, order) => {
    setTablePaginationParams((prevState) => {
      return {
        ...prevState,
        sortBy: order ? field : undefined,
        sortDir: order ? (order === 'ascend' ? 'ASC' : 'DESC') : undefined,
      };
    });
  };

  const setDropDownItems = () => {
    const dropdownItems = DEFAULT_TABLE_PAGINATION_DROPDOWN_PAGE_COUNT.map(
      (item) => {
        return {
          ...item,
          label: (
            <Button
              type='text'
              style={{ width: '100%' }}
              onClick={() => {
                setTablePaginationParams({
                  ...tablePaginationParams,
                  limit: +item.key,
                });
              }}
            >
              {item.label}
            </Button>
          ),
        };
      }
    );
    return dropdownItems;
  };

  return (
    <Card>
      <>
        <Title className={'page-title'} level={2}>
          Labor Categories
        </Title>
        <Table
          className='data-table full-height-table'
          size={'small'}
          scroll={{ y: window.innerHeight - 450 }}
          pagination={false}
          onChange={(a, b, sorting) =>
            setTableSortingParams(sorting.field, sorting.order)
          }
          rowKey={'id'}
          dataSource={_get(laborData, 'data', [])}
          columns={tableColumns}
          showSorterTooltip={false}
          loading={laborDataLoading}
          title={() => (
            <Row justify={'end'}>
              <Col>
                <Button
                  size={'large'}
                  onClick={() => setToggleFilters(!toggleFilters)}
                >
                  {toggleFilters ? <CloseOutlined /> : <SlidersOutlined />}
                </Button>
                &nbsp;&nbsp;
                <Button
                  size='large'
                  icon={<PlusOutlined />}
                  type={'primary'}
                  onClick={() => {
                    setCheckAddLabor(true);
                    setToggleEditLaborModal(true);
                  }}
                >
                  Add New Labor Category
                </Button>
                &nbsp;&nbsp;
                <Button
                  size={'large'}
                  type={'secondary'}
                  icon={<SyncOutlined />}
                  onClick={loadLaborsData}
                >
                  Refresh
                </Button>
              </Col>
              {toggleFilters && (
                <Col span={24}>
                  <Form
                    className='filter-form'
                    onFinish={() =>
                      setTablePaginationParams({
                        ...tablePaginationParams,
                        offset: 0,
                        filters: { ...activeFilters },
                      })
                    }
                  >
                    <Space className={'mt-10 mb-10 ml-10 mr-10'}>
                      <Input
                        value={activeFilters.name}
                        placeholder='Category'
                        onChange={(e) =>
                          setActiveFilters((prev) => {
                            return { ...prev, name: e.target.value };
                          })
                        }
                      />
                      <Form.Item>
                        <Button type={'primary'} htmlType={'submit'}>
                          Apply
                        </Button>
                      </Form.Item>
                      <Button
                        type={'secondary'}
                        onClick={() => {
                          setActiveFilters({});
                          setTablePaginationParams({
                            ...tablePaginationParams,
                            filters: {},
                          });
                        }}
                      >
                        Clear
                      </Button>
                    </Space>
                  </Form>
                </Col>
              )}
            </Row>
          )}
          footer={() => (
            <Row justify={'space-between'}>
              <Col>
                <Space>
                  <Dropdown
                    menu={{ items: setDropDownItems() }}
                    trigger={['click']}
                  >
                    <Button type='ghost'>
                      Items Per Page:{_get(tablePaginationParams, 'limit')}
                      <DownOutlined className={'ml-5'} />
                    </Button>
                  </Dropdown>
                  <Typography>{`${_get(laborData, 'offset', 0) + 1} to ${
                    _get(laborData, 'data', []).length +
                    _get(laborData, 'offset', '--')
                  } of ${_get(laborData, 'total', '--')} items`}</Typography>
                </Space>
              </Col>
              <Col>
                <Button.Group>
                  <Button
                    type='ghost'
                    icon={<CaretLeftOutlined />}
                    onClick={() =>
                      setTablePaginationParams({
                        ...tablePaginationParams,
                        offset:
                          _get(laborData, 'offset', 0) -
                          _get(laborData, 'limit', 0),
                      })
                    }
                    disabled={!_get(laborData, 'offset', 0)}
                  />
                  <Button
                    type='ghost'
                    icon={<CaretRightOutlined />}
                    onClick={() =>
                      setTablePaginationParams({
                        ...tablePaginationParams,
                        offset:
                          _get(laborData, 'offset', 0) +
                          _get(laborData, 'limit', 0),
                      })
                    }
                    disabled={
                      _get(laborData, 'offset', 0) +
                        _get(laborData, 'limit', 0) >=
                      _get(laborData, 'total', 0)
                    }
                  />
                </Button.Group>
              </Col>
            </Row>
          )}
        />
        <ModalCom
          open={toggleEditLaborModal}
          title={`${checkAddLabor ? 'Add New' : 'Edit'} Labor Category`}
          onCancel={() => {
            setToggleEditLaborModal(false);
            setCheckAddLabor(false);
          }}
          footer={null}
        >
          <Form
            layout='vertical'
            key={_get(toggleEditLaborModal, 'id')}
            initialValues={checkAddLabor ? {} : { ...toggleEditLaborModal }}
            onFinish={(values) =>
              checkAddLabor
                ? addLabor(values)
                : updateLabor(_get(toggleEditLaborModal, 'id'), values)
            }
          >
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item
                  label='Labor Category Name'
                  name='name'
                  rules={[
                    {
                      required: true,
                      message: REQUIRED_FIELD_WITH_NAME_MESSAGE,
                    },
                    {
                      min: 3,
                      max: 50,
                      message:
                        'Labor Category Name must be 3 to 50 characters long',
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16} justify={'end'}>
              <Col>
                <Form.Item>
                  <Button
                    type='primary'
                    htmlType='submit'
                    loading={laborDataActionLoading}
                  >
                    &nbsp;{`${checkAddLabor ? 'Add' : 'Update'}`}&nbsp;
                  </Button>
                </Form.Item>
              </Col>
              <Col>
                <Button
                  type='secondary'
                  onClick={() => setToggleEditLaborModal(false)}
                >
                  {' '}
                  Cancel
                </Button>
              </Col>
            </Row>
          </Form>
        </ModalCom>
      </>
    </Card>
  );
}

export default LaborManagement;
