import { createSlice } from "@reduxjs/toolkit";
import { MANUFACTURER_FILTER_LIST } from "../reduxConstant";
import { MANUFACTURER_LIST } from "../../constants/api";
import { postAPI } from "../../utils/apiRequest";

export const manufacturerFilterListSlice = createSlice({
    name: MANUFACTURER_FILTER_LIST,
    initialState: {
        data: {}
    },
    reducers: {
        setManufacturerFilterList: (state, action) => {
            state.data = action.payload
        }
    }
})

export const getManufacturerFilterData = () => dispatch => {
    let successFn = function(result) {
        dispatch(setManufacturerFilterList(result));
    };
    let errorFn = function() {
    };
    postAPI(MANUFACTURER_LIST, { limit: 0, sortBy: "name", sortDir: "ASC" }, successFn, errorFn);
}

export const { setManufacturerFilterList } = manufacturerFilterListSlice.actions;

export default manufacturerFilterListSlice.reducer;