import React, { Component } from 'react';
import AuthBase from '../AuthBase';
import { Button, Col, Divider, Form, Input, Row, Spin } from 'antd';
import { postOuterAPI } from '../../../../utils/apiRequest';
import { displayMessage, parseQueryString } from '../../../../utils/common';
import { SUCCESS_MSG_TYPE } from '../../../../constants/dataKeys';
import { RESET_PASSWORD } from '../../../../constants/api';
import { withParamsAndNavigate } from '../../../../utils/hoc';
import { Link, Navigate } from 'react-router-dom';
import _get from 'lodash/get';
import { PASSWORD_FIELD_VALIDATION_MESSAGE, REQUIRED_FIELD_WITH_NAME_MESSAGE } from '../../../../constants/message';

class ForgotPasswordLink extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      linkVerified: false,
    };
  }

  onFinish = (values) => {
    let that = this;
    let reqData = {
      ...values,
    };
    that.setState({
      loading: true,
    });
    let location = parseQueryString(this.props.params.search);
    reqData.token = location.token;
    reqData.id = +_get(this.props, 'location.id');
    let successFn = function(result) {
      that.setState({
        loading: false,
        linkVerified: true,
      });
      displayMessage(SUCCESS_MSG_TYPE, 'Password Changed Successfully');
    };
    let errorFn = function(error) {
      that.setState({
        loading: false,
      });
    };
    delete reqData.repeat_password;
    postOuterAPI(decodeURIComponent(location.service_url) + '/' + RESET_PASSWORD, reqData, successFn, errorFn);
  };

  render() {
    let that = this;
    const { loading, linkVerified } = this.state;
    if (linkVerified)
      return <Navigate to={'/login'} />;
    return (
      <AuthBase>
        <Row>
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <h2 className={'auth-type-title'}>Forgot Password</h2>
            <Spin spinning={loading}>
              <p className='mt-10-n'>Please enter new password</p>
              <Form
                layout={'vertical'}
                id={'components-form-demo-normal-login'}
                name='reset-password'
                size={'large'}
                onFinish={this.onFinish}
              >
                <Form.Item label={'Password'} name={'new_password'}
                           rules={[{ required: true, message: REQUIRED_FIELD_WITH_NAME_MESSAGE }, {
                             message: PASSWORD_FIELD_VALIDATION_MESSAGE, pattern: new RegExp(
                             '^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[;,=!@#$%^&*()_-])(?=.{12,})',
                             ),
                           }]}>
                  <Input.Password visibilityToggle={false} />
                </Form.Item>
                <Form.Item
                  name='repeat_password'
                  label='Repeat Password'
                  dependencies={['password']}
                  rules={[
                    {
                      required: true,
                      message: 'Please confirm your password!',
                    },
                    ({ getFieldValue }) => ({
                      validator(rule, value) {
                        if (!value || getFieldValue('new_password') === value) {
                          return Promise.resolve();
                        }
                        return Promise.reject(
                          'The two passwords that you entered do not match!',
                        );
                      },
                    }),
                  ]}
                >
                  <Input.Password visibilityToggle={false} />
                </Form.Item>
                <Form.Item>
                  <Button
                    type={'secondary'}
                    className='login-form-button theme-color'
                    htmlType='submit' loading={loading}
                  >
                    Submit
                  </Button>
                </Form.Item>
              </Form>
              <Divider />
              <Link to={'/login'}>Back to Login</Link>
            </Spin>
          </Col>
        </Row>
      </AuthBase>
    );
  }
}

export default withParamsAndNavigate(ForgotPasswordLink);
