import AppBase from '../../base/AppBase';
import React, { useEffect, useRef, useState } from 'react';
import { Card, Col, Row, Spin, Typography } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { getAPI } from '../../../utils/apiRequest';
import { releaseNotesAction } from '../../../redux/actions/releaseNotes/releaseNotesAction';
import { RELEASE_NOTES_LIST } from '../../../constants/api';
import { setMessage } from '../../../redux/Slices/messageSlice';
import { TYPE_ERROR } from '../../../constants/message';
import { changeApiLoader } from '../../../redux/Slices/apiLoaderSlice';
import moment from 'moment';

const { Title } = Typography;

function ReleaseNotes() {
  const [versions, setVersions] = useState([]);
  // const allVersions = useSelector((state) => state.releaseNotesReducer);
  const [activeVersion, setActiveVersion] = useState({});
  const noteRef = useRef(null);

  const apiLoader = useSelector((state) => state.apiLoader.bool);
  const dispatch = useDispatch();

  useEffect(() => {
    const getReleaseNotes = () => {
      dispatch(changeApiLoader(true));
      const successFn = (data) => {
        dispatch(changeApiLoader(false));
        if (data.data && data.data.length > 0) {
          const d = data.data.map((item, index) =>
            index === 0 ? { ...item, active: true } : { ...item, active: false }
          );
          dispatch(releaseNotesAction(data));
          setVersions(d);
          setActiveVersion(d[0]);
        }
      };

      const errorFn = () => {
        dispatch(changeApiLoader(false));
        dispatch(
          setMessage({
            type: TYPE_ERROR,
            msg: 'Something went wrong',
          })
        );
      };

      getAPI(RELEASE_NOTES_LIST, successFn, errorFn);
    };

    getReleaseNotes();
  }, []);

  useEffect(() => {
    if (noteRef.current) {
      const images = noteRef.current.querySelectorAll('img');
      images.forEach((img) => {
        img.style.maxHeight = '100%';
        img.style.maxWidth = 'calc(100vw - 40%)';
      });
    }
  }, [activeVersion]);

  const activeVersionTab = (v, i) => {
    const allVersions = versions.map((item, index) => {
      return index === i
        ? { ...item, active: true }
        : { ...item, active: false };
    });
    setVersions(allVersions);
    setActiveVersion(v);
  };

  const getVersionTabStyle = (item, index) => {
    const defaultStyle = { marginBottom: '12px', cursor: 'pointer' };
    return item.active
      ? { ...defaultStyle, backgroundColor: '#FFCA33', color: 'white' }
      : { ...defaultStyle, backgroundColor: 'white', color: 'black' };
  };

  const getActiveVersion = () => {
    return (
      <div style={{ borderBottom: '12px', borderBottomColor: '#fff' }}>
        <span>{activeVersion.title}</span>
        <br></br>
        <span style={{ fontSize: '14px', fontWeight: 'normal' }}>
          <span style={{ fontWeight: 'lighter' }}>Version:</span>{' '}
          {activeVersion.version}
        </span>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <span style={{ fontSize: '14px', fontWeight: 'normal' }}>
          <span style={{ fontWeight: 'lighter' }}>Published On:</span>{' '}
          {moment(activeVersion.date).format('MM-DD-YYYY')}
        </span>
      </div>
    );
  };

  return (
    <AppBase>
      <Title className={'page-title'}>Release Notes</Title>
      <Spin spinning={apiLoader}>
        {versions.length > 0 ? (
          <Row gutter={8}>
            <Col xxl={4} xl={5} style={{ height: '63vh' }}>
              <Card
                className='release-notes-side-panel'
                loading={versions.length ? false : true}
              >
                {versions.map((item, index) => {
                  return (
                    <>
                      <Card
                        style={{
                          ...getVersionTabStyle(item, index),
                        }}
                        className='release-version-tab'
                        key={index}
                        onClick={() => activeVersionTab(item, index)}
                        hoverable={true}
                      >
                        <div style={{ fontSize: '14px', fontWeight: 'bold' }}>
                          Version:{' '}
                          <span style={{ fontWeight: 'normal' }}>
                            {item.version}
                          </span>
                        </div>
                        <span>
                          Published On: {moment(item.date).format('MM-DD-YYYY')}
                        </span>
                      </Card>
                    </>
                  );
                })}
              </Card>
            </Col>
            <Col xxl={20} xl={19}>
              <Card
                style={{
                  minWidth: '100%',
                  width: '100%',
                }}
                title={getActiveVersion()}
                hoverable={true}
              >
                <div
                  ref={noteRef}
                  dangerouslySetInnerHTML={{ __html: activeVersion.note }}
                ></div>
              </Card>
            </Col>
          </Row>
        ) : (
          <Row>
            <Col span={24}>
              <Typography.Title
                style={{ textAlign: 'center', marginTop: '10%' }}
              >
                {!apiLoader ? 'No release notes available at the moment' : ''}
              </Typography.Title>
            </Col>
          </Row>
        )}
      </Spin>
    </AppBase>
  );
}

export default ReleaseNotes;
