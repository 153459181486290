import { createSlice } from '@reduxjs/toolkit';
import { EDIT_CATALOG_TABS } from '../reduxConstant';
import { postAPI } from '../../utils/apiRequest';
import {
  CATALOG_DISTRIBUTOR_LIST,
  CATALOG_LABOR_LIST,
} from '../../constants/api';
import { changeApiLoader } from './apiLoaderSlice';
import { interpolate } from '../../utils/common';

export const editCatalogTabSlice = createSlice({
  name: EDIT_CATALOG_TABS,
  initialState: {
    distributor: {},
    labors: {},
    distributorPagination: {
      current: 1,
    },
  },
  reducers: {
    setEditCatalogDistributorData: (state, action) => {
      state.distributor = action.payload;
    },
    setEditCatalogLaborData: (state, action) => {
      state.labor = action.payload;
    },
    setDistributorPagination: (state, action) => {
      state.distributorPagination = action.payload;
    },
  },
});

export const getEditCatalogDistributorData = (params) => (dispatch) => {
  dispatch(changeApiLoader(true));
  let successFn = function (result) {
    dispatch(setEditCatalogDistributorData(result));
    dispatch(changeApiLoader(false));
  };
  let errorFn = function () {
    dispatch(changeApiLoader(false));
  };
  postAPI(
    interpolate(CATALOG_DISTRIBUTOR_LIST, [params.id]),
    {
      filters: params.filterValues,
      ...params.sorters,
      ...params.paginationVals,
    },
    successFn,
    errorFn
  );
};

export const getEditCatalogLaborData = (params) => (dispatch) => {
  changeApiLoader(true);
  let successFn = function (result) {
    dispatch(setEditCatalogLaborData(result));
    changeApiLoader(false);
  };
  let errorFn = function () {
    changeApiLoader(false);
  };
  postAPI(interpolate(CATALOG_LABOR_LIST, [params.id]), {}, successFn, errorFn);
};

export const {
  setEditCatalogDistributorData,
  setEditCatalogLaborData,
  setDistributorPagination,
} = editCatalogTabSlice.actions;

export default editCatalogTabSlice.reducer;
