import React from 'react';
import {
  Row,
  Button,
  Col,
  Form,
  InputNumber,
  Select,
  Space,
  Tabs,
  DatePicker,
} from 'antd';
import {
  CloseOutlined,
  SlidersOutlined,
  SyncOutlined,
} from '@ant-design/icons';
import { _get } from '../../../utils/lodashUtils';
import moment from 'moment';
import {
  ALL_BATCHES_STATUS_FILTER_LIST,
  ARCHIVED_BATCHES_STATUS_FILTER_LIST,
  PAGES_CODE as PAGES,
} from '../../../constants/hardData';

function BatchTableTitle(props) {
  const {
    setActiveTab,
    activeTab,
    tabItems,
    setToggleFilters,
    toggleFilters,
    loadBatchesData,
    setTablePaginationParams,
    tablePaginationParams,
    activeFilters,
    setActiveFilters,
    distributorData,
    batchDataLoading,
  } = props;

  return (
    <Row justify={'space-between'} style={{ backgroundColor: '#f4f4f4' }}>
      <Col>
        <Tabs
          className={'system-config-tabs'}
          type={'card'}
          onTabClick={setActiveTab}
          activeKey={activeTab}
          items={tabItems}
        ></Tabs>
      </Col>
      <Col>
        <Button size={'large'} onClick={() => setToggleFilters(!toggleFilters)}>
          {toggleFilters ? <CloseOutlined /> : <SlidersOutlined />}
        </Button>
        <Button
          size={'large'}
          type={'secondary'}
          icon={<SyncOutlined />}
          onClick={loadBatchesData}
        >
          Load
        </Button>
      </Col>
      {toggleFilters && (
        <Col span={24}>
          <Form
            className='filter-form'
            onFinish={() =>
              setTablePaginationParams({
                ...tablePaginationParams,
                offset: 0,
                filters: { ...activeFilters },
              })
            }
          >
            <Space className={'mt-10 mb-10 ml-10 mr-10'}>
              <InputNumber
                value={activeFilters.id}
                placeholder='ID'
                style={{ width: 200 }}
                onChange={(value) =>
                  setActiveFilters((prev) => {
                    return { ...prev, id: value || undefined };
                  })
                }
              />
              <Select
                optionFilterProp='children'
                showSearch
                value={activeFilters.distributor_id}
                placeholder={'Distributor'}
                style={{ width: 200 }}
                allowClear
                onChange={(value) =>
                  setActiveFilters((prev) => {
                    return { ...prev, distributor_id: value };
                  })
                }
              >
                {_get(distributorData, 'data', []).map((item) => (
                  <Select.Option value={item.id} key={item.id}>
                    {item.name}
                  </Select.Option>
                ))}
              </Select>
              <DatePicker
                value={
                  activeFilters.batch_date
                    ? moment(activeFilters.batch_date, 'YYYY-MM-DD')
                    : null
                }
                placeholder={'Batch Date'}
                style={{ width: 200 }}
                onChange={(value) =>
                  setActiveFilters((prev) => {
                    return {
                      ...prev,
                      batch_date: value
                        ? moment(value).format('YYYY-MM-DD')
                        : undefined,
                    };
                  })
                }
              />
              <DatePicker
                value={
                  activeFilters.effective_date
                    ? moment(activeFilters.effective_date, 'YYYY-MM-DD')
                    : null
                }
                placeholder={'Effective Date'}
                style={{ width: 200 }}
                onChange={(value) =>
                  setActiveFilters((prev) => {
                    return {
                      ...prev,
                      effective_date: value
                        ? moment(value).format('YYYY-MM-DD')
                        : undefined,
                    };
                  })
                }
              />
              <Select
                optionFilterProp='children'
                showSearch
                value={activeFilters.status}
                placeholder={'Status'}
                style={{ width: 200 }}
                allowClear
                onChange={(value) =>
                  setActiveFilters((prev) => {
                    return { ...prev, status: value };
                  })
                }
              >
                {[
                  ...(activeTab === PAGES.BATCH_ALL
                    ? ALL_BATCHES_STATUS_FILTER_LIST
                    : ARCHIVED_BATCHES_STATUS_FILTER_LIST),
                ].map((item) => (
                  <Select.Option value={item.value} key={item.label}>
                    {item.label}
                  </Select.Option>
                ))}
              </Select>
              <Form.Item style={{ marginBottom: '0px !important' }}>
                <Button
                  type={'primary'}
                  htmlType={'submit'}
                  loading={batchDataLoading}
                >
                  Apply
                </Button>
              </Form.Item>
              <Button
                type={'secondary'}
                onClick={() => {
                  setActiveFilters({});
                  setTablePaginationParams({
                    ...tablePaginationParams,
                    filters: {},
                  });
                }}
              >
                Clear
              </Button>
            </Space>
          </Form>
        </Col>
      )}
    </Row>
  );
}

export default BatchTableTitle;
