import React, { Component } from 'react';
import { Layout, Typography } from 'antd';
// import AppFooter from './footer/AppFooter';
import { _get } from '../../utils/lodashUtils';
import { connect } from 'react-redux';
import AppHeader from './header/AppHeader';
import SecondaryHeader from './header/SecondaryHeader';
import { BadCpnModal } from '../modules/batch/BadCpnModal';
import { changeBadCpnModalState } from '../../redux/Slices/badCpnBatchesSlice';
// import ReleaseNoteModal from '../modules/releaseNotes/ReleaseNoteModal';

const { Text } = Typography;
const { Content } = Layout;

class AppBase extends Component {
  render() {
    const {
      noSider,
      noHeader,
      loggedIn,
      noPadding,
      noBreadcrumb,
      noSecondaryHeader,
      badCpnModalState,
      changeBadCpnModalState,
      badCpnModalName,
    } = this.props;
    return (
      <Layout>
        {noHeader ? null : <AppHeader noBreadcrumb={noBreadcrumb} />}
        {noSecondaryHeader ? null : <SecondaryHeader />}
        <Layout
          className='site-layout'
          style={{
            marginTop: noSecondaryHeader ? 60 : 130,
          }}
        >
          <Content
            className={'main-container'}
            style={{
              padding: noPadding ? 0 : 10,
              height: `calc(100vh - 130px)`,
              overflow: 'auto',
            }}
          >
            {this.props.children}
            {/*<AppFooter />*/}
          </Content>
        </Layout>
        {/* <ReleaseNoteModal /> */}
        <BadCpnModal
          open={badCpnModalState}
          modalName={badCpnModalName}
          onCancel={() => changeBadCpnModalState(false)}
        />
      </Layout>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: _get(state, 'auth.user'),
    badCpnModalState: _get(state, 'badCpnBatchesReducer.modalState'),
    badCpnModalName: _get(state, 'badCpnBatchesReducer.modalName'),
  };
};
const mapDispatchToProps = {
  changeBadCpnModalState: changeBadCpnModalState,
};

export default connect(mapStateToProps, mapDispatchToProps)(AppBase);
