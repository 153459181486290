import { IMAGE_BASE_URL } from '../constants/api';
import {
  ERROR_MESSAGE_400,
  ERROR_MESSAGE_401,
  ERROR_MESSAGE_403,
  ERROR_MESSAGE_404,
  ERROR_MESSAGE_500,
  TYPE_ERROR,
  TYPE_INFO,
  TYPE_LOADING,
  TYPE_SUCCESS,
  TYPE_WARNING,
} from '../constants/message';
import {
  APPLICATION_URL,
  ERROR_MSG_TYPE,
  INFO_MSG_TYPE,
  SUCCESS_MSG_TYPE,
  WARNING_MSG_TYPE,
} from '../constants/dataKeys';
import { clearStorage } from './apiRequest';
import { get } from 'lockr';
import { setMessage } from '../redux/Slices/messageSlice';
import { Store } from '../redux/store';

const formMsg = (type, msg) => {
  Store.dispatch(setMessage({ type: type, msg: msg }));
};

export const makeURL = function (URL) {
  const appURL = get(APPLICATION_URL);
  return appURL + '/' + URL;
};

export const makeFileURL = function (URL) {
  return IMAGE_BASE_URL + '/' + URL;
};

export const handleErrorResponse = function (error) {
  let response = error.response;
  if (response) {
    let status = response.status;
    if (status === 400) {
      if (Array.isArray(response.data)) {
        response.data.forEach(function (errObject) {
          if (errObject.detail) {
            formMsg(TYPE_ERROR, errObject.message);
          }
        });
      } else if (response.data.message) {
        formMsg(TYPE_ERROR, response.data.message);
      } else {
        formMsg(TYPE_ERROR, ERROR_MESSAGE_400);
      }
    } else if (status === 403) {
      if (response.data.message) {
        formMsg(TYPE_ERROR, response.data.message);
      } else {
        formMsg(TYPE_ERROR, ERROR_MESSAGE_403);
      }
    } else if (status === 404) {
      if (response.data.message) {
        formMsg(TYPE_ERROR, response.data.message);
      } else {
        formMsg(TYPE_ERROR, ERROR_MESSAGE_404);
      }
    } else if (status === 500) {
      if (response.data.message) {
        formMsg(TYPE_ERROR, response.data.message);
      } else {
        formMsg(TYPE_ERROR, ERROR_MESSAGE_500);
      }
    } else if (status === 401 || status === 402) {
      clearStorage();
      window.location.reload();
      if (response.data.message) {
        formMsg(TYPE_ERROR, response.data.message);
      } else {
        formMsg(TYPE_ERROR, ERROR_MESSAGE_401);
      }
    } else {
      if (response?.data?.message) formMsg(TYPE_ERROR, response.data.message);
    }
  }
};

export const interpolate = function (theString, argumentArray) {
  var regex = /%s/;
  var _r = function (p, c) {
    return p.replace(regex, c);
  };
  return argumentArray.reduce(_r, theString);
};

export const displayMessage = function (type, msg) {
  if (type === SUCCESS_MSG_TYPE) formMsg(TYPE_SUCCESS, msg);
  else if (type === INFO_MSG_TYPE) formMsg(TYPE_INFO, msg);
  else if (type === WARNING_MSG_TYPE) formMsg(TYPE_WARNING, msg);
  else if (type === ERROR_MSG_TYPE) formMsg(TYPE_ERROR, msg);
};

export const startLoadingMessage = function (msg) {
  return formMsg(TYPE_LOADING, msg);
};
export const stopLoadingMessage = function (msgFn, finishMsgType, finishMsg) {
  msgFn();
  if (finishMsgType) displayMessage(finishMsgType, finishMsg);
  return true;
};
export const stripHTML = function (html) {
  var tmp = document.createElement('DIV');
  tmp.innerHTML = html;
  return tmp.textContent || tmp.innerText || '';
};

export const parseQueryString = function (query) {
  const obj = {};
  if (query.length) {
    if (query[0] == '?' || query[0] == '#') {
      query = query.substring(1, query.length);
    }
    const tempArr = query.split('&');
    tempArr.forEach(function (str) {
      const arr = str.split('=');
      if (arr.length == 2) {
        obj[arr[0]] = arr[1];
      }
    });
  }
  return obj;
};

export const copyToClipboard = (text) => {
  let myTemporaryInputElement = document.createElement('input');
  myTemporaryInputElement.type = 'text';
  myTemporaryInputElement.value = text;
  document.body.appendChild(myTemporaryInputElement);
  myTemporaryInputElement.select();
  document.execCommand('Copy');
  document.body.removeChild(myTemporaryInputElement);
  displayMessage(SUCCESS_MSG_TYPE, 'Copied!');
};

export const checkGotoInput = (gotoPage, total, limit) => {
  if (gotoPage <= 0) {
    return false;
  }
  let result = parseInt(total) / parseInt(limit);
  if (Number.isInteger(result)) {
    return parseInt(gotoPage) <= result;
  } else {
    return parseInt(gotoPage) <= parseInt(result) + 1;
  }
};
