import { _indexOf } from "./lodashUtils";

export const hasPermission = (master, useRole) => {
    let allow = false;
  
    if(_indexOf(master, useRole) >= 0){
      allow = true;
    }
  
    return allow;
  };

export const generatePaginationObj = function (page = 1, limit = 10) {
  const offset = (page - 1) * limit;
  return {
    offset,
    limit,
  };
};