import React from 'react';
import { connect } from 'react-redux';
import { Button, Checkbox, Col, Divider, Form, Input, Row, Spin } from 'antd';
import { MailOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import {
  loginUserAuthAction,
  userOrgAction,
} from '../../../../redux/actions/authReduxActions';
import {
  globalSiderMenu,
  resetGlobalPagesFilter,
} from '../../../../redux/actions/globalReducActions';
import { REQUIRED_FIELD_MESSAGE } from '../../../../constants/message';
import AuthBase from '../AuthBase';
import {
  getOuterAPI,
  patchOuterApi,
  postAPI,
  postWithOutTokenAPI,
} from '../../../../utils/apiRequest';
import {
  EULA_ACCEPT,
  PORTAL_INFO_BY_UUID,
  RESET_PASSWORD_MAIL,
  USER_LOGIN,
  VERIFY_OTP,
  VERIFY_TOTP,
} from '../../../../constants/api';
import {
  displayMessage,
  interpolate,
  parseQueryString,
} from '../../../../utils/common';
import {
  APPLICATION_URL,
  AUTH_ORG_UUID,
  ERROR_MSG_TYPE,
} from '../../../../constants/dataKeys';
import { withParams } from '../../../../utils/hoc';
import { _get } from '../../../../utils/lodashUtils';
import { set } from 'lockr';
import { agreement } from '../../../../constants/agreement';
import ModalCom from '../../../common/Modal';

class LogIn extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      userName: null,
      resetModalVisible: false,
      twoFAType: null,
      loginData: {},
      agreementModalOpen: false,
    };
  }

  onFinish = (values) => {
    let that = this;
    that.setState({
      loading: true,
    });
    let successFn = function (result) {
      let codeForVerify = null;

      if (_get(result, 'data.multi_factor_auth_type') === 'NONE') {
        that.props.loginUserAuthAction(result.data);
        if (that.props.history) {
          that.props.history.push('/');
        }
      }
      that.setState({
        loading: false,
        twoFAType: _get(result, 'data.multi_factor_auth_type'),
        loginData: result.data,
      });
      that.props.resetGlobalPagesFilter();
    };
    let errorFn = function (error) {
      if (error.code === 'EULA_NOT_ACCEPTED' && !error.success) {
        that.setState({
          eula_token: error.eula_token,
          agreementModalOpen: true,
        });
      }
      that.setState({
        loading: false,
      });
    };
    postWithOutTokenAPI(USER_LOGIN, values, successFn, errorFn);
  };
  checkOrganisation = (values) => {
    let that = this;
    that.setState({
      loading: true,
      login_values: values,
    });
    let successFn = function (result) {
      that.props.userOrgAction(result.data);
      set(AUTH_ORG_UUID, _get(values, 'org_uuid'));
      set(APPLICATION_URL, _get(result, 'data.portal_backend_url'));
      that.onFinish({ ...values, org_uuid: undefined });
    };
    let errorFn = function () {
      that.setState({
        loading: false,
      });
    };
    getOuterAPI(
      process.env.REACT_APP_MASTER_BACKEND +
        interpolate(PORTAL_INFO_BY_UUID, [values.org_uuid]),
      successFn,
      errorFn
    );
  };
  showResetModal = () => {
    let { resetModalVisible } = this.state;
    this.setState({
      resetModalVisible: !resetModalVisible,
    });
  };
  changeUserMail = (e) => {
    this.setState({
      userName: e.target.value,
    });
  };
  verifyOtp = (values) => {
    let that = this;
    that.setState({
      loading: true,
    });
    let reqData = {
      ...values,
      otp_token: this.state.loginData.otp_token,
    };
    let successFn = function (result) {
      that.props.loginUserAuthAction(result.data);
      that.props.history.push('/');
      that.setState({
        loading: false,
      });
      that.props.resetGlobalPagesFilter();
    };
    let errorFn = function () {
      that.setState({
        loading: false,
      });
    };
    postAPI(VERIFY_OTP, reqData, successFn, errorFn);
  };
  verifyTotp = (values) => {
    let that = this;
    that.setState({
      loading: true,
    });
    let reqData = {
      ...values,
      totp_token: this.state.loginData.totp_token,
    };
    let successFn = function (result) {
      that.props.loginUserAuthAction(result.data);
      that.props.history.push('/');
      that.setState({
        loading: false,
      });
      that.props.resetGlobalPagesFilter();
    };
    let errorFn = function () {
      that.setState({
        loading: false,
      });
    };
    postAPI(VERIFY_TOTP, reqData, successFn, errorFn);
  };
  handleOk = (e) => {
    const that = this;
    let { userName } = that.state;
    if (!userName) {
      displayMessage(ERROR_MSG_TYPE, 'Please fill Email Field');
      return true;
    }
    const successFn = function (data) {
      displayMessage(
        'success',
        'Link to update your password have been sent to your mail.'
      );
      that.setState({
        resetModalVisible: false,
        userName: null,
      });
    };
    const errorFn = function (error) {};
    postAPI(RESET_PASSWORD_MAIL, { username: userName }, successFn, errorFn);
  };
  handleAgreement = (values) => {
    const that = this;
    this.setState({
      agreementModalOpen: false,
    });
    if (values.eula_status) {
      let successFn = function () {
        that.onFinish({ ...that.state.login_values, org_uuid: undefined });
      };
      let errorFn = function () {
        that.setState({
          loading: false,
        });
      };
      patchOuterApi(EULA_ACCEPT, this.state.eula_token, successFn, errorFn);
    } else {
      this.form.resetFields();
    }
  };

  saveFormRef = (form) => {
    this.form = form;
  };

  render() {
    let { loading, resetModalVisible, twoFAType, agreementModalOpen } =
      this.state;
    let { params } = this.props;

    return (
      <AuthBase>
        <Row>
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <h2 className={'auth-type-title'}>Login to Connect Portal</h2>
            {!twoFAType ? (
              <Spin spinning={loading}>
                <Form
                  ref={this.saveFormRef}
                  id={'components-form-demo-normal-login'}
                  name='normal_login'
                  size={'large'}
                  className='login-form'
                  validateMessages={validateMessages}
                  initialValues={{
                    org_uuid: _get(
                      parseQueryString(params.search),
                      'org_id',
                      ''
                    ),
                  }}
                  onFinish={this.checkOrganisation}
                  layout='vertical'
                  key={_get(params, 'key')}
                >
                  <Form.Item
                    label='Organization ID'
                    name='org_uuid'
                    rules={[
                      { required: true, message: REQUIRED_FIELD_MESSAGE },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    label='Email Id'
                    name='email'
                    rules={[
                      {
                        type: 'email',
                        message: 'Email ID is not valid',
                      },
                      { required: true, message: REQUIRED_FIELD_MESSAGE },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    name='password'
                    label='Password'
                    rules={[
                      {
                        required: true,
                        message: 'Please input your Password!',
                      },
                    ]}
                  >
                    <Input.Password visibilityToggle={false} />
                  </Form.Item>
                  <Form.Item>
                    <div className={'btnFloatRight'}>
                      <Link to={'/reset-password'}>
                        <Button
                          size={'small'}
                          type={'link'}
                          className='login-form-forgot'
                        >
                          Forgot password
                        </Button>
                      </Link>
                    </div>
                  </Form.Item>

                  <Form.Item>
                    <Button
                      type={'secondary'}
                      htmlType='submit'
                      className='login-form-button theme-color'
                    >
                      Login
                    </Button>
                  </Form.Item>
                </Form>
                <Divider />
                Login as <Link to={'/login/root'}>Root User</Link>
              </Spin>
            ) : null}
            {twoFAType === 'OTP' ? (
              <Spin spinning={loading}>
                <p className='mt-10-n'>
                  Please enter the OTP sent to your mail
                </p>
                <Form
                  id={'components-form-demo-normal-login'}
                  name='normal_login'
                  size={'large'}
                  className='login-form'
                  validateMessages={validateMessages}
                  onFinish={this.verifyOtp}
                  layout='vertical'
                  key={_get(params, 'key')}
                >
                  <Form.Item
                    label='OTP'
                    name='code'
                    rules={[
                      { required: true, message: REQUIRED_FIELD_MESSAGE },
                    ]}
                  >
                    <Input />
                  </Form.Item>

                  <Form.Item>
                    <Button
                      type={'secondary'}
                      htmlType='submit'
                      className='login-form-button theme-color'
                    >
                      Login
                    </Button>
                  </Form.Item>
                </Form>
                <Divider />
                Login as <Link to={'/login/root'}>Root User</Link>
              </Spin>
            ) : null}
            {twoFAType === 'TOTP' ? (
              <Spin spinning={loading}>
                <p className='mt-10-n'>
                  Please enter the TOTP from authenticator app
                </p>
                <Form
                  id={'components-form-demo-normal-login'}
                  name='normal_login'
                  size={'large'}
                  className='login-form'
                  validateMessages={validateMessages}
                  onFinish={this.verifyTotp}
                  layout='vertical'
                  key={_get(params, 'key')}
                >
                  <Form.Item
                    label='TOTP'
                    name='code'
                    rules={[
                      { required: true, message: REQUIRED_FIELD_MESSAGE },
                    ]}
                  >
                    <Input />
                  </Form.Item>

                  <Form.Item>
                    <Button
                      type={'secondary'}
                      htmlType='submit'
                      className='login-form-button theme-color'
                    >
                      Login
                    </Button>
                  </Form.Item>
                </Form>
                <Divider />
                Login as <Link to={'/login/root'}>Root User</Link>
              </Spin>
            ) : null}
            <ModalCom
              title='Email to Reset Password'
              open={resetModalVisible}
              onOk={this.handleOk}
              onCancel={this.showResetModal}
              okText='Reset Password'
            >
              <Input
                placeholder='Email/Mobile Number/Employee Code'
                prefix={<MailOutlined />}
                onChange={this.changeUserMail}
              />
            </ModalCom>

            <ModalCom
              title={
                <Row>
                  <Col span={8}>
                    <div className='agreement-image'>
                      <img src={'/fullLogo.png'} alt='BlackLeaf Connect Logo' />
                    </div>
                  </Col>
                  <Col>
                    <div className='agreement-title'>
                      <span>END USER LICENSE AGREEMENT (EULA)</span>
                    </div>
                  </Col>
                </Row>
              }
              width={870}
              open={agreementModalOpen}
              footer={null}
              onCancel={() => {
                this.setState({ agreementModalOpen: false });
              }}
              closable
              maskClosable
              className='agreement-modal'
            >
              <div className='modal-body mb-30'>{agreement()}</div>
              <Form
                layout='vertical'
                onFinish={this.handleAgreement}
                initialValues={{}}
              >
                <div>
                  <Form.Item
                    name='eula_status'
                    rules={[
                      {
                        required: true,
                        message: 'Please Accept the EULA',
                      },
                    ]}
                    valuePropName={'checked'}
                  >
                    <Checkbox>
                      I have read the End User License Agreement and I accept
                      the Terms of Service.
                    </Checkbox>
                  </Form.Item>
                </div>

                <div>
                  <Form.Item>
                    <Button
                      type='primary'
                      htmlType='submit'
                      style={{ height: '40px', fontSize: '18px' }}
                    >
                      Submit
                    </Button>
                  </Form.Item>
                </div>
              </Form>
            </ModalCom>
          </Col>
        </Row>
      </AuthBase>
    );
  }
}

const validateMessages = {
  required: REQUIRED_FIELD_MESSAGE,
};
const mapStateToProps = (state) => {
  return {
    loggedIn: _get(state, 'auth.loggedIn'),
  };
};

const mapDispatchToProps = {
  loginUserAuthAction,
  globalSiderMenu,
  userOrgAction,
  resetGlobalPagesFilter,
};

export default connect(mapStateToProps, mapDispatchToProps)(withParams(LogIn));
