import AppBase from '../../base/AppBase';
import React, { useRef, useState } from 'react';
import {
  Badge,
  Button,
  Col,
  Divider,
  Form,
  Image,
  Input,
  Modal,
  Row,
  Select,
  Switch,
  Typography,
} from 'antd';
import { FormOutlined } from '@ant-design/icons';
import { useSelector } from 'react-redux';
import { _get } from '../../../utils/lodashUtils';
import { postAPI } from '../../../utils/apiRequest';
import {
  CHANGE_PASSWORD,
  GENERATE_TOTP_QR,
  UPDATE_USER,
} from '../../../constants/api';
import { cloneUser } from '../../../redux/actions/authReduxActions';
import {
  PASSWORD_FIELD_VALIDATION_MESSAGE,
  REQUIRED_FIELD_WITH_NAME_MESSAGE,
} from '../../../constants/message';
import { TWO_FA_TYPE_LIST } from '../../../constants/hardData';
import { useNavigate } from 'react-router-dom';
import ModalCom from '../../common/Modal';

const { Title } = Typography;

function Profile() {
  const twoFactorFormRef = useRef();
  const navigate = useNavigate();
  const user = useSelector((state) => state.auth);
  const [editBasicInfo, toggleEditBasicInfo] = useState(false);
  const [editTwoFactorInfo, toggleEditTwoFactorInfo] = useState(false);
  const [editPasswordInfo, toggleEditPasswordInfo] = useState(false);
  const [editDataActionLoading, setEditDataActionLoading] = useState(false);
  const [totpQr, setTotpQr] = useState(null);
  const updateUser = function (values) {
    let reqData = {
      first_name: values.first_name || user.first_name,
      last_name: values.last_name || user.last_name,
      send_notifications: values.send_notifications || user.send_notifications,
      ...values,
      email: undefined,
    };
    setEditDataActionLoading(true);
    let successFn = function (result) {
      setEditDataActionLoading(false);
      toggleEditPasswordInfo(false);
      toggleEditBasicInfo(false);
      toggleEditTwoFactorInfo(false);
      setTotpQr(false);
      cloneUser();
    };
    let errorFn = function () {
      cloneUser();
      setEditDataActionLoading(false);
      loadQR(values.multi_factor_auth_type);
    };
    postAPI(UPDATE_USER, reqData, successFn, errorFn);
  };
  const updatePassword = function (values) {
    Modal.confirm({
      title: 'Confirm Logout?',
      content:
        'If you continue, your current session will end and you will return to Login screen',
      type: 'warning',
      className: 'confirm-modal',
      onOk: () => {
        let reqData = {
          ...values,
          confirm_new_password: undefined,
        };
        setEditDataActionLoading(true);
        let successFn = function (result) {
          setEditDataActionLoading(false);
          toggleEditPasswordInfo(false);
          toggleEditBasicInfo(false);
          navigate(`/login`);
        };
        let errorFn = function () {
          cloneUser();
          setEditDataActionLoading(false);
        };
        postAPI(CHANGE_PASSWORD, reqData, successFn, errorFn);
      },
    });
  };
  const loadQR = function (type) {
    if (type !== 'TOTP') {
      return;
    }
    let successFn = function (result) {
      setTotpQr(result.data);
    };
    postAPI(GENERATE_TOTP_QR, {}, successFn, null);
  };

  return (
    <AppBase>
      <Title className={'page-title'}>My Account</Title>
      <Row gutter={16}>
        <Col span={20}>
          <Title level={4} className={'mb-5'}>
            Basic Information{' '}
            <FormOutlined
              onClick={() => toggleEditBasicInfo(true)}
              disabled={editDataActionLoading}
            />
          </Title>
          <Divider className={'mt-0 '} />
          <Form
            layout='vertical'
            initialValues={{ ...user }}
            onFinish={updateUser}
          >
            <Row gutter={16}>
              <Col span={9}>
                <Form.Item label={'First name'} name={'first_name'}>
                  <Input placeholder={'First Name'} disabled={!editBasicInfo} />
                </Form.Item>
              </Col>
              <Col span={9}>
                <Form.Item label={'Last name'} name={'last_name'}>
                  <Input placeholder={'Last Name'} disabled={!editBasicInfo} />
                </Form.Item>
              </Col>
              <Col span={9}>
                <Form.Item label={'Email'} name={'email'}>
                  <Input placeholder={'Email'} disabled={true} />
                </Form.Item>
              </Col>
            </Row>
            {editBasicInfo && (
              <Row>
                <Form.Item>
                  <Button
                    htmlType='submit'
                    type={'primary'}
                    disabled={editDataActionLoading}
                  >
                    Update
                  </Button>
                </Form.Item>
              </Row>
            )}
          </Form>
          <Title level={4} className={'mt-25 mb-5'}>
            Notifications{' '}
          </Title>
          <Divider className={'mt-0 '} />
          <Switch
            disabled={
              editDataActionLoading ||
              _get(user, 'Role.role_code') === 'ROLE_ROOT_USER'
            }
            checked={_get(user, 'send_notifications')}
            onChange={(value) => updateUser({ send_notifications: value })}
          />
          <Title level={4} className={'mt-25 mb-5'}>
            Two Factor Authentication{' '}
            <FormOutlined
              onClick={() => toggleEditTwoFactorInfo(true)}
              disabled={editDataActionLoading}
            />
          </Title>
          <Divider className={'mt-0'} />
          <Form
            layout='vertical'
            initialValues={{ ...user }}
            onFinish={updateUser}
            ref={twoFactorFormRef}
          >
            <Row>
              <Col span={9}>
                <Form.Item
                  label={'Multi Factor Auth'}
                  name={'multi_factor_auth_type'}
                >
                  <Select
                    disabled={!editTwoFactorInfo}
                    onChange={(value) => loadQR(value)}
                  >
                    {TWO_FA_TYPE_LIST.map((item) => (
                      <Select.Option value={item.value} key={item.label}>
                        {item.label}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            {editTwoFactorInfo && (
              <Row>
                <Form.Item>
                  <Button
                    htmlType='submit'
                    type={'primary'}
                    disabled={editDataActionLoading}
                  >
                    Update
                  </Button>
                </Form.Item>
              </Row>
            )}
          </Form>
          <Title level={4} className={'mt-25 mb-5'}>
            Password{' '}
            <FormOutlined
              onClick={() => toggleEditPasswordInfo(true)}
              disabled={editDataActionLoading}
            />
          </Title>
          <Divider className={'mt-0 '} />
          {editPasswordInfo ? (
            <Form layout='vertical' onFinish={updatePassword}>
              <Row gutter={16}>
                <Col span={9}>
                  <Form.Item
                    label={'Current Password '}
                    name={'current_password'}
                    rules={[
                      {
                        required: true,
                        message: REQUIRED_FIELD_WITH_NAME_MESSAGE,
                      },
                    ]}
                  >
                    <Input.Password />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col span={9}>
                  <Form.Item
                    label={'New Password'}
                    name={'new_password'}
                    rules={[
                      {
                        required: true,
                        message: REQUIRED_FIELD_WITH_NAME_MESSAGE,
                      },
                      {
                        message: PASSWORD_FIELD_VALIDATION_MESSAGE,
                        pattern: new RegExp(
                          '^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[;,=!@#$%^&*()_-])(?=.{12,})'
                        ),
                      },
                    ]}
                  >
                    <Input.Password />
                  </Form.Item>
                </Col>
                <Col span={9}>
                  <Form.Item
                    label={'Repeat New Password'}
                    name={'confirm_new_password'}
                    rules={[
                      {
                        required: true,
                        message: REQUIRED_FIELD_WITH_NAME_MESSAGE,
                      },
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          if (
                            !value ||
                            getFieldValue('new_password') === value
                          ) {
                            return Promise.resolve();
                          }
                          return Promise.reject(
                            new Error('Passwords do not match')
                          );
                        },
                      }),
                    ]}
                  >
                    <Input.Password />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Form.Item>
                  <Button htmlType='submit' type={'primary'}>
                    Update
                  </Button>
                </Form.Item>
              </Row>
            </Form>
          ) : (
            <>
              <Badge status='default' />
              <Badge status='default' />
              <Badge status='default' />
              <Badge status='default' />
              <Badge status='default' />
              <Badge status='default' />
              <Badge status='default' />
              <Badge status='default' />
            </>
          )}
        </Col>
      </Row>
      <ModalCom
        open={totpQr}
        key={_get(totpQr, 'totp_dataURL')}
        onCancel={() => {
          setTotpQr(null);
          toggleEditTwoFactorInfo(false);
          twoFactorFormRef.current.setFieldsValue({
            multi_factor_auth_type: user.multi_factor_auth_type,
          });
        }}
        footer={null}
      >
        <Row justify={'space-around'}>
          <Col>
            <Title level={5} align={'center'}>
              Scan the QR Code with Goggle Authenticator or Microsoft
              Authenticator to enable Two Factor Authentication
            </Title>
          </Col>
          <Col>
            <Image src={_get(totpQr, 'totp_dataURL')} preview={false} />
            <Form
              layout={'vertical'}
              onFinish={(values) =>
                updateUser({
                  ...values,
                  send_notifications: true,
                  multi_factor_auth_type: 'TOTP',
                })
              }
            >
              <Form.Item
                name={'code'}
                rules={[
                  { required: true, message: REQUIRED_FIELD_WITH_NAME_MESSAGE },
                  {
                    min: 6,
                    max: 6,
                    message: `"Code" must be 6 character long!`,
                  },
                ]}
                label={'Code'}
              >
                <Input disabled={editDataActionLoading} />
              </Form.Item>
              <Form.Item>
                <Button
                  type={'primary'}
                  htmlType={'submit'}
                  loading={editDataActionLoading}
                >
                  Save
                </Button>
              </Form.Item>
            </Form>
          </Col>
        </Row>
      </ModalCom>
    </AppBase>
  );
}

export default Profile;
