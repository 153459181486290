import { RELEASE_NOTES } from "../../reduxConstant";


export const releaseNotesReducer = ( state = {}, action) => {
    switch (action.type) {
        case RELEASE_NOTES: {
            return {
                ...state,
                ...action.payload
            }
        }
        default: {
            return state;
        }
    }
}