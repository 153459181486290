import React, { useEffect, useState } from 'react';
import AppBase from '../../base/AppBase';
import {
  Button,
  Col,
  Divider,
  Dropdown,
  Form,
  Input,
  Menu,
  Popconfirm,
  Row,
  Select,
  Space,
  Switch,
  Table,
  Tooltip,
  Typography,
} from 'antd';
import {
  CaretLeftOutlined,
  CaretRightOutlined,
  CloseOutlined,
  DeleteOutlined,
  DownOutlined,
  FormOutlined,
  PlusOutlined,
  SlidersOutlined,
} from '@ant-design/icons';
import { _get, _indexOf } from '../../../utils/lodashUtils';
import { deleteAPI, getAPI, postAPI, putAPI } from '../../../utils/apiRequest';
import {
  CREATE_USER_DATA,
  SINGLE_USER_DATA,
  USER_ALL_ROLES,
  USER_LIST,
  USER_ROLES,
} from '../../../constants/api';
import { interpolate } from '../../../utils/common';
import {
  PASSWORD_FIELD_VALIDATION_MESSAGE,
  REQUIRED_FIELD_WITH_NAME_MESSAGE,
} from '../../../constants/message';
import {
  ACTIVE_INACTIVE_FILTER_LIST,
  DEFAULT_TABLE_PAGINATION_DROPDOWN_PAGE_COUNT,
  PAGES_CODE as PAGES,
} from '../../../constants/hardData';
import { useDispatch, useSelector } from 'react-redux';
import {
  globalPagesFilterData,
  globalPagesFilterToggle,
  globalPagesPagination,
} from '../../../redux/actions/globalReducActions';
import * as ROLES from '../../../constants/roles';
import ModalCom from '../../common/Modal';

const { Title } = Typography;

function UserManagement(props) {
  const [toggleAddUserModal, setToggleAdduserModal] = useState(false);
  const [toggleEditUserModal, setToggleEdituserModal] = useState(false);
  const [userData, setUserData] = useState([]);
  const [userRolesData, setUserRolesData] = useState([]);
  const [userAllRolesData, setUserAllRolesData] = useState([]);
  const [userDataLoading, toggleUserDataLoading] = useState(false);
  const [userDataActionLoading, toggleUserDataActionLoading] = useState(false);
  const { RolePermissions = [] } = useSelector((state) =>
    _get(state, 'auth.Role', {})
  );
  const tablePaginationParams = useSelector(
    (state) => _get(state, 'global.globalPagesPagination')[PAGES.USERS]
  );
  const toggleFilters = useSelector(
    (state) => _get(state, 'global.globalPagesFilterToggle')[PAGES.USERS]
  );
  const activeFilters = useSelector(
    (state) => _get(state, 'global.globalPagesFilterData')[PAGES.USERS]
  );
  const dispatch = useDispatch();
  const setTablePaginationParams = (param) => {
    if (param && typeof param == 'function') {
      dispatch(
        globalPagesPagination(PAGES.USERS, param(tablePaginationParams))
      );
    } else {
      dispatch(globalPagesPagination(PAGES.USERS, param));
    }
  };
  const setToggleFilters = (param) => {
    if (param && typeof param == 'function') {
      dispatch(globalPagesFilterToggle(PAGES.USERS, param(toggleFilters)));
    } else {
      dispatch(globalPagesFilterToggle(PAGES.USERS, param));
    }
  };
  const setActiveFilters = (param) => {
    if (param && typeof param == 'function') {
      dispatch(globalPagesFilterData(PAGES.USERS, param(activeFilters)));
    } else {
      dispatch(globalPagesFilterData(PAGES.USERS, param));
    }
  };
  useEffect(() => {
    loadUsers();
  }, [tablePaginationParams]);
  useEffect(() => {
    loadRoleList();
    loadAllRoleList();
  }, []);
  const tableColumns = [
    {
      title: 'Edit',
      key: 'Actions',
      width: 80,
      render: (value, record) => (
        <Space>
          {(record.Role.role_code === ROLES.ROLE_STANDARD_USER &&
            _indexOf(RolePermissions, 'PERM_UPDATE_STANDARD_USER') > -1) ||
          (record.Role.role_code === ROLES.ROLE_ADMIN_USER &&
            _indexOf(RolePermissions, 'PERM_UPDATE_ADMIN_USER') > -1) ||
          (record.Role.role_code === ROLES.ROLE_ROOT_USER &&
            _indexOf(RolePermissions, 'PERM_UPDATE_ROOT_USER') > -1) ||
          (record.Role.role_code === ROLES.ROLE_CATALOG_READ_ONLY_USER &&
            _indexOf(RolePermissions, 'PERM_UPDATE_CATALOG_READ_ONLY_USER') >
              -1) ? (
            <Tooltip title={'Edit'}>
              <FormOutlined
                onClick={() => {
                  setToggleEdituserModal(record);
                }}
              />
            </Tooltip>
          ) : null}

          {(record.Role.role_code === 'ROLE_STANDARD_USER' &&
            _indexOf(RolePermissions, 'PERM_DELETE_STANDARD_USER') > -1) ||
          (record.Role.role_code === 'ROLE_ADMIN_USER' &&
            _indexOf(RolePermissions, 'PERM_DELETE_ADMIN_USER') > -1) ||
          (record.Role.role_code === 'ROLE_ROOT_USER' &&
            _indexOf(RolePermissions, 'PERM_DELETE_ROOT_USER') > -1) ||
          (record.Role.role_code === ROLES.ROLE_CATALOG_READ_ONLY_USER &&
            _indexOf(RolePermissions, 'PERM_DELETE_CATALOG_READ_ONLY_USER') >
              -1) ? (
            <Tooltip title={'Delete'}>
              <Popconfirm
                title='Are you sure you want to delete this user?'
                onConfirm={() => deleteUser(record)}
                okText='Yes'
                cancelText='No'
              >
                <DeleteOutlined />
              </Popconfirm>
            </Tooltip>
          ) : null}
        </Space>
      ),
    },
    {
      title: 'User ID',
      key: 'id',
      dataIndex: 'id',
      sorter: true,
      align: 'right',
      width: 100,
      sortDirections: ['descend', 'ascend'],
      sortOrder:
        _get(userData, 'sortBy') === 'id'
          ? _get(userData, 'sortDir') === 'DESC'
            ? 'descend'
            : 'ascend'
          : false,
      render: (value) => value || '-',
    },
    {
      title: 'Name',
      key: 'first_name',
      dataIndex: 'first_name',
      sorter: true,
      sortOrder:
        _get(userData, 'sortBy') === 'first_name'
          ? _get(userData, 'sortDir') === 'DESC'
            ? 'descend'
            : 'ascend'
          : false,
      render: (value, record) =>
        _get(record, 'first_name') + ' ' + _get(record, 'last_name'),
    },
    {
      title: 'User Type',
      key: 'userType',
      dataIndex: 'Role.name',
      render: (value, record) => _get(record, 'Role.name', '-'),
    },
    {
      title: 'Email',
      key: 'email',
      dataIndex: 'email',
      sorter: true,
      width: 400,
      sortOrder:
        _get(userData, 'sortBy') === 'email'
          ? _get(userData, 'sortDir') === 'DESC'
            ? 'descend'
            : 'ascend'
          : false,
      render: (value) => value || '-',
    },
    {
      title: 'Active',
      key: 'active',
      dataIndex: 'is_active',
      render: (value, record) => (
        <Switch
          checked={value}
          disabled={
            userDataActionLoading ||
            !(
              (record.Role.role_code === 'ROLE_STANDARD_USER' &&
                _indexOf(RolePermissions, 'PERM_UPDATE_STANDARD_USER') > -1) ||
              (record.Role.role_code === 'ROLE_ADMIN_USER' &&
                _indexOf(RolePermissions, 'PERM_UPDATE_ADMIN_USER') > -1) ||
              (record.Role.role_code === 'ROLE_ROOT_USER' &&
                _indexOf(RolePermissions, 'PERM_UPDATE_ROOT_USER') > -1) ||
              (record.Role.role_code === ROLES.ROLE_CATALOG_READ_ONLY_USER &&
                _indexOf(
                  RolePermissions,
                  'PERM_UPDATE_CATALOG_READ_ONLY_USER'
                ) > -1)
            )
          }
          className='ant-switch-green'
          onClick={() => updateUser(record.id, { is_active: !value })}
        />
      ),
    },
    {
      title: 'Notify',
      key: 'notify',
      dataIndex: 'send_notifications',
      render: (value, record) => (
        <Switch
          checked={value}
          disabled={
            userDataActionLoading ||
            !(
              (record.Role.role_code === 'ROLE_STANDARD_USER' &&
                _indexOf(RolePermissions, 'PERM_UPDATE_STANDARD_USER') > -1) ||
              (record.Role.role_code === 'ROLE_ADMIN_USER' &&
                _indexOf(RolePermissions, 'PERM_UPDATE_ADMIN_USER') > -1) ||
              (record.Role.role_code === 'ROLE_ROOT_USER' &&
                _indexOf(RolePermissions, 'PERM_UPDATE_ROOT_USER') > -1) ||
              (record.Role.role_code === ROLES.ROLE_CATALOG_READ_ONLY_USER &&
                _indexOf(
                  RolePermissions,
                  'PERM_UPDATE_CATALOG_READ_ONLY_USER'
                ) > -1)
            )
          }
          className='ant-switch-green'
          onClick={() => updateUser(record.id, { send_notifications: !value })}
        />
      ),
    },
  ];
  const loadRoleList = () => {
    let successFn = function (result) {
      setUserRolesData(result.data);
    };
    let errorFn = function () {};
    getAPI(USER_ROLES, successFn, errorFn);
  };
  const loadAllRoleList = () => {
    let successFn = function (result) {
      setUserAllRolesData(result.data);
    };
    let errorFn = function () {};
    getAPI(USER_ALL_ROLES, successFn, errorFn);
  };
  const loadUsers = () => {
    toggleUserDataLoading(true);
    let params = {
      ...tablePaginationParams,
    };
    let successFn = function (result) {
      setUserData(result);
      toggleUserDataLoading(false);
    };
    let errorFn = function () {
      toggleUserDataLoading(false);
    };
    postAPI(USER_LIST, params, successFn, errorFn);
  };
  const deleteUser = (record) => {
    toggleUserDataActionLoading(true);
    let successFn = function () {
      toggleUserDataActionLoading(false);
      setToggleEdituserModal(false);
      loadUsers();
    };
    let errorFn = function () {
      toggleUserDataActionLoading(false);
    };
    deleteAPI(interpolate(SINGLE_USER_DATA, [record.id]), successFn, errorFn);
  };
  const addUser = (data) => {
    toggleUserDataActionLoading(true);
    let successFn = function () {
      toggleUserDataActionLoading(false);
      setToggleAdduserModal(false);
      loadUsers();
    };
    let errorFn = function () {
      toggleUserDataActionLoading(false);
    };
    postAPI(CREATE_USER_DATA, data, successFn, errorFn);
  };
  const updateUser = (id, data) => {
    toggleUserDataActionLoading(true);
    let successFn = function () {
      toggleUserDataActionLoading(false);
      setToggleEdituserModal(false);
      loadUsers();
    };
    let errorFn = function () {
      toggleUserDataActionLoading(false);
    };
    putAPI(
      interpolate(SINGLE_USER_DATA, [id]),
      {
        ...data,
        id: undefined,
        password: data.password ? data.password : undefined,
        multi_factor_auth_type:
          data.multi_factor_auth_type === false ? 'NONE' : undefined,
      },
      successFn,
      errorFn
    );
  };
  const setTableSortingParams = (field, order) => {
    setTablePaginationParams((prevState) => {
      return {
        ...prevState,
        sortBy: order ? field : undefined,
        sortDir: order ? (order === 'ascend' ? 'ASC' : 'DESC') : undefined,
      };
    });
  };
  const setDropDownItems = () => {
    const dropdownItems = DEFAULT_TABLE_PAGINATION_DROPDOWN_PAGE_COUNT.map(
      (item) => {
        return {
          ...item,
          label: (
            <Button
              type='text'
              style={{ width: '100%' }}
              onClick={() => {
                setTablePaginationParams({
                  ...tablePaginationParams,
                  limit: +item.key,
                });
              }}
              key={item.label}
            >
              {item.label}
            </Button>
          ),
        };
      }
    );
    return dropdownItems;
  };
  return (
    <AppBase>
      <>
        <Title className={'page-title'}>User Management</Title>
        <Table
          pagination={false}
          size={'small'}
          scroll={{ y: 'calc(100vh - 370px)' }}
          onChange={(a, b, sorting) =>
            setTableSortingParams(sorting.field, sorting.order)
          }
          dataSource={_get(userData, 'data')}
          showSorterTooltip={false}
          loading={userDataLoading}
          className='data-table full-height-table'
          rowKey={'id'}
          columns={tableColumns}
          title={() => (
            <Row justify={'end'}>
              <Col>
                <Button
                  size={'large'}
                  onClick={() => setToggleFilters(!toggleFilters)}
                >
                  {toggleFilters ? <CloseOutlined /> : <SlidersOutlined />}
                </Button>
                <Button
                  size={'large'}
                  type={'primary'}
                  icon={<PlusOutlined />}
                  onClick={() => setToggleAdduserModal(true)}
                >
                  {' '}
                  Add New User
                </Button>
              </Col>
              {toggleFilters && (
                <Col span={24}>
                  <Form
                    className='filter-form'
                    onFinish={() =>
                      setTablePaginationParams({
                        ...tablePaginationParams,
                        offset: 0,
                        filters: { ...activeFilters },
                      })
                    }
                  >
                    <Space className={'mt-10 mb-10 ml-10 mr-10'}>
                      <Input
                        value={activeFilters.first_name}
                        placeholder='First Name'
                        onChange={(e) =>
                          setActiveFilters((prev) => {
                            return { ...prev, first_name: e.target.value };
                          })
                        }
                      />
                      <Input
                        value={activeFilters.last_name}
                        placeholder='Last Name'
                        onChange={(e) =>
                          setActiveFilters((prev) => {
                            return { ...prev, last_name: e.target.value };
                          })
                        }
                      />
                      <Input
                        value={activeFilters.email}
                        placeholder='Email'
                        onChange={(e) =>
                          setActiveFilters((prev) => {
                            return { ...prev, email: e.target.value };
                          })
                        }
                      />
                      <Select
                        optionFilterProp='children'
                        showSearch
                        value={activeFilters.role_id}
                        placeholder={'User Type'}
                        style={{ width: 200 }}
                        allowClear
                        onChange={(value) =>
                          setActiveFilters((prev) => {
                            return { ...prev, role_id: value };
                          })
                        }
                      >
                        {userAllRolesData.map((role) => (
                          <Select.Option value={role.id} key={role.id}>
                            {role.name}
                          </Select.Option>
                        ))}
                      </Select>
                      <Select
                        optionFilterProp='children'
                        showSearch
                        value={activeFilters.is_active}
                        placeholder={'Status'}
                        style={{ width: 200 }}
                        allowClear
                        onChange={(value) =>
                          setActiveFilters((prev) => {
                            return { ...prev, is_active: value };
                          })
                        }
                      >
                        {ACTIVE_INACTIVE_FILTER_LIST.map((item) => (
                          <Select.Option value={item.value} key={item.label}>
                            {item.label}
                          </Select.Option>
                        ))}
                      </Select>
                      <Form.Item>
                        <Button type={'primary'} htmlType={'submit'}>
                          Apply
                        </Button>
                      </Form.Item>
                      <Button
                        type={'secondary'}
                        onClick={() => {
                          setActiveFilters({});
                          setTablePaginationParams({
                            ...tablePaginationParams,
                            filters: {},
                          });
                        }}
                      >
                        Clear
                      </Button>
                    </Space>
                  </Form>
                </Col>
              )}
            </Row>
          )}
          footer={() => (
            <Row justify={'space-between'}>
              <Col>
                <Space>
                  <Dropdown
                    menu={{ items: setDropDownItems() }}
                    trigger={['click']}
                  >
                    <Button type='ghost'>
                      Items Per Page:{_get(tablePaginationParams, 'limit')}
                      <DownOutlined className={'ml-5'} />
                    </Button>
                  </Dropdown>
                  <Typography>{`${_get(userData, 'offset', 0) + 1} to ${
                    _get(userData, 'data', []).length +
                    _get(userData, 'offset', '--')
                  } of ${_get(userData, 'total', '--')} items`}</Typography>
                </Space>
              </Col>
              <Col>
                <Button.Group>
                  <Button
                    type='ghost'
                    icon={<CaretLeftOutlined />}
                    onClick={() =>
                      setTablePaginationParams({
                        ...tablePaginationParams,
                        offset:
                          _get(userData, 'offset', 0) -
                          _get(userData, 'limit', 0),
                      })
                    }
                    disabled={!_get(userData, 'offset', 0)}
                  />
                  <Button
                    type='ghost'
                    icon={<CaretRightOutlined />}
                    onClick={() =>
                      setTablePaginationParams({
                        ...tablePaginationParams,
                        offset:
                          _get(userData, 'offset', 0) +
                          _get(userData, 'limit', 0),
                      })
                    }
                    disabled={
                      _get(userData, 'offset', 0) +
                        _get(userData, 'limit', 0) >=
                      _get(userData, 'total', 0)
                    }
                  />
                </Button.Group>
              </Col>
            </Row>
          )}
        />
        <ModalCom
          open={toggleAddUserModal}
          key={toggleAddUserModal}
          title={'Add New User'}
          onCancel={() => setToggleAdduserModal(false)}
          footer={null}
        >
          <Form
            layout='vertical'
            onFinish={addUser}
            initialValues={{
              send_notifications: false,
              is_active: false,
            }}
          >
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item
                  label='First Name'
                  name='first_name'
                  rules={[
                    {
                      required: true,
                      message: REQUIRED_FIELD_WITH_NAME_MESSAGE,
                    },
                    {
                      min: 3,
                      max: 125,
                      message: 'First Name must be 3 to 125 characters long',
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label='Last Name'
                  name='last_name'
                  rules={[
                    {
                      required: true,
                      message: REQUIRED_FIELD_WITH_NAME_MESSAGE,
                    },
                    {
                      min: 3,
                      max: 125,
                      message: 'Last Name must be 3 to 125 characters long',
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label='Email ID'
                  name='email'
                  rules={[
                    {
                      required: true,
                      message: REQUIRED_FIELD_WITH_NAME_MESSAGE,
                    },
                    { type: 'email', message: 'Email is not valid!' },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label='Password'
                  name='password'
                  rules={[
                    {
                      required: true,
                      message: PASSWORD_FIELD_VALIDATION_MESSAGE,
                      pattern: new RegExp(
                        '^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[;,=!@#$%^&*()_-])(?=.{12,})'
                      ),
                    },
                  ]}
                >
                  <Input.Password />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label='User Type'
                  name='role_id'
                  rules={[
                    {
                      required: true,
                      message: REQUIRED_FIELD_WITH_NAME_MESSAGE,
                    },
                  ]}
                >
                  <Select>
                    {userRolesData.map((role) => (
                      <Select.Option value={role.id} key={role.id}>
                        {role.name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label='Active'
                  name='is_active'
                  valuePropName='checked'
                >
                  <Switch className='ant-switch-green' />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Divider orientation={'left'}>Notify</Divider>
                <Form.Item
                  label='Batch Processing'
                  name='send_notifications'
                  valuePropName='checked'
                >
                  <Switch className='ant-switch-green' />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16} justify={'end'}>
              <Col>
                <Form.Item>
                  <Button
                    type='primary'
                    htmlType='submit'
                    loading={userDataActionLoading}
                    className='btn-green'
                  >
                    {' '}
                    Add User{' '}
                  </Button>
                </Form.Item>
              </Col>
              <Col>
                <Button
                  type='secondary'
                  onClick={() => setToggleAdduserModal(false)}
                >
                  {' '}
                  Cancel
                </Button>
              </Col>
            </Row>
          </Form>
        </ModalCom>
        <ModalCom
          open={toggleEditUserModal}
          title={'Edit User'}
          onCancel={() => setToggleEdituserModal(false)}
          key={_get(toggleEditUserModal, 'id')}
          footer={null}
        >
          <Form
            onFinish={(values) =>
              updateUser(_get(toggleEditUserModal, 'id'), values)
            }
            layout='vertical'
            key={_get(toggleEditUserModal, 'id')}
            initialValues={{
              ...toggleEditUserModal,
              multi_factor_auth_type:
                _get(toggleEditUserModal, 'multi_factor_auth_type') === 'NONE'
                  ? false
                  : true,
            }}
          >
            <Row gutter={16}>
              <Col span={24}>
                <Form.Item label='User ID' name='id'>
                  <Input disabled className={'right-aligned'} />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label='First Name'
                  name='first_name'
                  rules={[
                    {
                      required: true,
                      message: REQUIRED_FIELD_WITH_NAME_MESSAGE,
                    },
                    {
                      min: 3,
                      max: 125,
                      message: 'First Name must be 3 to 125 characters long',
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label='Last Name'
                  name='last_name'
                  rules={[
                    {
                      required: true,
                      message: REQUIRED_FIELD_WITH_NAME_MESSAGE,
                    },
                    {
                      min: 3,
                      max: 125,
                      message: 'Last Name must be 3 to 125 characters long',
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label='User Type'
                  name='role_id'
                  rules={[
                    {
                      required: true,
                      message: REQUIRED_FIELD_WITH_NAME_MESSAGE,
                    },
                  ]}
                >
                  <Select>
                    {userRolesData.map((role) => (
                      <Select
                        optionFilterProp='children'
                        showSearch
                        value={role.id}
                        key={role.id}
                      >
                        {role.name}
                      </Select>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label='Email ID'
                  name='email'
                  rules={[
                    {
                      required: true,
                      message: REQUIRED_FIELD_WITH_NAME_MESSAGE,
                    },
                    {
                      type: 'email',
                      message: 'Email ID is not valid',
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label='Password'
                  name='password'
                  rules={[
                    {
                      message: PASSWORD_FIELD_VALIDATION_MESSAGE,
                      pattern: new RegExp(
                        '^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[;,=!@#$%^&*()_-])(?=.{12,})'
                      ),
                    },
                  ]}
                >
                  <Input.Password />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label='Active'
                  name='is_active'
                  valuePropName='checked'
                >
                  <Switch className='ant-switch-green' />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Divider orientation={'left'}>Notify</Divider>
                <Form.Item
                  label='Batch Processing'
                  name='send_notifications'
                  valuePropName='checked'
                >
                  <Switch className='ant-switch-green' />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label='Two Factor Authentication'
                  name='multi_factor_auth_type'
                  valuePropName='checked'
                >
                  <Switch
                    className='ant-switch-green'
                    disabled={
                      _get(toggleEditUserModal, 'multi_factor_auth_type') ===
                      'NONE'
                    }
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16} justify={'end'}>
              <Col>
                <Form.Item>
                  <Button
                    type='primary'
                    htmlType='submit'
                    loading={userDataActionLoading}
                  >
                    {' '}
                    Update{' '}
                  </Button>
                </Form.Item>
              </Col>
              <Col>
                <Button
                  type='secondary'
                  onClick={() => setToggleEdituserModal(false)}
                >
                  {' '}
                  Cancel
                </Button>
              </Col>
            </Row>
          </Form>
        </ModalCom>
      </>
    </AppBase>
  );
}

export default UserManagement;
