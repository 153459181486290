/**
 * *Auth Reducers Constants
 * */
export const AUTH_LOGIN_TYPE = 'auth_login';
export const AUTH_LOGOUT_TYPE = 'auth_logout';

/**
 * *Global Reducers Constants
 * */
export const GLOBAL_UPDATE_ROUTE = 'global_update_route';
export const GLOBAL_SIDER_COLLAPSED = 'global_sider_collapsed';
export const GLOBAL_SIDER_MENU = 'global_sider_menu';
export const GLOBAL_PAGES_PAGINATION = 'global_pages_pagination';
export const GLOBAL_PAGES_FILTER_DATA = 'global_pages_filter_data';
export const GLOBAL_PAGES_FILTER_TOGGLE = 'global_pages_filter_toggle';
export const REDUX_PERSIST = 'persist/REHYDRATE';

export const AUTH_TOKEN = 'token';
export const AUTH_USER_DATA = 'auth_user_clone';
export const AUTH_ORG_DETAIL = 'auth_org';

// Release Notes action
export const RELEASE_NOTES = 'release_notes';

// Slices Constants
export const API_LOADER = 'api_loader';
export const MANUFACTURER_FILTER_LIST = 'manufacturer_filter_list';
export const CATEGORY_FILTER_DATA = 'category_filter_data';
export const SYNC_CONNECTOR_FILTER_DATA = 'sync_connector_filter_data';
export const CATALOGS = 'catalogs';
export const MESSAGE = 'message';
export const MODAL = 'modal';
export const DUPLICATE_BATCHES = 'duplicate_batches';
export const BAD_CPN_BATCHES = 'bad_cpn_batches';
export const SYSTEM_SYNC_CONNECTOR_INTEGRATION = 'system_sc_integration';
export const CATALOG_CONNECTOR_DATA = 'catalog_connector_data';
export const PRODUCT_CATEGORY_LABOR_CATEGORY =
  'product_category_labor_category';
export const EDIT_CATALOG_TABS = 'edit_catalog_tabs';
export const PRODUCT_CATEGORY_INTEGRATION = 'product_category_integration';
