import React, { Component } from 'react';
import { Button, Col, Dropdown, Layout, Row, Space, Typography } from 'antd';
import { UserOutlined, FileTextOutlined } from '@ant-design/icons';
import { _get } from '../../../utils/lodashUtils';
import { globalSiderCollapsed } from '../../../redux/actions/globalReducActions';
import { logoutUserAuthAction } from '../../../redux/actions/authReduxActions';
import { connect } from 'react-redux';
import { USER_LOGOUT } from '../../../constants/api';
import { postAPI } from '../../../utils/apiRequest';
import { Link } from 'react-router-dom';
import { withParamsAndNavigate } from '../../../utils/hoc';

const { Header } = Layout;

class AppHeader extends Component {
  logOutUser = () => {
    let that = this;
    let successFn = function (result) {
      that.props.navigate('/login', { replace: true });
      that.props.logoutUserAuthAction();
    };
    let errorFn = function (error) {};
    postAPI(USER_LOGOUT, {}, successFn, errorFn);
  };

  userMenu = () => {
    const items = [
      {
        key: '/me',
        label: <Link to={'/me'}>Account Information</Link>,
      },
      {
        key: '/logout',
        label: (
          <Button
            type='text'
            onClick={() => this.logOutUser()}
            style={{ width: '100%' }}
          >
            Log Out
          </Button>
        ),
      },
    ];
    return items;
  };

  render() {
    let that = this;
    const { loginUser, orgName } = that.props;

    return (
      <Header
        className='site-layout-background'
        style={{
          background: '#0f0708',
          boxShadow: 'rgba(38, 50, 69, 0.2) 0px 2px 4px 0px',
          padding: '0px 10px',
          position: 'fixed',
          zIndex: 1,
          width: '100%',
        }}
      >
        <Row align={'center'} justify={'space-between'}>
          <Col>
            <Link to={'/'}>
              <Space>
                <img
                  src={'/fullLogo.png'}
                  alt='BlackLeaf Solutions'
                  style={{
                    maxWidth: '100%',
                    maxHeight: 65,
                    height: 36,
                    marginRight: 8,
                    marginTop: 16,
                  }}
                />
                <Typography
                  className={'text-white text-large'}
                  style={{ fontSize: 18 }}
                >
                  | {orgName}
                </Typography>
              </Space>
            </Link>
          </Col>
          <Col span={8}>
            <div
              style={{ float: 'right', padding: '0 10px', cursor: 'pointer' }}
            >
              <Link to={'/release-notes'}>
                <FileTextOutlined
                  className={'text-white'}
                  style={{ fontSize: 22, marginRight: '24px' }}
                />
              </Link>
              <Dropdown menu={{ items: this.userMenu() }} className={'dark'}>
                <Space align='baseline'>
                  <span className='text-white'>{`Hi, ${_get(
                    loginUser,
                    'first_name'
                  )} ${_get(loginUser, 'last_name')}`}</span>
                  <UserOutlined
                    className={'text-white'}
                    style={{ fontSize: 22 }}
                  />
                </Space>
              </Dropdown>
            </div>
          </Col>
        </Row>
      </Header>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    route: _get(state, 'global.route') || {},
    loginUser: _get(state, 'auth'),
    orgName: _get(state, 'auth.org.name', '--'),
  };
};
const mapDispatchToProps = {
  globalSiderCollapsed,
  logoutUserAuthAction,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withParamsAndNavigate(AppHeader));
