import { Layout } from 'antd';
import { BrowserRouter as Router } from 'react-router-dom';
import { Provider } from 'react-redux';
import ErrorBoundary from './crashHandling/ErrorBoundary';
import { Store } from './redux/store';
import RoutesHome from './RoutesHome';
import Message from './components/common/Message';

function App() {
  return (
    <Layout>
      <Provider store={Store}>
        <Message />
        <ErrorBoundary>
          <Router>
            <RoutesHome />
          </Router>
        </ErrorBoundary>
      </Provider>
    </Layout>
  );
}

export default App;
