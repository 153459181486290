import React, { useEffect, useState } from 'react';
import ModalCom from '../../common/Modal';
import { Col, Collapse, Row, Spin, Table, Tooltip, Typography } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { PRIMARY_COLOR } from '../../../constants/constants';
import { useDispatch, useSelector } from 'react-redux';
import { getDuplicateBatchItemData } from '../../../redux/Slices/duplicateBatchSlice';
import { setMessage } from '../../../redux/Slices/messageSlice';
import { TYPE_WARNING } from '../../../constants/message';
import { DESCRIPTION_PARAGRAPH_ELLIPSIS } from '../../../constants/hardData';
import getSymbolFromCurrency from 'currency-symbol-map';
import { _get } from '../../../utils/lodashUtils';

export const DuplicateBatchModal = (props) => {
  const { open, onOk, onCancel, ...restProps } = props;

  const [deleteDuplicatesArr, setDeleteDuplicatesArr] = useState([]);
  const [selectedItem, setSelectedItem] = useState(0);

  const dispatch = useDispatch();

  const duplicateBatchIds = useSelector(
    (state) => state.duplicateBatchReducer.data
  );
  const duplicateBatchIdItems = useSelector(
    (state) => state.duplicateBatchReducer.itemData
  );
  const apiLoader = useSelector((state) => state.apiLoader.bool);
  const batchId = useSelector((state) => state.duplicateBatchReducer.batchId);

  useEffect(() => {
    if (duplicateBatchIds.length === 0) {
      onCancel();
    }
  }, [duplicateBatchIds]);

  const renderColumns = () => {
    const columns = [
      {
        title: 'Line',
        dataIndex: 'sequence_number',
        render: (value) => value || '-',
      },
      {
        title: (
          <Tooltip placement='top' title='Manufacturer Part Number'>
            MPN
          </Tooltip>
        ),
        dataIndex: 'vendor_part_num',
        render: (value) => value || '-',
      },
      {
        title: (
          <Tooltip placement='top' title='Distributor Part Number'>
            DPN
          </Tooltip>
        ),
        dataIndex: 'item_num',
        render: (value) => value || '-',
      },
      {
        title: 'Description',
        dataIndex: 'description',
      },
      {
        title: 'MSRP',
        dataIndex: 'msrp',
        render: (value) => (value ? (+value).toFixed(2) : '-'),
      },
      {
        title: 'Cost',
        dataIndex: 'price',
        render: (value, record, index) =>
          value
            ? `${
                duplicateBatchIdItems[index].Batch?.currency_code
                  ? getSymbolFromCurrency(
                      _get(duplicateBatchIdItems[index], 'Batch.currency_code')
                    ) ||
                    _get(duplicateBatchIdItems[index], 'Batch.currency_code')
                  : getSymbolFromCurrency('USD')
              }${(+value).toFixed(2)}`
            : '-',
      },
      {
        title: 'Category',
        dataIndex: 'category_name',
        render: (value, record) => value || '-',
      },
      {
        title: 'Sub Category',
        dataIndex: 'subcategory_name',
        render: (value, record) =>
          value ? (
            <Typography.Paragraph
              ellipsis={{
                ...DESCRIPTION_PARAGRAPH_ELLIPSIS,
                tooltip: value,
              }}
              style={{ maxWidth: 260 }}
              key={record.id}
            >
              {value}
            </Typography.Paragraph>
          ) : (
            '-'
          ),
      },
      {
        title: 'RMR',
        dataIndex: 'rmr',
        render: (value) => (value ? (+value).toFixed(2) : '-'),
      },
      {
        title: 'RMR Frequency',
        dataIndex: 'rmr_frequency',
        width: 200,
        render: (value) => value || '-',
      },
    ];

    return columns;
  };

  const checkSelectedItems = (arr) => {
    let count = 0;
    duplicateBatchIdItems.forEach((item) => {
      arr.forEach((i) => {
        if (i === item.id) {
          count++;
        }
      });
    });
    return count === duplicateBatchIdItems.length - 1 ? false : true;
  };

  const onbatchItemSelect = (record, selected, selectedRows, nativeEvent) => {
    const { id } = record;
    let arr = [...deleteDuplicatesArr];
    if (!checkSelectedItems(arr) && selected) {
      dispatch(
        setMessage({
          type: TYPE_WARNING,
          msg: 'Cannot delete all items',
        })
      );
      return;
    }
    if (selected) {
      arr.push(id);
    } else if (!selected) {
      arr.splice(arr.indexOf(id), 1);
    }

    setDeleteDuplicatesArr(arr);

    return false;
  };

  const renderCollapseItems = () => {
    const items = duplicateBatchIds.map((id) => {
      return {
        key: id,
        label: <Typography.Text>Item {id}</Typography.Text>,
        children: (
          <Table
            dataSource={duplicateBatchIdItems}
            columns={renderColumns()}
            rowKey={'id'}
            pagination={false}
            rowSelection={{
              type: 'checkbox',
              hideSelectAll: true,
              preserveSelectedRowKeys: true,
              selectedRowKeys: deleteDuplicatesArr,
              columnTitle: 'Delete Item',
              onSelect: (record, selected, selectedRows, nativeEvent) =>
                onbatchItemSelect(record, selected, selectedRows, nativeEvent),
            }}
          />
        ),
      };
    });

    return items;
  };

  const onCollapseChange = (key) => {
    if (key.length) {
      const itemId = key[0];
      setSelectedItem(itemId);
      if (batchId !== -1) {
        dispatch(getDuplicateBatchItemData(batchId, itemId));
      }
    }
  };

  const title = (
    <Row>
      <Col span={3}>
        <Typography.Title
          level={2}
          style={{ color: PRIMARY_COLOR, marginTop: 0 }}
        >
          <ExclamationCircleOutlined />
        </Typography.Title>
      </Col>
      <Col>
        <Typography.Title
          level={2}
          style={{ marginTop: 0, marginBottom: '8px' }}
        >
          Duplicate Batch Items Found
        </Typography.Title>
        <Typography.Text style={{ fontWeight: 400 }}>
          Please delete the duplicate item ids
        </Typography.Text>
      </Col>
    </Row>
  );

  return (
    <ModalCom
      title={title}
      open={open}
      width={window.innerWidth - 300 < 800 ? 800 : window.innerWidth - 300}
      okText={'Delete Duplicates'}
      onOk={() => {
        onOk(batchId, deleteDuplicatesArr, selectedItem);
      }}
      onCancel={onCancel}
      {...restProps}
    >
      <Spin spinning={apiLoader}>
        <Collapse
          items={renderCollapseItems()}
          onChange={(key) => onCollapseChange(key)}
          accordion={true}
        />
      </Spin>
    </ModalCom>
  );
};
