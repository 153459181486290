import React, { useEffect, useState } from 'react';
import AppBase from '../../base/AppBase';
import {
  Button,
  Col,
  Dropdown,
  Popconfirm,
  Row,
  Space,
  Spin,
  Table,
  Tag,
  Typography,
} from 'antd';
import {
  CaretLeftOutlined,
  CaretRightOutlined,
  DeleteOutlined,
  DisconnectOutlined,
  DownloadOutlined,
  DownOutlined,
  ExclamationCircleOutlined,
  EyeOutlined,
  FormOutlined,
} from '@ant-design/icons';
import { _get } from '../../../utils/lodashUtils';
import {
  ALL_BATCH_LIST,
  ARCHIVED_BATCH_LIST,
  BATCH_SINGLE_DATA,
  DISTRIBUTOR_LIST,
  DUPLICATE_BATCH_ITEMS_DELETE,
  EXPORT_SINGLE_ARCHIVED_BATCH,
  EXPORT_SINGLE_BATCH,
  FETCH_MISSING_MANUFACTURER_CODE_BATCH,
  VOID_SINGLE_BATCH,
} from '../../../constants/api';
import {
  deleteAPI,
  deleteMultipleAPI,
  getAPI,
  postAPI,
} from '../../../utils/apiRequest';
import {
  BATCH_STATUS_COLOR_CODE,
  DEFAULT_TABLE_PAGINATION_DROPDOWN_PAGE_COUNT,
  PAGES_CODE as PAGES,
} from '../../../constants/hardData';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import moment from 'moment';
import { interpolate } from '../../../utils/common';
import ManufacturerCodeDrawer from '../manufacturers/ManufacturerCodeDrawer';
import { useDispatch, useSelector } from 'react-redux';
import {
  globalPagesFilterData,
  globalPagesFilterToggle,
  globalPagesPagination,
} from '../../../redux/actions/globalReducActions';
import { DuplicateBatchModal } from './DuplicateBatchModal';
import {
  getDuplicateBatchItemData,
  getDuplicateBatchesData,
} from '../../../redux/Slices/duplicateBatchSlice';
import { changeApiLoader } from '../../../redux/Slices/apiLoaderSlice';
import { setMessage } from '../../../redux/Slices/messageSlice';
import { TYPE_SUCCESS } from '../../../constants/message';
import BatchTableTitle from './BatchTableTitle';
import {
  changeBadCpnModalName,
  changeBadCpnModalState,
} from '../../../redux/Slices/badCpnBatchesSlice';
import { BATCH } from '../../../constants/constants';

const { Title } = Typography;

function BatchManagement(props) {
  const { id } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState(
    searchParams.get('tab') || PAGES.BATCH_ALL
  );
  const [batchData, setBatchData] = useState({});
  const [distributorData, setDistributorData] = useState({});
  const [batchDataLoading, toggleBatchDataLoading] = useState(false);
  const [batchDataActionLoading, toggleBatchDataActionLoading] =
    useState(false);
  const [codeListDataLoading, setCodeListDataLoading] = useState(false);
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [duplicateModal, setDuplicateModal] = useState(false);
  const tablePaginationParams = useSelector(
    (state) => _get(state, 'global.globalPagesPagination')[activeTab]
  );
  const toggleFilters = useSelector(
    (state) => _get(state, 'global.globalPagesFilterToggle')[activeTab]
  );
  const activeFilters = useSelector(
    (state) => _get(state, 'global.globalPagesFilterData')[activeTab]
  );
  const dispatch = useDispatch();
  const setTablePaginationParams = (param) => {
    if (param && typeof param == 'function') {
      dispatch(globalPagesPagination(activeTab, param(tablePaginationParams)));
    } else {
      dispatch(globalPagesPagination(activeTab, param));
    }
  };
  const setToggleFilters = (param) => {
    if (param && typeof param == 'function') {
      dispatch(globalPagesFilterToggle(activeTab, param(toggleFilters)));
    } else {
      dispatch(globalPagesFilterToggle(activeTab, param));
    }
  };
  const setActiveFilters = (param) => {
    if (param && typeof param == 'function') {
      dispatch(globalPagesFilterData(activeTab, param(activeFilters)));
    } else {
      dispatch(globalPagesFilterData(activeTab, param));
    }
  };
  useEffect(() => {
    loadBatchesData();
  }, [tablePaginationParams]);
  useEffect(() => {
    setSearchParams({ ...searchParams.values(), tab: activeTab });
    setBatchData({});
  }, [activeTab]);
  useEffect(() => {
    loadDistributorData();
  }, []);
  const loadDistributorData = () => {
    let successFn = function (result) {
      setDistributorData(result);
    };
    let errorFn = function () {};
    getAPI(DISTRIBUTOR_LIST, successFn, errorFn);
  };
  const checkMissingCode = (batchId) => {
    setDrawerVisible(false);
    setCodeListDataLoading(true);
    let successFn = function (result) {
      if (result.duplicate_item_id_flag) {
        setDuplicateModal(true);
      }
      setCodeListDataLoading(false);
      if (result.data.length) {
        setDrawerVisible(batchId);
      } else {
        setDrawerVisible(false);
        navigate(`/batch/${batchId}`);
      }
    };
    let errorFn = function (data, error) {
      if (data.duplicate_item_id_flag) {
        dispatch(getDuplicateBatchesData(batchId));
        setDuplicateModal(true);
      }
      setCodeListDataLoading(false);
      if (error?.response?.status === 423) loadBatchesData();
    };
    postAPI(
      interpolate(FETCH_MISSING_MANUFACTURER_CODE_BATCH, [batchId]),
      {},
      successFn,
      errorFn
    );
  };
  const tableColumns = [
    {
      title: 'Edit',
      key: 'Actions',
      width: 120,
      fixed: true,
      render: (value, record) =>
        activeTab == PAGES.BATCH_ALL ? (
          <Space size={4}>
            <Button
              title='Edit'
              type={'text'}
              size={'small'}
              icon={<FormOutlined />}
              onClick={() => checkMissingCode(record.id)}
              disabled={record.status === 'RC'}
            />
            {record.status === 'RC' ? (
              <Button
                title={'Delete'}
                type={'text'}
                size={'small'}
                icon={<DeleteOutlined />}
                disabled={record.status === 'RC'}
              />
            ) : (
              <Popconfirm
                title={'Are you sure you want to archive this batch?'}
                onConfirm={() => deleteBatch(record)}
                okText='Yes'
                cancelText='No'
                disabled={record.status === 'RC'}
              >
                <Button
                  title={'Delete'}
                  type={'text'}
                  size={'small'}
                  icon={<DeleteOutlined />}
                  disabled={record.status === 'RC'}
                />
              </Popconfirm>
            )}
            {record.status === 'RC' ? (
              <Button
                title={'Void'}
                type={'text'}
                size={'small'}
                icon={<DisconnectOutlined />}
                style={{ cursor: 'pointer' }}
                disabled={record.status === 'RC'}
              />
            ) : (
              <Popconfirm
                title={'Are you sure you want to void this batch?'}
                onConfirm={() => voidBatch(record)}
                okText='Yes'
                cancelText='No'
              >
                {' '}
                <Button
                  title={'Void'}
                  type={'text'}
                  size={'small'}
                  icon={<DisconnectOutlined />}
                  style={{ cursor: 'pointer' }}
                  disabled={record.status === 'RC'}
                />
              </Popconfirm>
            )}
            <Button
              title={'Export'}
              type={'text'}
              size={'small'}
              icon={<DownloadOutlined />}
              onClick={() => exportBatch(record)}
              disabled={record.status === 'RC'}
            />
          </Space>
        ) : (
          <Space size={8}>
            <Button
              title={'Export'}
              type={'text'}
              size={'small'}
              icon={<DownloadOutlined />}
              onClick={() => exportArchiveBatch(record)}
              disabled={record.status === 'RC'}
            />
            <Button
              title={'View'}
              type={'text'}
              size={'small'}
              icon={<EyeOutlined />}
              onClick={() => navigate(`/archived-batch/${record.id}`)}
            />
          </Space>
        ),
    },
    {
      title: 'Distributor',
      key: 'Distributor',
      dataIndex: 'Distributor.name',
      width: 140,
      render: (value, record) => _get(record, 'Distributor.name', '-'),
    },
    {
      title: 'Customer',
      key: 'customer_number',
      dataIndex: 'customer_number',
      width: 150,
      render: (value) => value || '-',
      align: 'right',
    },
    {
      title: 'Batch Date',
      key: 'batch_date',
      dataIndex: 'batch_date',
      width: 90,
      render: (value) => (value ? moment(value).format('YYYY-MM-DD') : '-'),
    },
    {
      title: 'Effective Date',
      key: 'effective_date',
      dataIndex: 'effective_date',
      sorter: true,
      width: 120,
      sortOrder:
        _get(batchData, 'sortBy') === 'effective_date'
          ? _get(batchData, 'sortDir') === 'DESC'
            ? 'descend'
            : 'ascend'
          : false,
    },
    {
      title: 'Batch ID',
      key: 'id',
      dataIndex: 'id',
      render: (value) => value || '-',
      sorter: true,
      width: 100,
      align: 'right',
      fixed: true,
      sortDirections: ['descend', 'ascend'],
      sortOrder:
        _get(batchData, 'sortBy') === 'id'
          ? _get(batchData, 'sortDir') === 'DESC'
            ? 'descend'
            : 'ascend'
          : false,
    },
    ...(activeTab == PAGES.BATCH_ALL
      ? [
          {
            title: 'New',
            key: 'new_count',
            dataIndex: 'new_count',
            sorter: true,
            align: 'right',
            width: 80,
            sortOrder:
              _get(batchData, 'sortBy') === 'new_count'
                ? _get(batchData, 'sortDir') === 'DESC'
                  ? 'descend'
                  : 'ascend'
                : false,
            render: (value, record) => (record.status == 'RC' ? '-' : value),
          },
          {
            title: 'Updated',
            key: 'updated_count',
            dataIndex: 'updated_count',
            sorter: true,
            align: 'right',
            width: 100,
            sortOrder:
              _get(batchData, 'sortBy') === 'updated_count'
                ? _get(batchData, 'sortDir') === 'DESC'
                  ? 'descend'
                  : 'ascend'
                : false,
            render: (value, record) => (record.status == 'RC' ? '-' : value),
          },
          {
            title: 'Discontinued',
            key: 'discontinued_count',
            dataIndex: 'discontinued_count',
            width: 110,
            align: 'right',
            sorter: true,
            sortOrder:
              _get(batchData, 'sortBy') === 'discontinued_count'
                ? _get(batchData, 'sortDir') === 'DESC'
                  ? 'descend'
                  : 'ascend'
                : false,
            render: (value, record) => (record.status == 'RC' ? '-' : value),
          },
          {
            title: 'Unchanged',
            key: 'unchanged_count',
            dataIndex: 'unchanged_count',
            width: 100,
            align: 'right',
            sorter: true,
            sortOrder:
              _get(batchData, 'sortBy') === 'unchanged_count'
                ? _get(batchData, 'sortDir') === 'DESC'
                  ? 'descend'
                  : 'ascend'
                : false,
            render: (value, record) => (record.status == 'RC' ? '-' : value),
          },
        ]
      : []),
    {
      title: 'Batch Total',
      key: 'item_count',
      dataIndex: 'item_count',
      sorter: true,
      align: 'right',
      width: 110,
      sortOrder:
        _get(batchData, 'sortBy') === 'item_count'
          ? _get(batchData, 'sortDir') === 'DESC'
            ? 'descend'
            : 'ascend'
          : false,
      render: (value) => value || '-',
    },
    {
      title: 'Batch Status',
      key: 'status',
      dataIndex: 'status',
      width: 105,
      render: (value, record) => (
        <Tag
          color={_get(BATCH_STATUS_COLOR_CODE[record.status], 'color')}
          style={{
            color: _get(BATCH_STATUS_COLOR_CODE[record.status], 'font'),
            borderRadius: 16,
          }}
        >
          {record.status === 'RC'
            ? 'Receiving'
            : _get(record, 'ProcessStatus.name', '-')}
        </Tag>
      ),
    },
  ];
  const loadBatchesData = () => {
    toggleBatchDataLoading(true);
    let params = {
      ...tablePaginationParams,
    };
    let successFn = function (result) {
      setBatchData(result);
      if (result.bad_cpn_flag) {
        dispatch(changeBadCpnModalState(true));
        dispatch(changeBadCpnModalName(BATCH));
      }
      toggleBatchDataLoading(false);
    };
    let errorFn = function () {
      toggleBatchDataLoading(false);
    };
    let api = null;
    switch (activeTab) {
      case PAGES.BATCH_ALL:
        api = ALL_BATCH_LIST;
        break;
      case PAGES.BATCH_ARCHIVED:
        api = ARCHIVED_BATCH_LIST;
        break;
      default:
        api = ALL_BATCH_LIST;
    }
    postAPI(api, params, successFn, errorFn);
  };
  const deleteBatch = (record) => {
    toggleBatchDataActionLoading(true);
    let successFn = function () {
      toggleBatchDataActionLoading(false);
      loadBatchesData();
    };
    let errorFn = function (data, error) {
      toggleBatchDataActionLoading(false);
      if (error?.response?.status === 423) loadBatchesData();
    };
    deleteAPI(interpolate(BATCH_SINGLE_DATA, [record.id]), successFn, errorFn);
  };
  const voidBatch = (record) => {
    toggleBatchDataActionLoading(true);
    let successFn = function () {
      toggleBatchDataActionLoading(false);
      loadBatchesData();
    };
    let errorFn = function (data, error) {
      toggleBatchDataActionLoading(false);
      if (error?.response?.status === 423) loadBatchesData();
    };
    postAPI(
      interpolate(VOID_SINGLE_BATCH, [record.id]),
      {},
      successFn,
      errorFn
    );
  };
  const exportBatch = (record) => {
    toggleBatchDataActionLoading(true);
    let successFn = function (result) {
      toggleBatchDataActionLoading(false);
    };
    let errorFn = function (data, error) {
      toggleBatchDataActionLoading(false);
      if (error?.response?.status === 423) loadBatchesData();
    };
    postAPI(
      interpolate(EXPORT_SINGLE_BATCH, [record.id]),
      {},
      successFn,
      errorFn
    );
  };
  const exportArchiveBatch = (record) => {
    toggleBatchDataActionLoading(true);
    let successFn = function (result) {
      toggleBatchDataActionLoading(false);
    };
    let errorFn = function (data, error) {
      toggleBatchDataActionLoading(false);
      if (error?.response?.status === 423) loadBatchesData();
    };
    postAPI(
      interpolate(EXPORT_SINGLE_ARCHIVED_BATCH, [record.id]),
      {},
      successFn,
      errorFn
    );
  };
  const setTableSortingParams = (field, order) => {
    setTablePaginationParams((prevState) => {
      return {
        ...prevState,
        sortBy: order ? field : undefined,
        sortDir: order ? (order === 'ascend' ? 'ASC' : 'DESC') : undefined,
      };
    });
  };

  const setDropDownItems = () => {
    const dropdownItems = DEFAULT_TABLE_PAGINATION_DROPDOWN_PAGE_COUNT.map(
      (item) => {
        return {
          ...item,
          label: (
            <Button
              type='text'
              style={{ width: '100%' }}
              onClick={() => {
                setTablePaginationParams({
                  ...tablePaginationParams,
                  limit: +item.key,
                });
              }}
            >
              {item.label}
            </Button>
          ),
        };
      }
    );
    return dropdownItems;
  };

  const deleteDuplicateItemId = (batchId, deleteItemIds, selectedItem) => {
    dispatch(changeApiLoader(true));

    let successFn = (result) => {
      dispatch(
        setMessage({
          type: TYPE_SUCCESS,
          msg: 'Selected Ids deleted successfully',
        })
      );
      dispatch(getDuplicateBatchesData(batchId));
      dispatch(getDuplicateBatchItemData(batchId, selectedItem));
      dispatch(changeApiLoader(false));
    };

    let errorFn = (error) => {
      dispatch(changeApiLoader(false));
    };

    deleteMultipleAPI(
      interpolate(DUPLICATE_BATCH_ITEMS_DELETE, [batchId]),
      { ids: deleteItemIds },
      successFn,
      errorFn
    );
  };

  return (
    <AppBase>
      <Spin spinning={codeListDataLoading}>
        <Title className={'page-title'}>Batch Management</Title>
        <Title className={'page-title'} level={3}>
          Batch List
        </Title>
        <BatchTableTitle
          setActiveTab={setActiveTab}
          activeTab={activeTab}
          tabItems={[
            {
              key: PAGES.BATCH_ALL,
              label: 'All Batches',
            },
            {
              key: PAGES.BATCH_ARCHIVED,
              label: 'Archive',
            },
          ]}
          setToggleFilters={setToggleFilters}
          toggleFilters={toggleFilters}
          loadBatchesData={loadBatchesData}
          setTablePaginationParams={setTablePaginationParams}
          tablePaginationParams={tablePaginationParams}
          activeFilters={activeFilters}
          setActiveFilters={setActiveFilters}
          distributorData={distributorData}
          batchDataLoading={batchDataLoading}
        />
        <Table
          className='data-table full-height-table'
          scroll={{ y: window.innerHeight }}
          size={'large'}
          rowKey='id'
          pagination={false}
          showSorterTooltip={false}
          dataSource={_get(batchData, 'data', [])}
          onChange={(a, b, sorting) =>
            setTableSortingParams(sorting.field, sorting.order)
          }
          columns={tableColumns}
          loading={batchDataLoading}
          footer={() => (
            <Row justify={'space-between'}>
              <Col>
                <Space>
                  <Dropdown
                    menu={{ items: setDropDownItems() }}
                    trigger={['click']}
                  >
                    <Button type='default'>
                      Items Per Page:&nbsp;
                      {_get(tablePaginationParams, 'limit')}
                      <DownOutlined className={'ml-5'} />
                    </Button>
                  </Dropdown>
                  <Typography>{`${_get(batchData, 'offset', 0) + 1} to ${
                    _get(batchData, 'data', []).length +
                    _get(batchData, 'offset', '--')
                  } of ${_get(batchData, 'total', '--')} items`}</Typography>
                </Space>
              </Col>
              <Col>
                <Button.Group>
                  <Button
                    type='ghost'
                    icon={<CaretLeftOutlined />}
                    onClick={() =>
                      setTablePaginationParams({
                        ...tablePaginationParams,
                        offset:
                          _get(batchData, 'offset', 0) -
                          _get(batchData, 'limit', 0),
                      })
                    }
                    disabled={!_get(batchData, 'offset', 0)}
                  />
                  <Button
                    type='ghost'
                    icon={<CaretRightOutlined />}
                    onClick={() =>
                      setTablePaginationParams({
                        ...tablePaginationParams,
                        offset:
                          _get(batchData, 'offset', 0) +
                          _get(batchData, 'limit', 0),
                      })
                    }
                    disabled={
                      _get(batchData, 'offset', 0) +
                        _get(batchData, 'limit', 0) >=
                      _get(batchData, 'total', 0)
                    }
                  />
                </Button.Group>
              </Col>
            </Row>
          )}
        />
        <DuplicateBatchModal
          open={duplicateModal}
          destroyOnClose={true}
          onCancel={() => setDuplicateModal(false)}
          onOk={(batchId, deleteItemIds, selectedItem) =>
            deleteDuplicateItemId(batchId, deleteItemIds, selectedItem)
          }
        />
        {drawerVisible ? (
          <ManufacturerCodeDrawer
            batchId={drawerVisible}
            key={drawerVisible}
            defaultOpen={true}
          />
        ) : null}
      </Spin>
    </AppBase>
  );
}

export default BatchManagement;
