import AppBase from '../../base/AppBase';
import { Card, Tabs, Typography } from 'antd';
import React from 'react';
import AboutTab from './aboutTab';
import SyncConnectorManagement from './syncConnectorTab';
import APIKeyManagement from './apiKeys';
import GeneralSystem from './generalTab';

const { Title } = Typography;

function SystemConfiguration() {
  return (
    <AppBase>
      <Title className={'page-title'}>System Configuration</Title>
      <Tabs
        type='card'
        className={'system-config-tabs'}
        items={[
          {
            key: 'general',
            label: 'General',
            children: <GeneralSystem />,
          },
          {
            key: 'sync-connectors',
            label: 'Sync Connectors',
            children: (
              <Card>
                <SyncConnectorManagement />
              </Card>
            ),
          },
          {
            key: 'api-keys',
            label: 'API Keys',
            children: (
              <Card>
                <APIKeyManagement />
              </Card>
            ),
          },
          {
            key: 'about',
            label: 'About',
            children: (
              <Card>
                <AboutTab />
              </Card>
            ),
          },
        ]}
      />
    </AppBase>
  );
}

export default SystemConfiguration;
