import { useNavigate, useParams } from 'react-router';
import {useLocation} from 'react-router-dom';

export const withNavigation = (Component) => {
  return (props) => <Component {...props} navigate={useNavigate()} />;
};

export const withParams = (Component) => {
  return (props) => <Component {...props} params={useLocation()} />;
};

export const withParamsAndNavigate = (Component) => {
  return (props) => (
    <Component {...props} navigate={useNavigate()} params={useLocation()} location={useParams()} />
  );
};
