import { AUTH_LOGIN_TYPE, AUTH_LOGOUT_TYPE, AUTH_ORG_DETAIL, AUTH_USER_DATA } from '../reduxConstant';

import { getAPI, getOuterAPI } from '../../utils/apiRequest';
import { CONNECT_PORTAL_INFO_BY_ID, PORTAL_INFO_BY_UUID, USER_CLONE } from '../../constants/api';
import { Store } from '../store';
import { get } from 'lockr';
import { AUTH_TOKEN } from '../../constants/dataKeys';
import { interpolate } from '../../utils/common';


export const loginUserAuthAction = (userData) => (dispatch, getState) => {
  return dispatch({
    type: AUTH_LOGIN_TYPE,
    payload: userData,
  });
};

export const logoutUserAuthAction = (userData) => (dispatch, getState) => {
  return dispatch({
    type: AUTH_LOGOUT_TYPE,
    payload: userData,
  });
};

export const userOrgAction = (orgData) => (dispatch, getState) => {
  return dispatch({
    type: AUTH_ORG_DETAIL,
    payload: orgData,
  });
};

export const cloneUser = (that) => {
  that?.setState({
    loadingPermissions:true
  });
  let successFn = function (data) {
    that?.setState({
      loadingPermissions:false
    });
    Store.dispatch({
      type: AUTH_USER_DATA,
      payload: data.data,
    });
  };
  let errorFn = function () {
    that?.setState({
      loadingPermissions:false
    });
  };
  if(get(AUTH_TOKEN))
  getAPI(USER_CLONE, successFn, errorFn);
  else
    that?.setState({
      loadingPermissions:false
    });
};

export const cloneOrgDetails = (org_uuid) => {
  let successFn = function (data) {
    Store.dispatch({
      type: AUTH_ORG_DETAIL,
      payload: data.data,
    });
  };
  let errorFn = function () {

  };
  getOuterAPI(process.env.REACT_APP_MASTER_BACKEND + interpolate(PORTAL_INFO_BY_UUID, [org_uuid]),  successFn, errorFn);
};
