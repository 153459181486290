import {
  GLOBAL_PAGES_FILTER_DATA,
  GLOBAL_PAGES_FILTER_TOGGLE,
  GLOBAL_PAGES_PAGINATION,
  GLOBAL_SIDER_COLLAPSED,
  GLOBAL_SIDER_MENU,
  GLOBAL_UPDATE_ROUTE,
} from '../reduxConstant';
import { _get } from '../../utils/lodashUtils';
import {
  GLOBAL_PAGES_ACTIVE_FILTER_DEFAULT_STATE,
  GLOBAL_PAGES_PAGINATION_DEFAULT_STATE,
  GLOBAL_PAGES_TOGGLE_FILTERS_DEFAULT_STATE,
} from './globalDefaultStates';
import { isEmpty } from 'lodash';

export default (state = {}, action) => {
  switch (action.type) {
    case GLOBAL_UPDATE_ROUTE:
      return {
        ...state,
        route: action.payload,
      };
    case GLOBAL_SIDER_COLLAPSED:
      return {
        ...state,
        siderCollapsed: action.payload,
      };
    case GLOBAL_SIDER_MENU:
      return {
        ...state,
        currentSiderMenu: _get(action, 'payload'),
      };
    case GLOBAL_PAGES_PAGINATION:
      return {
        ...state,
        globalPagesPagination: {
          ...state.globalPagesPagination,
          [action.payload.tab]: action.payload.data || {},
        },
      };
    case GLOBAL_PAGES_FILTER_TOGGLE:
      return {
        ...state,
        globalPagesFilterToggle: {
          ...state.globalPagesFilterToggle,
          [action.payload.tab]: !!action.payload.data,
        },
      };
    case GLOBAL_PAGES_FILTER_DATA:
      return {
        ...state,
        globalPagesFilterData: {
          ...state.globalPagesFilterData,
          [action.payload.tab]: action.payload.data || {},
        },
      };
    default:
      return {
        ...state,
        globalPagesPagination: isEmpty(state.globalPagesPagination)
          ? GLOBAL_PAGES_PAGINATION_DEFAULT_STATE
          : { ...state.globalPagesPagination },
        globalPagesFilterData: isEmpty(state.globalPagesFilterData)
          ? GLOBAL_PAGES_ACTIVE_FILTER_DEFAULT_STATE
          : { ...state.globalPagesFilterData },
        globalPagesFilterToggle: isEmpty(state.globalPagesFilterToggle)
          ? GLOBAL_PAGES_TOGGLE_FILTERS_DEFAULT_STATE
          : { ...state.globalPagesFilterToggle },
      };
  }
};
