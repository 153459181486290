import { createSlice } from '@reduxjs/toolkit';
import { SYSTEM_SYNC_CONNECTOR_INTEGRATION } from '../reduxConstant';
import { getAPI } from '../../utils/apiRequest';
import {
  INTEGRATION_DISTRIBUTOR_DROPDOWN_LIST,
  INTEGRATION_DISTRIBUTOR_LIST,
  INTEGRATION_GENERAL_EDIT_FIELD_TABLE_COLUMN_DATA,
  INTEGRATION_GENERAL_EDIT_SALES_TAX_CLASSIFICATION_LIST,
  INTEGRATION_GENERAL_EDIT_TARGET_DATA,
  INTEGRATION_GENERAL_LIST,
  INTEGRATION_ITEM_TYPE_DROPDOWN_LIST,
  INTEGRATION_ITEM_TYPE_LIST,
  INTEGRATION_MANUFACTURER_DROPDOWN_LIST,
  INTEGRATION_MANUFACTURER_LIST,
  INTEGRATION_SYSTEM_OPTIONS,
  INTGERATION_CATEGORY_DROPDOWN_LIST,
  INTGERATION_CATEGORY_LIST,
  INTGERATION_LABOR_CATEGORY_DROPDOWN_LIST,
  INTGERATION_LABOR_CATEGORY_LIST,
} from '../../constants/api';
import { lowerCase } from 'lodash';
import {
  CATEGORY,
  DISTRIBUTOR,
  INTEGRATION_LIST,
  INTGERATION_DROPDOWN_LIST,
  LABOR_CATEGORY,
  MANUFACTURER,
  GENERAL,
  ITEM_TYPE,
} from '../../constants/constants';
import { interpolate } from '../../utils/common';
import { changeApiLoader } from './apiLoaderSlice';

export const systemSyncConnectorIntegrationSlice = createSlice({
  name: SYSTEM_SYNC_CONNECTOR_INTEGRATION,
  initialState: {
    systemOptionsData: {},
    generalData: [],
    distributorData: [],
    distributorDropdownData: [],
    manufacturerData: [],
    manufacturerDropdownData: [],
    categoryData: [],
    categoryDropdownData: [],
    laborCategoryData: [],
    laborCategoryDropdownData: [],
    itemTypeData: [],
    itemTypeDropdownData: [],
    generalEditFieldTableColumnData: [],
    generalEditTargetData: [],
    generalEditSalesTaxClassificationData: [],
  },
  reducers: {
    setSystemOptionsData: (state, action) => {
      state.systemOptionsData = action.payload;
    },
    setSyncConnectorGeneralData: (state, action) => {
      state.generalData = action.payload;
    },
    setSyncConnectorDistributorData: (state, action) => {
      state.distributorData = action.payload;
    },
    setSyncConnectorDistributorDropdownData: (state, action) => {
      state.distributorDropdownData = action.payload;
    },
    setSyncConnectorManufacturerData: (state, action) => {
      state.manufacturerData = action.payload;
    },
    setSyncConnectorManufacturerDropdownData: (state, action) => {
      state.manufacturerDropdownData = action.payload;
    },
    setSyncConnectorCategoryData: (state, action) => {
      state.categoryData = action.payload;
    },
    setSyncConnectorCategoryDropdownData: (state, action) => {
      state.categoryDropdownData = action.payload;
    },
    setSyncConnectorLaborCategoryData: (state, action) => {
      state.laborCategoryData = action.payload;
    },
    setSyncConnectorLaborCategoryDropdownData: (state, action) => {
      state.laborCategoryDropdownData = action.payload;
    },
    setSyncConnectorItemTypeData: (state, action) => {
      state.itemTypeData = action.payload;
    },
    setSyncConnectorItemTypeDropdownData: (state, action) => {
      state.itemTypeDropdownData = action.payload;
    },
    setGeneralEditFieldTableColumnData: (state, action) => {
      state.generalEditFieldTableColumnData = action.payload;
    },
    setGeneralEditTargetData: (state, action) => {
      state.generalEditTargetData = action.payload;
    },
    setGeneralEditSalesTaxClassificationData: (state, action) => {
      state.generalEditSalesTaxClassificationData = action.payload;
    },
  },
});

const switchListUrls = (tab) => {
  switch (tab) {
    case lowerCase(GENERAL):
      return INTEGRATION_GENERAL_LIST;
    case lowerCase(DISTRIBUTOR):
      return INTEGRATION_DISTRIBUTOR_LIST;
    case lowerCase(MANUFACTURER):
      return INTEGRATION_MANUFACTURER_LIST;
    case lowerCase(CATEGORY):
      return INTGERATION_CATEGORY_LIST;
    case lowerCase(LABOR_CATEGORY.split(' ').join('_')):
      return INTGERATION_LABOR_CATEGORY_LIST;
    case lowerCase(ITEM_TYPE.split(' ').join('_')):
      return INTEGRATION_ITEM_TYPE_LIST;
    default:
      return INTEGRATION_DISTRIBUTOR_LIST;
  }
};

const switchDropdownListUrls = (tab) => {
  switch (tab) {
    case lowerCase(DISTRIBUTOR):
      return INTEGRATION_DISTRIBUTOR_DROPDOWN_LIST;
    case lowerCase(MANUFACTURER):
      return INTEGRATION_MANUFACTURER_DROPDOWN_LIST;
    case lowerCase(CATEGORY):
      return INTGERATION_CATEGORY_DROPDOWN_LIST;
    case lowerCase(LABOR_CATEGORY.split(' ').join('_')):
      return INTGERATION_LABOR_CATEGORY_DROPDOWN_LIST;
    case lowerCase(ITEM_TYPE.split(' ').join('_')):
      return INTEGRATION_ITEM_TYPE_DROPDOWN_LIST;
    default:
      return INTEGRATION_DISTRIBUTOR_DROPDOWN_LIST;
  }
};

const switchUrls = (type, tab) => {
  switch (type) {
    case INTEGRATION_LIST:
      return switchListUrls(tab);
    case INTGERATION_DROPDOWN_LIST:
      return switchDropdownListUrls(tab);
    default:
      return;
  }
};

const formCategoryTreeData = (data) => {
  return data.map((item) => {
    if (item.children && item.children.length === 0) {
      delete item.children;
    } else if (item.children && item.children.length > 0) {
      item = {
        ...item,
        children: formCategoryTreeData(item.children),
      };
    }
    return item;
  });
};

export const switchListSetFunction = (tab, dispatch, result) => {
  switch (tab) {
    case lowerCase(GENERAL):
      dispatch(setSyncConnectorGeneralData(result.data));
      break;
    case lowerCase(DISTRIBUTOR):
      dispatch(setSyncConnectorDistributorData(result.data));
      break;
    case lowerCase(MANUFACTURER):
      dispatch(setSyncConnectorManufacturerData(result.data));
      break;
    case lowerCase(CATEGORY):
      dispatch(setSyncConnectorCategoryData(formCategoryTreeData(result.data)));
      break;
    case lowerCase(LABOR_CATEGORY.split(' ').join('_')):
      dispatch(setSyncConnectorLaborCategoryData(result.data));
      break;
    case lowerCase(ITEM_TYPE.split(' ').join('_')):
      dispatch(setSyncConnectorItemTypeData(result.data));
      break;
    default:
      dispatch(setSyncConnectorDistributorData(result.data));
      break;
  }
};

const switchDropdownSetFunction = (tab, dispatch, result) => {
  switch (tab) {
    case lowerCase(DISTRIBUTOR):
      dispatch(setSyncConnectorDistributorDropdownData(result.data));
      break;
    case lowerCase(MANUFACTURER):
      dispatch(setSyncConnectorManufacturerDropdownData(result.data));
      break;
    case lowerCase(CATEGORY):
      dispatch(setSyncConnectorCategoryDropdownData(result.data));
      break;
    case lowerCase(LABOR_CATEGORY.split(' ').join('_')):
      dispatch(setSyncConnectorLaborCategoryDropdownData(result.data));
      break;
    case lowerCase(ITEM_TYPE.split(' ').join('_')):
      dispatch(setSyncConnectorItemTypeDropdownData(result.data));
      break;
    default:
      dispatch(setSyncConnectorDistributorDropdownData(result.data));
      break;
  }
};

const switchSetFunction = (type, tab, dispatch, result) => {
  switch (type) {
    case INTEGRATION_LIST:
      switchListSetFunction(tab, dispatch, result);
      break;
    case INTGERATION_DROPDOWN_LIST:
      switchDropdownSetFunction(tab, dispatch, result);
      break;
    default:
      break;
  }
};

export const getSyncConnectorIntegrationsData = (tab, id) => (dispatch) => {
  dispatch(changeApiLoader(true));
  let successFn = function (result) {
    switchSetFunction(INTEGRATION_LIST, tab, dispatch, result);
    dispatch(changeApiLoader(false));
  };
  let errorFn = function () {
    dispatch(changeApiLoader(false));
  };
  getAPI(
    interpolate(switchUrls(INTEGRATION_LIST, tab), [id]),
    successFn,
    errorFn
  );
};

export const getSyncConnectorIntegrationsDropdownData =
  (tab, id) => (dispatch) => {
    let successFn = function (result) {
      switchSetFunction(INTGERATION_DROPDOWN_LIST, tab, dispatch, result);
    };
    let errorFn = function () {};
    getAPI(
      interpolate(switchUrls(INTGERATION_DROPDOWN_LIST, tab), [id]),
      successFn,
      errorFn
    );
  };

export const getGeneralEditFieldTableColumnData = (id) => (dispatch) => {
  dispatch(changeApiLoader(true));
  let successFn = function (result) {
    dispatch(changeApiLoader(false));
    dispatch(setGeneralEditFieldTableColumnData(result.data));
  };
  let errorFn = function (error) {
    dispatch(changeApiLoader(false));
    console.log('error', error);
  };
  getAPI(
    interpolate(INTEGRATION_GENERAL_EDIT_FIELD_TABLE_COLUMN_DATA, [id]),
    successFn,
    errorFn
  );
};

export const getGeneralEditTargetData = (id, field_name) => (dispatch) => {
  dispatch(changeApiLoader(true));
  let successFn = function (result) {
    dispatch(changeApiLoader(false));
    dispatch(setGeneralEditTargetData(result.data));
  };
  let errorFn = function (error) {
    dispatch(changeApiLoader(false));
    console.log('error', error);
  };
  getAPI(
    interpolate(INTEGRATION_GENERAL_EDIT_TARGET_DATA, [id, field_name]),
    successFn,
    errorFn
  );
};

function list_to_tree(list) {
  var map = {},
    node,
    roots = [],
    i;

  for (i = 0; i < list.length; i += 1) {
    map[list[i].id] = i;
    list[i].items = [];
  }

  for (i = 0; i < list.length; i += 1) {
    node = list[i];
    if (node.parent_id && map.hasOwnProperty(node.parent_id)) {
      list[map[node.parent_id]].items.push(node);
    } else {
      roots.push(node);
    }
  }
  return roots;
}

export const getGeneralEditSalesTaxClassificationData = (id) => (dispatch) => {
  dispatch(changeApiLoader(true));
  let successFn = function (result) {
    const { data } = result;
    let treeData = list_to_tree(data);
    dispatch(setGeneralEditSalesTaxClassificationData(treeData));
    dispatch(changeApiLoader(false));
  };
  let errorFn = function (error) {
    console.log('errorFn', error);
    dispatch(changeApiLoader(false));
  };
  getAPI(
    interpolate(INTEGRATION_GENERAL_EDIT_SALES_TAX_CLASSIFICATION_LIST, [id]),
    successFn,
    errorFn
  );
};

export const getSystemOptionsData = (id) => (dispatch) => {
  dispatch(changeApiLoader(true));
  let successFn = function (result) {
    if (Array.isArray(result.data) && result.data.length > 0) {
      dispatch(setSystemOptionsData(result.data[0]));
    }
    dispatch(changeApiLoader(false));
  };
  let errorFn = function (error) {
    console.log('errorFn', error);
    dispatch(changeApiLoader(false));
  };
  getAPI(interpolate(INTEGRATION_SYSTEM_OPTIONS, [id]), successFn, errorFn);
};

export const {
  setSystemOptionsData,
  setSyncConnectorGeneralData,
  setSyncConnectorDistributorData,
  setSyncConnectorDistributorDropdownData,
  setSyncConnectorManufacturerData,
  setSyncConnectorManufacturerDropdownData,
  setSyncConnectorCategoryData,
  setSyncConnectorCategoryDropdownData,
  setSyncConnectorLaborCategoryData,
  setSyncConnectorLaborCategoryDropdownData,
  setSyncConnectorItemTypeData,
  setSyncConnectorItemTypeDropdownData,
  setGeneralEditFieldTableColumnData,
  setGeneralEditTargetData,
  setGeneralEditSalesTaxClassificationData,
} = systemSyncConnectorIntegrationSlice.actions;

export default systemSyncConnectorIntegrationSlice.reducer;
