import React, { useEffect, useState } from 'react';
import { getAPI } from '../../../utils/apiRequest';
import { ABOUT_SYSTEM } from '../../../constants/api';
import { Col, Divider, Row, Spin, Typography } from 'antd';
import { _get } from '../../../utils/lodashUtils';

const { Title, Text } = Typography;

function AboutTab() {
  const [aboutData, setAboutData] = useState({});
  const [aboutDataLoading, toggleAboutDataLoading] = useState(false);
  useEffect(() => {
    loadConfiguration();
  }, []);
  const loadConfiguration = () => {
    toggleAboutDataLoading(true);
    let successFn = function(result) {
      setAboutData(result.data);
      toggleAboutDataLoading(false);
    };
    let errorFn = function() {
      toggleAboutDataLoading(false);
    };
    getAPI(ABOUT_SYSTEM, successFn, errorFn);
  };
  return <>
    <Title level={2} className={'page-title'}>About</Title>
    <Row>
      <Col md={12} xs={24}>
        <Spin spinning={aboutDataLoading}>
          <AboutRowRender title={'Organization Id'} value={_get(aboutData, 'id', '--')} />
          <AboutRowRender title={'Organization'} value={_get(aboutData, 'name', '--')} />
          <AboutRowRender title={'Database Version'} value={_get(aboutData, 'db_version', '--')} />
          <AboutRowRender title={'Contact Info'} value={_get(aboutData, 'org_email', '--')} />
          <AboutRowRender title={'Version'} value={_get(aboutData, 'version', '--')} />
        </Spin>
      </Col>
    </Row>
  </>;
}

export default AboutTab;

const AboutRowRender = function({ title, value }) {
  return <Row>
    <Col span={12}>
      <Text type={'secondary'}>{title}</Text>
    </Col>
    <Col span={12}>
      <Text>{value}</Text>
    </Col>
    <Col span={24}>
      <Divider className={'mt-0 mb-0'} />
    </Col>
  </Row>;
};
