import React, { useState } from 'react';
import { EditCatalogCommonTabs } from './CommonTabChildren';
import { Space, Spin, Switch, Table, Tabs, Tooltip, Typography } from 'antd';
import { EyeOutlined, FormOutlined, MenuOutlined } from '@ant-design/icons';
import {
  SortableContainer,
  SortableElement,
  SortableHandle,
} from 'react-sortable-hoc';
import get from 'lodash/get';
import { ROLE_CATALOG_READ_ONLY_USER } from '../../../../constants/roles';
import { _get } from '../../../../utils/lodashUtils';
import { DESCRIPTION_PARAGRAPH_ELLIPSIS } from '../../../../constants/hardData';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import {
  setDistributorPagination,
  setEditCatalogDistributorData,
} from '../../../../redux/Slices/editCatalogTabSlice';

const DragHandle = SortableHandle(() => (
  <MenuOutlined
    style={{
      cursor: 'grab',
      color: '#999',
    }}
  />
));
const SortableItem = SortableElement((props) => <tr {...props} />);
const SortableBody = SortableContainer((props) => <tbody {...props} />);
const { Paragraph } = Typography;

export const EditCatalogDistributorTab = (props) => {
  const {
    loadCatalogDistributors,
    apiLoader,
    distributorData,
    onSortEnd,
    roleCode,
    toggleEditDistributorModal,
    catalogDetails,
    updateDistributorData,
    distributorTabKey,
    setDistributorTabKey,
  } = props;

  let dispatch = useDispatch();

  const distributorPagination = useSelector(
    (state) => state.editCatalogTabReducer.distributorPagination
  );

  const DraggableContainer = (props) => (
    <SortableBody
      useDragHandle
      disableAutoscroll
      helperClass='row-dragging'
      onSortEnd={onSortEnd}
      {...props}
    />
  );

  const DraggableBodyRow = ({ className, style, ...restProps }) => {
    // function findIndex base on Table rowKey props and should always be a right array index
    const index = _get(distributorData, 'data', [])?.findIndex(
      (x) => x.id === restProps['data-row-key']
    );
    return <SortableItem index={index} {...restProps} />;
  };

  const tableColumns = (tab) => {
    const activeTableColumns = [
      {
        dataIndex: 'sort',
        ellipsis: true,
        width: 30,
        className: 'drag-visible',
        render: () => <DragHandle />,
        hidden: roleCode === ROLE_CATALOG_READ_ONLY_USER,
      },
      {
        title: roleCode === ROLE_CATALOG_READ_ONLY_USER ? 'View' : 'Edit',
        key: 'Actions',
        ellipsis: true,
        width: 70,
        render: (value, record) => (
          <Space>
            {roleCode === ROLE_CATALOG_READ_ONLY_USER ? (
              <Tooltip overlayClassName='tooltip' title={'View'}>
                <EyeOutlined
                  onClick={() => toggleEditDistributorModal(record)}
                />
              </Tooltip>
            ) : (
              <Tooltip overlayClassName='tooltip' title={'Edit'}>
                <FormOutlined
                  onClick={() => toggleEditDistributorModal(record)}
                />
              </Tooltip>
            )}
          </Space>
        ),
      },
      {
        title: 'Distributor',
        dataIndex: 'name',
        ellipsis: true,
        width: 150,
        render: (value, record) => _get(record, 'Distributor.name', '-'),
      },
      {
        title: 'Item Number',
        dataIndex: 'item_number',
        key: 'item_number',
        ellipsis: true,
        width: 120,
        render: (value, record) =>
          _get(record, 'distributor_id', '-') === 999999999
            ? _get(catalogDetails, 'manufacturer_item_number', '-')
            : value || '-',
      },
      {
        title: 'Description',
        key: 'description',
        dataIndex: 'description',
        ellipsis: true,
        width: 260,
        render: (value, record) =>
          value || _get(catalogDetails, 'description') ? (
            <Paragraph
              ellipsis={{
                ...DESCRIPTION_PARAGRAPH_ELLIPSIS,
                tooltip: value ? value : _get(catalogDetails, 'description'),
              }}
              style={{ maxWidth: 260 }}
              key={record.id}
            >
              {value ? value : _get(catalogDetails, 'description')}
            </Paragraph>
          ) : (
            '-'
          ),
      },
      {
        title: 'Cost',
        dataIndex: 'current_cost',
        ellipsis: true,
        width: 110,
        align: 'right',
      },
      {
        title: 'MSRP',
        dataIndex: 'msrp',
        key: 'msrp',
        ellipsis: true,
        width: 110,
        align: 'right',
        render: (value) => value || '-',
      },
      {
        title: 'Discontinued',
        key: 'discontinued',
        dataIndex: 'discontinued',
        ellipsis: true,
        width: 125,
        render: (value, record) => (
          <Switch
            checked={value}
            className='ant-switch-green'
            onClick={(checked) =>
              updateDistributorData(
                {
                  item_master_distributor_id: record.id,
                  is_active: record.is_active,
                  discontinued: checked,
                },
                true
              )
            }
          />
        ),
      },
      {
        title: 'Active',
        key: 'active',
        dataIndex: 'is_active',
        ellipsis: true,
        width: 100,
        render: (value, record) => (
          <Switch
            checked={value}
            className='ant-switch-green'
            onClick={(checked) =>
              updateDistributorData(
                {
                  item_master_distributor_id: record.id,
                  is_active: checked,
                  discontinued: record.discontinued,
                },
                true
              )
            }
          />
        ),
      },
      {
        title: 'Date Active',
        key: 'active_date',
        dataIndex: 'active_date',
        ellipsis: true,
        width: 130,
        render: (value) => (value ? moment(value).format('YYYY-MM-DD') : '-'),
      },
      {
        title: 'Date Inactive',
        key: 'inactive_date',
        dataIndex: 'inactive_date',
        ellipsis: true,
        width: 130,
        render: (value) => (value ? moment(value).format('YYYY-MM-DD') : '-'),
      },
      {
        title: 'RMR',
        dataIndex: 'rmr',
        key: 'rmr',
        ellipsis: true,
        width: 110,
        align: 'right',
        render: (value) => value || '-',
      },
      {
        title: 'RMR Frequency',
        dataIndex: 'rmr_frequency',
        key: 'rmr_frequency',
        ellipsis: true,
        width: 160,
        render: (value) => value || '-',
      },
    ].filter((item) => !item.hidden);

    const inactiveTableColumns = activeTableColumns.slice(1).map((item) => {
      if (item.title === 'Distributor') {
        item = {
          ...item,
          sorter: true,
          sortOrder:
            _get(distributorData, 'sortBy') === 'distributor_name'
              ? _get(distributorData, 'sortDir') === 'DESC'
                ? 'descend'
                : 'ascend'
              : false,
        };
      }
      return item;
    });
    return tab === 'active' ? activeTableColumns : inactiveTableColumns;
  };

  const tabChildren = (tab) => {
    const commonProps = {
      className: 'mt-30',
      dataSource: _get(distributorData, 'data', []),
      rowKey: 'id',
      size: 'small',
      scroll: { y: 300 },
      columns: tableColumns(tab),
      pagination: {
        position: ['bottomRight'],
        hideOnSinglePage: false,
        showSizeChanger: true,
        pageSize: _get(distributorData, 'limit', 0),
        total: _get(distributorData, 'total', 0),
        current: distributorPagination.current,
        pageSizeOptions: [10, 25, 50, 100],
      },
      onChange: (paginationObj, filterObj, sorterObj) => {
        let inactiveSorterObj = get(sorterObj, 'order')
          ? {
              sortBy: 'distributor_name',
              sortDir: get(sorterObj, 'order') === 'ascend' ? 'ASC' : 'DESC',
            }
          : {
              sortBy: 'distributor_name',
              sortDir: 'ASC',
            };
        let paginationVals = {
          limit: paginationObj.pageSize,
          offset:
            paginationObj.pageSize * paginationObj.current -
            paginationObj.pageSize,
        };
        dispatch(
          setDistributorPagination({
            current: paginationObj.current,
            ...paginationVals,
          })
        );

        tab === 'inactive'
          ? loadCatalogDistributors(
              { is_active: false },
              inactiveSorterObj,
              paginationVals
            )
          : loadCatalogDistributors({ is_active: true }, {}, paginationVals);
      },
    };

    let extraActiveTableProp = {
      components: {
        body: {
          wrapper: DraggableContainer,
          row: DraggableBodyRow,
        },
      },
    };

    return (
      <Table {...commonProps} {...(tab === 'active' && extraActiveTableProp)} />
    );
  };

  const distributionTabItems = [
    {
      key: 'ACTIVE',
      label: 'Active',
      children: tabChildren('active'),
    },
    {
      key: 'NOT_ACTIVE',
      label: 'Not Active',
      children: tabChildren('inactive'),
    },
  ];
  return (
    <EditCatalogCommonTabs title={'Distributor'}>
      <Spin spinning={apiLoader}>
        <Tabs
          type='card'
          activeKey={distributorTabKey}
          className={'system-config-tabs'}
          destroyInactiveTabPane={true}
          items={distributionTabItems}
          onChange={(activeKey) => {
            setDistributorTabKey(activeKey);
            dispatch(setEditCatalogDistributorData([]));
            activeKey === 'ACTIVE'
              ? loadCatalogDistributors(
                  { is_active: true },
                  { sortBy: 'order' }
                )
              : loadCatalogDistributors(
                  { is_active: false },
                  { sortBy: 'distributor_name', sorDir: 'ASC' }
                );
          }}
        />
      </Spin>
    </EditCatalogCommonTabs>
  );
};
