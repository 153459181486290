import React from 'react';
import ReactDOM from 'react-dom/client';
import { ConfigProvider } from 'antd';
import {
  PRIMARY_COLOR,
  LAYOUT_BODY_BACKGROUND,
  DEFAULT_FONT_WEIGHT,
  DEFAULT_BORDER_RADIUS,
} from './constants/constants';
import './assets/css/custom.css';
import './assets/css/theme.css';
import './assets/css/constant.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

const defaultTheme = {
  colorPrimary: PRIMARY_COLOR,
  colorPrimaryActive: PRIMARY_COLOR,
  colorLink: PRIMARY_COLOR,
  colorBgLayout: LAYOUT_BODY_BACKGROUND,
  fontWeightStrong: DEFAULT_FONT_WEIGHT,
  borderRadius: DEFAULT_BORDER_RADIUS,
};
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <ConfigProvider
      theme={{
        token: defaultTheme,
      }}
    >
      <App />
    </ConfigProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
