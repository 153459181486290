import React, { Component } from 'react';
import AuthBase from '../AuthBase';
import { Button, Card, Col, Divider, Form, Input, Row, Spin } from 'antd';
import { getOuterAPI, postAPI, postWithOutTokenAPI } from '../../../../utils/apiRequest';
import { displayMessage, interpolate } from '../../../../utils/common';
import { APPLICATION_URL, AUTH_ORG_UUID, SUCCESS_MSG_TYPE } from '../../../../constants/dataKeys';
import { PORTAL_INFO_BY_UUID, RESET_PASSWORD, RESET_PASSWORD_MAIL } from '../../../../constants/api';
import { REQUIRED_FIELD_MESSAGE } from '../../../../constants/message';
import { set } from 'lockr';
import { _get } from '../../../../utils/lodashUtils';
import { Link } from 'react-router-dom';

class ResetPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      otpSent:false,
      otpVerified:false,
      loading:false
    };
  }
  checkOrganisation = (values) => {
    let that = this;
    that.setState({
      loading: true,
    });
    let successFn = function(result) {
      set(AUTH_ORG_UUID, _get(values, 'org_uuid'));
      set(APPLICATION_URL, _get(result, 'data.portal_backend_url'));
      that.sendOtp({ ...values, org_uuid: undefined });
    };
    let errorFn = function() {
      that.setState({
        loading: false,
      });
    };
    getOuterAPI(process.env.REACT_APP_MASTER_BACKEND + interpolate(PORTAL_INFO_BY_UUID, [values.org_uuid]), successFn, errorFn);
  };
  sendOtp = (values) => {
    let that = this;
    let reqData = {
      ...values,
    };
    let successFn = function (result) {
      that.setState({
        otpSent:true,
        loading: false,
      })

    };
    let errorFn = function (error) {
      that.setState({
        loading: false,
      })
    };
    delete reqData.repeat_password;
    postWithOutTokenAPI(RESET_PASSWORD_MAIL, reqData, successFn, errorFn);
  };

  render() {
    let that = this;
    const {otpSent, otpVerified, loading} = this.state;
    return (
      <AuthBase>
        <Row>
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <h2 className={'auth-type-title'}>Forgot Password</h2>
            {!otpSent ? <Spin spinning={loading}>
              <p className='mt-10-n'>Please enter your email id to reset your password</p>
              <Form
                layout={"vertical"}
                id={'components-form-demo-normal-login'}
                name='reset-password'
                size={'large'}
                onFinish={this.checkOrganisation}
              >
              <Form.Item
                label='Organization ID'
                name='org_uuid'
                rules={[
                  { required: true, message: REQUIRED_FIELD_MESSAGE },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label='Email Id'
                name='email'
                rules={[{
                  type: 'email',
                  message: 'Email ID is not valid',
                },
                  { required: true, message: REQUIRED_FIELD_MESSAGE },
                ]}
              >
                <Input />
              </Form.Item>
                <Form.Item >
                  <Button
                    type={'secondary'}
                    className='login-form-button theme-color'
                    htmlType='submit'
                  >
                    Submit
                  </Button>
                </Form.Item>
              </Form>
              <Divider />
              <Link to={'/login'}>Back to Login</Link>
            </Spin> :
              <p className='mt-10-n'>An email with password reset link has been sent to the mail. Kindly check your mail and open Reset Password link to generate the new password.</p>
         }
          </Col>
        </Row>
      </AuthBase>
    );
  }
}

export default ResetPassword;
