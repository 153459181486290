import { createSlice } from "@reduxjs/toolkit";
import { SYNC_CONNECTOR_FILTER_DATA } from "../reduxConstant";
import { postAPI } from "../../utils/apiRequest";
import { CONNECTOR_LIST } from "../../constants/api";

export const syncConnectorFilterDataSlice = createSlice({
    name: SYNC_CONNECTOR_FILTER_DATA,
    initialState: {
        data: {}
    },
    reducers: {
        setSyncConnectorFilterData: (state, action) => {
            state.data = action.payload
        }
    }
})

export const getSyncConnectorFilterData = () => dispatch => {
    let params = {
        limit:1000,
        filters: {is_active:true}
    };
    let successFn = function(result) {
        dispatch(setSyncConnectorFilterData(result))
    };
    let errorFn = function() {
    };
    postAPI(CONNECTOR_LIST, params, successFn, errorFn);
}

export const { setSyncConnectorFilterData } = syncConnectorFilterDataSlice.actions;

export default syncConnectorFilterDataSlice.reducer;