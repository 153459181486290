import React from 'react';
import { EditCatalogCommonTabs } from './CommonTabChildren';
import { Input, Table } from 'antd';
import { _get } from '../../../../utils/lodashUtils';

export const EditCatalogLaborTab = (props) => {
  const { apiLoader, laborData, updateLabor } = props;

  const tableColumns = () => {
    const columns = [
      {
        title: 'Description',
        width: 100,
        dataIndex: 'name',
        key: 'name',
      },
      {
        title: 'Value',
        dataIndex: 'value',
        key: 'value',
        width: 100,
        render: (value, record, index) => (
          <Input
            type='number'
            value={value}
            onKeyDown={(evt) => {
              if (evt.key === 'e' || evt.key === '.') {
                evt.preventDefault();
              }
            }}
            onChange={(e) => {
              if (e.target.value !== 'e') {
                updateLabor(record.id, e.target.value, index);
              }
            }}
            min={0}
            style={{ maxWidth: '15%' }}
          />
        ),
      },
    ];
    return columns;
  };

  return (
    <EditCatalogCommonTabs title={'Labor'}>
      <Table
        size={'small'}
        key={apiLoader}
        rowKey={'id'}
        scroll={{ y: 300 }}
        virtual={true}
        loading={apiLoader}
        pagination={false}
        dataSource={_get(laborData, 'data', [])}
        columns={tableColumns()}
      />
    </EditCatalogCommonTabs>
  );
};
