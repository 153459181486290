import React, { createRef, useEffect, useState } from 'react';
import {
  Button,
  Checkbox,
  Col,
  Dropdown,
  Form,
  Input,
  InputNumber,
  Row,
  Select,
  Space,
  Table,
  Tooltip,
  Typography,
  Avatar,
} from 'antd';
import {
  CaretLeftOutlined,
  CaretRightOutlined,
  CloseOutlined,
  DownOutlined,
  FormOutlined,
  SlidersOutlined,
  SyncOutlined,
} from '@ant-design/icons';
import { _get, _indexOf } from '../../../utils/lodashUtils';
import isNull from 'lodash/isNull';
import {
  MANUFACTURER_CODE_LIST,
  MANUFACTURER_LIST,
  MANUFACTURER_SINGLE_DATA,
} from '../../../constants/api';
import { getAPI, patchAPI, postAPI } from '../../../utils/apiRequest';
import { interpolate } from '../../../utils/common';
import {
  DEFAULT_TABLE_PAGINATION_DROPDOWN_PAGE_COUNT,
  PAGES_CODE as PAGES,
} from '../../../constants/hardData';
import { REQUIRED_FIELD_WITH_NAME_MESSAGE } from '../../../constants/message';
import { useDispatch, useSelector } from 'react-redux';
import {
  globalPagesFilterData,
  globalPagesFilterToggle,
  globalPagesPagination,
} from '../../../redux/actions/globalReducActions';
import { Link } from 'react-router-dom';
import ModalCom from '../../common/Modal';

const { Title } = Typography;

function ManufacturerManagement(props) {
  const [toggleEditManufacturerModal, setToggleEditManufacturerModal] =
    useState(false);
  const [manufacturerData, setManufacturerData] = useState({});
  const [manufacturerDataLoading, toggleManufacturerDataLoading] =
    useState(false);
  const [manufacturerDataActionLoading, toggleManufacturerDataActionLoading] =
    useState(false);
  const [codesCompactList, setCodesCompactList] = useState([]);
  const [isDefaultCodeUseChecked, setDefaultCodeUseChecked] = useState(false);
  const [manualCode, setManualCode] = useState(false);
  const tablePaginationParams = useSelector(
    (state) => _get(state, 'global.globalPagesPagination')[PAGES.MANUFACTURER]
  );
  const toggleFilters = useSelector(
    (state) => _get(state, 'global.globalPagesFilterToggle')[PAGES.MANUFACTURER]
  );
  const activeFilters = useSelector(
    (state) => _get(state, 'global.globalPagesFilterData')[PAGES.MANUFACTURER]
  );
  const dispatch = useDispatch();
  const setTablePaginationParams = (param) => {
    if (param && typeof param == 'function') {
      dispatch(
        globalPagesPagination(PAGES.MANUFACTURER, param(tablePaginationParams))
      );
    } else {
      dispatch(globalPagesPagination(PAGES.MANUFACTURER, param));
    }
  };
  const setToggleFilters = (param) => {
    if (param && typeof param == 'function') {
      dispatch(
        globalPagesFilterToggle(PAGES.MANUFACTURER, param(toggleFilters))
      );
    } else {
      dispatch(globalPagesFilterToggle(PAGES.MANUFACTURER, param));
    }
  };
  const setActiveFilters = (param) => {
    if (param && typeof param == 'function') {
      dispatch(globalPagesFilterData(PAGES.MANUFACTURER, param(activeFilters)));
    } else {
      dispatch(globalPagesFilterData(PAGES.MANUFACTURER, param));
    }
  };
  let formRef = createRef();
  useEffect(() => {
    loadManufacturersData();
  }, [tablePaginationParams]);
  const loadManufacturerCodeList = () => {
    let successFn = function (result) {
      setCodesCompactList(result.data);
    };
    let errorFn = function () {};
    getAPI(MANUFACTURER_CODE_LIST, successFn, errorFn);
  };

  const tableColumns = [
    {
      title: 'Edit',
      key: 'Actions',
      width: 60,
      render: (value, record) => (
        <Space>
          <Tooltip title={'Edit'}>
            <FormOutlined
              onClick={() => {
                loadSingleManufacturersData(record.id);
                loadManufacturerCodeList();
                setDefaultCodeUseChecked(false);
                setManualCode(false);
              }}
            />
          </Tooltip>{' '}
        </Space>
      ),
    },
    {
      title: 'ID',
      key: 'id',
      dataIndex: 'id',
      sorter: true,
      align: 'right',
      width: 100,
      sortDirections: ['descend', 'ascend'],
      sortOrder:
        _get(manufacturerData, 'sortBy') === 'id'
          ? _get(manufacturerData, 'sortDir') === 'DESC'
            ? 'descend'
            : 'ascend'
          : false,
      render: (value) => value || '-',
    },
    {
      title: 'Name',
      key: 'name',
      dataIndex: 'name',
      sorter: true,
      sortOrder:
        _get(manufacturerData, 'sortBy') === 'name'
          ? _get(manufacturerData, 'sortDir') === 'DESC'
            ? 'descend'
            : 'ascend'
          : false,
      width: 350,
      render: (value, record) => (
        <Space>
          {!isNull(_get(record, 'image_url')) ? (
            <Avatar size='large' src={_get(record, 'image_url')} />
          ) : (
            <Avatar style={{ backgroundColor: '#FAA500' }} size='large'>
              {' '}
              {value.charAt(0)}
            </Avatar>
          )}
          {value || '-'}
        </Space>
      ),
    },
    {
      title: 'Code',
      key: 'code',
      dataIndex: 'code',
      sorter: true,
      sortOrder:
        _get(manufacturerData, 'sortBy') === 'code'
          ? _get(manufacturerData, 'sortDir') === 'DESC'
            ? 'descend'
            : 'ascend'
          : false,
      width: 100,
      render: (value) => value || '-',
    },
    {
      title: 'Note',
      key: 'note',
      dataIndex: 'note',
      render: (value) => value || '-',
      ellipsis: true,
    },
  ];
  const loadManufacturersData = () => {
    toggleManufacturerDataLoading(true);
    let params = {
      ...tablePaginationParams,
    };
    let successFn = function (result) {
      setManufacturerData(result);

      toggleManufacturerDataLoading(false);
    };
    let errorFn = function () {
      toggleManufacturerDataLoading(false);
    };
    postAPI(MANUFACTURER_LIST, params, successFn, errorFn);
  };
  const loadSingleManufacturersData = (id) => {
    toggleManufacturerDataLoading(true);

    let successFn = function (result) {
      setToggleEditManufacturerModal(result.data);
      toggleManufacturerDataLoading(false);
    };
    let errorFn = function () {
      toggleManufacturerDataLoading(false);
    };
    getAPI(interpolate(MANUFACTURER_SINGLE_DATA, [id]), successFn, errorFn);
  };
  const updateManufacturer = (data) => {
    let reqData = {
      note: data.note || '',
      name: data.name,
    };
    if (_get(toggleEditManufacturerModal, 'has_code') === true) {
      reqData = {
        ...reqData,
        code: !!_get(toggleEditManufacturerModal, 'code')
          ? undefined
          : data.code,
        is_new_assignment: !!_get(toggleEditManufacturerModal, 'code')
          ? undefined
          : !!isDefaultCodeUseChecked || manualCode,
        description: data.description ? data.description : undefined,
      };
    }
    toggleManufacturerDataActionLoading(true);
    let successFn = function () {
      toggleManufacturerDataActionLoading(false);
      setToggleEditManufacturerModal(false);
      loadManufacturersData();
    };
    let errorFn = function () {
      toggleManufacturerDataActionLoading(false);
    };
    patchAPI(
      interpolate(MANUFACTURER_SINGLE_DATA, [
        _get(toggleEditManufacturerModal, 'id'),
      ]),
      reqData,
      successFn,
      errorFn
    );
  };
  const setTableSortingParams = (field, order) => {
    setTablePaginationParams((prevState) => {
      return {
        ...prevState,
        sortBy: order ? field : undefined,
        sortDir: order ? (order === 'ascend' ? 'ASC' : 'DESC') : undefined,
      };
    });
  };
  const setDefaultCodeAsCode = (option, values) => {
    setDefaultCodeUseChecked(!!option);
    formRef.current.setFieldsValue(values);
  };
  const setDropDownItems = () => {
    const dropdownItems = DEFAULT_TABLE_PAGINATION_DROPDOWN_PAGE_COUNT.map(
      (item) => {
        return {
          ...item,
          label: (
            <Button
              type='text'
              style={{ width: '100%' }}
              onClick={() => {
                setTablePaginationParams({
                  ...tablePaginationParams,
                  limit: +item.key,
                });
              }}
            >
              {item.label}
            </Button>
          ),
        };
      }
    );
    return dropdownItems;
  };
  return (
    <>
      <Title className={'page-title'}>Manufacturers</Title>
      <Table
        className='data-table full-height-table'
        size={'small'}
        scroll={{ y: 'calc(100vh - 400px)' }}
        rowKey={'id'}
        pagination={false}
        onChange={(a, b, sorting) =>
          setTableSortingParams(sorting.field, sorting.order)
        }
        dataSource={_get(manufacturerData, 'data', [])}
        columns={tableColumns}
        showSorterTooltip={false}
        loading={manufacturerDataLoading}
        title={() => (
          <Row justify={'end'}>
            <Col>
              <Button
                size={'large'}
                onClick={() => setToggleFilters(!toggleFilters)}
              >
                {toggleFilters ? <CloseOutlined /> : <SlidersOutlined />}
              </Button>
              <Button
                size={'large'}
                type={'secondary'}
                icon={<SyncOutlined />}
                onClick={loadManufacturersData}
              >
                Refresh
              </Button>
            </Col>
            {toggleFilters && (
              <Col span={24}>
                <Form
                  className='filter-form'
                  onFinish={() =>
                    setTablePaginationParams({
                      ...tablePaginationParams,
                      offset: 0,
                      filters: { ...activeFilters },
                    })
                  }
                >
                  <Space className={'mt-10 mb-10 ml-10 mr-10'}>
                    <InputNumber
                      value={activeFilters.id}
                      placeholder='ID'
                      onChange={(value) =>
                        setActiveFilters((prev) => {
                          return { ...prev, id: value ? value : undefined };
                        })
                      }
                    />
                    <Input
                      value={activeFilters.name}
                      placeholder='Name'
                      onChange={(e) =>
                        setActiveFilters((prev) => {
                          return { ...prev, name: e.target.value };
                        })
                      }
                    />
                    <Input
                      value={activeFilters.code}
                      placeholder='Code'
                      onChange={(e) =>
                        setActiveFilters((prev) => {
                          return { ...prev, code: e.target.value };
                        })
                      }
                    />
                    <Button type={'primary'} htmlType={'submit'}>
                      Apply
                    </Button>
                    <Button
                      type={'secondary'}
                      onClick={() => {
                        setActiveFilters({});
                        setTablePaginationParams({
                          ...tablePaginationParams,
                          filters: {},
                        });
                      }}
                    >
                      Clear
                    </Button>
                  </Space>
                </Form>
              </Col>
            )}
          </Row>
        )}
        footer={() => (
          <Row justify={'space-between'}>
            <Col>
              <Space>
                <Dropdown
                  menu={{ items: setDropDownItems() }}
                  trigger={['click']}
                >
                  <Button type='ghost'>
                    Items Per Page:{_get(tablePaginationParams, 'limit')}
                    <DownOutlined className={'ml-5'} />
                  </Button>
                </Dropdown>
                <Typography>
                  {`${_get(manufacturerData, 'offset', 0) + 1} 
                                    to ${
                                      _get(manufacturerData, 'data', [])
                                        .length +
                                      _get(manufacturerData, 'offset', '--')
                                    } 
                                    of ${_get(manufacturerData, 'total', '--')} 
                                    items`}
                </Typography>
              </Space>
            </Col>
            <Col>
              <Button.Group>
                <Button
                  type='ghost'
                  icon={<CaretLeftOutlined />}
                  onClick={() =>
                    setTablePaginationParams({
                      ...tablePaginationParams,
                      offset:
                        _get(manufacturerData, 'offset', 0) -
                        _get(manufacturerData, 'limit', 0),
                    })
                  }
                  disabled={!_get(manufacturerData, 'offset', 0)}
                />
                <Button
                  type='ghost'
                  icon={<CaretRightOutlined />}
                  onClick={() =>
                    setTablePaginationParams({
                      ...tablePaginationParams,
                      offset:
                        _get(manufacturerData, 'offset', 0) +
                        _get(manufacturerData, 'limit', 0),
                    })
                  }
                  disabled={
                    _get(manufacturerData, 'offset', 0) +
                      _get(manufacturerData, 'limit', 0) >=
                    _get(manufacturerData, 'total', 0)
                  }
                />
              </Button.Group>
            </Col>
          </Row>
        )}
      />

      <ModalCom
        open={_get(toggleEditManufacturerModal, 'id')}
        title={'Edit Manufacturer'}
        onCancel={() => setToggleEditManufacturerModal(false)}
        footer={null}
        key={_get(toggleEditManufacturerModal, 'id', '--')}
      >
        <Form
          layout='vertical'
          key={_get(toggleEditManufacturerModal, 'id', '--')}
          initialValues={{
            ...toggleEditManufacturerModal,
            description:
              _get(
                toggleEditManufacturerModal,
                'ManufacturerCode.description'
              ) || _get(toggleEditManufacturerModal, 'name'),
          }}
          ref={formRef}
          onFinish={updateManufacturer}
        >
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item label='ID' name='id'>
                <Input disabled={true} className={'right-aligned'} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label='Name'
                name='name'
                rules={[
                  { required: true, message: REQUIRED_FIELD_WITH_NAME_MESSAGE },
                ]}
              >
                <Input disabled={true} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label='Default Code' name='default_code'>
                <Input disabled={true} />
              </Form.Item>
              {_get(toggleEditManufacturerModal, 'show_default_code') &&
              !_get(toggleEditManufacturerModal, 'code') &&
              !(
                _indexOf(
                  codesCompactList.map((i) => i.code),
                  _get(toggleEditManufacturerModal, 'default_code')
                ) > -1
              ) ? (
                <Checkbox
                  checked={isDefaultCodeUseChecked}
                  onChange={(e) =>
                    setDefaultCodeAsCode(e.target.checked, {
                      code: e.target.checked
                        ? _get(toggleEditManufacturerModal, 'default_code')
                        : '',
                      description: _get(toggleEditManufacturerModal, 'name'),
                    })
                  }
                  hidden={
                    _get(toggleEditManufacturerModal, 'has_code') === false
                  }
                >
                  Use Default Code
                </Checkbox>
              ) : null}
            </Col>
            <Col span={12}>
              {manualCode ? (
                <Form.Item
                  label='Code'
                  name='code'
                  style={{ marginBottom: 0 }}
                  rules={[
                    {
                      required: true,
                      message: REQUIRED_FIELD_WITH_NAME_MESSAGE,
                    },
                    {
                      message: "'Code' must be 3 characters long",
                      min: 3,
                      max: 3,
                    },
                  ]}
                  hidden={
                    _get(toggleEditManufacturerModal, 'has_code') === false
                  }
                >
                  <Input disabled={isDefaultCodeUseChecked} />
                </Form.Item>
              ) : (
                <Form.Item
                  label='Code'
                  name='code'
                  style={{ marginBottom: 0 }}
                  rules={[
                    {
                      required: _get(toggleEditManufacturerModal, 'has_code'),
                      message: REQUIRED_FIELD_WITH_NAME_MESSAGE,
                    },
                  ]}
                  hidden={
                    _get(toggleEditManufacturerModal, 'has_code') === false
                  }
                >
                  <Select
                    onChange={(e, a) =>
                      formRef.current.setFieldsValue({
                        description: a.children[2],
                      })
                    }
                    showSearch
                    optionFilterProp='children'
                    optionLabelProp='label'
                    placeholder='Assign code'
                    dropdownRender={(menu) => <> {menu} </>}
                    disabled={
                      !!_get(toggleEditManufacturerModal, 'code') ||
                      isDefaultCodeUseChecked
                    }
                  >
                    {codesCompactList.map((item) => (
                      <Select.Option label={item.code} key={item.code}>
                        {item.code} - {item.description}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              )}
              {!_get(toggleEditManufacturerModal, 'code') ? (
                <Link
                  onClick={() => setManualCode(!manualCode)}
                  hidden={
                    _get(toggleEditManufacturerModal, 'has_code') === false
                  }
                >
                  {manualCode ? 'Select Code' : 'Add New Code'}
                </Link>
              ) : null}
            </Col>
            <Col span={24}>
              <Form.Item
                label='Description'
                name='description'
                rules={[
                  { required: true, message: REQUIRED_FIELD_WITH_NAME_MESSAGE },
                ]}
                hidden={_get(toggleEditManufacturerModal, 'has_code') === false}
              >
                <Input.TextArea />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                label='Note'
                name='note'
                rules={[
                  {
                    max: 100,
                    message: 'Note must not contain more than 255 characters',
                  },
                ]}
              >
                <Input.TextArea />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16} justify={'end'}>
            <Col>
              <Form.Item>
                <Button
                  type='primary'
                  htmlType='submit'
                  loading={manufacturerDataActionLoading}
                >
                  {' '}
                  Update{' '}
                </Button>
              </Form.Item>
            </Col>
            <Col>
              <Button
                type='secondary'
                onClick={() => setToggleEditManufacturerModal(false)}
              >
                {' '}
                Cancel
              </Button>
            </Col>
          </Row>
        </Form>
      </ModalCom>
    </>
  );
}

export default ManufacturerManagement;
