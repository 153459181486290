import React, { useEffect, useState, useMemo, useRef } from 'react';
import AppBase from '../../base/AppBase';
import {
  Checkbox,
  ConfigProvider,
  Empty,
  Space,
  Table,
  Tooltip,
  Typography,
} from 'antd';
import { EyeOutlined, FormOutlined } from '@ant-design/icons';
import { _get } from '../../../utils/lodashUtils';
import {
  CATALOG_BULK_CONNECTOR_UPDATE_V2,
  CATALOG_CONNECTOR_UPDATE,
} from '../../../constants/api';
import { putAPI } from '../../../utils/apiRequest';
import {
  DESCRIPTION_PARAGRAPH_ELLIPSIS,
  PAGES_CODE as PAGES,
} from '../../../constants/hardData';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { checkGotoInput, interpolate } from '../../../utils/common';
import { useDispatch, useSelector } from 'react-redux';
import {
  globalPagesFilterData,
  globalPagesFilterToggle,
  globalPagesPagination,
} from '../../../redux/actions/globalReducActions';
import { currencyFormatter } from '../../../constants/hardData';
import get from 'lodash/get';
import { ROLE_CATALOG_READ_ONLY_USER } from '../../../constants/roles';
import { getManufacturerFilterData } from '../../../redux/Slices/manufacturerFilterListSlice';
import { getCategoryFilterData } from '../../../redux/Slices/categoryFilterDataSlice';
import { getSyncConnectorFilterData } from '../../../redux/Slices/syncConnectorFilterData';
import {
  getCatalogsData,
  setSyncConnAllCheckbox,
  updateCatalogsData,
} from '../../../redux/Slices/catalogSlice';
import CatalogTableTitle from './CatalogTableTitle';
import { setMessage } from '../../../redux/Slices/messageSlice';
import { TYPE_ERROR } from '../../../constants/message';
import {
  BAD_CPN_CALLBACK_FUNC_NAMES,
  CATALOG,
} from '../../../constants/constants';
import {
  changeBadCpnModalName,
  changeBadCpnModalState,
  setBadCpnCallbackFuncName,
  setBadCpnCallbackFuncParams,
  setBadCpnDataEditCatalog,
} from '../../../redux/Slices/badCpnBatchesSlice';

const { Title, Paragraph } = Typography;

function CatalogManagement(props) {
  const [catalogDataLoading, toggleCatalogDataLoading] = useState(false);
  // const [syncConnAllCheckbox, setSyncConnAllCheckbox] = useState({});
  const [loadCatalogModal, setLoadCatalogModal] = useState(false);
  const [gotoPage, setGotoPage] = useState('');
  const tblRef = useRef(null);

  // Redux variables
  const manufacturerFilterListData = useSelector(
    (state) => state.manufacturerFilterList.data
  );
  const categoryFilterData = useSelector(
    (state) => state.categoryFilterData.data
  );
  const syncConnectorFilterData = useSelector(
    (state) => state.syncConnectorFilterData.data
  );
  const catalogs = useSelector((state) => state.catalogs.data);
  const syncConnectorsCheckboxData = useSelector(
    (state) => state.catalogs.syncConnectorCheckboxData
  );
  const syncConnAllCheckbox = useSelector(
    (state) => state.catalogs.syncConnAllCheckbox
  );
  const apiLoader = useSelector((state) => state.apiLoader);
  const tablePaginationParams = useSelector(
    (state) => _get(state, 'global.globalPagesPagination')[PAGES.CATALOG]
  );
  const toggleFilters = useSelector(
    (state) => _get(state, 'global.globalPagesFilterToggle')[PAGES.CATALOG]
  );
  const activeFilters = useSelector(
    (state) => _get(state, 'global.globalPagesFilterData')[PAGES.CATALOG]
  );

  const roleCode = useSelector((state) => _get(state, 'auth.Role.role_code'));

  const dispatch = useDispatch();
  const setTablePaginationParams = (param) => {
    if (param && typeof param == 'function') {
      dispatch(
        globalPagesPagination(PAGES.CATALOG, param(tablePaginationParams))
      );
    } else {
      dispatch(globalPagesPagination(PAGES.CATALOG, param));
    }
  };
  const setToggleFilters = (param) => {
    if (param && typeof param == 'function') {
      dispatch(globalPagesFilterToggle(PAGES.CATALOG, param(toggleFilters)));
    } else {
      dispatch(globalPagesFilterToggle(PAGES.CATALOG, param));
    }
  };
  const setActiveFilters = (param) => {
    if (param && typeof param == 'function') {
      dispatch(globalPagesFilterData(PAGES.CATALOG, param(activeFilters)));
    } else {
      dispatch(globalPagesFilterData(PAGES.CATALOG, param));
    }
  };
  useEffect(() => {
    if (tablePaginationParams.loadAllowed) loadCatalogsData();
  }, [tablePaginationParams]);

  useEffect(() => {
    if (catalogDataLoading !== apiLoader.bool) {
      toggleCatalogDataLoading(apiLoader.bool);
    }
  }, [apiLoader]);

  useEffect(() => {
    dispatch(getManufacturerFilterData());
    dispatch(getCategoryFilterData());
    dispatch(getSyncConnectorFilterData());
  }, []);

  const tableColumns = function () {
    return [
      {
        title: 'Edit',
        key: 'Actions',
        width: 60,
        fixed: true,
        render: (value, record) => (
          <Space>
            <Link to={`/catalog/${record.id}`}>
              {roleCode === ROLE_CATALOG_READ_ONLY_USER ? (
                <Tooltip title={'View'} overlayClassName='tooltip'>
                  <EyeOutlined style={{ color: 'initial' }} />
                </Tooltip>
              ) : (
                <Tooltip title={'Edit'} overlayClassName='tooltip'>
                  <FormOutlined style={{ color: 'initial' }} />
                </Tooltip>
              )}
            </Link>
          </Space>
        ),
      },
      {
        title: 'ItemID',
        key: 'id',
        dataIndex: 'id',
        sorter: true,
        fixed: true,
        align: 'right',
        sortOrder:
          _get(catalogs, 'sortBy') === 'id'
            ? _get(catalogs, 'sortDir') === 'DESC'
              ? 'descend'
              : 'ascend'
            : false,
        render: (value) => value || '-',
        width: 100,
        ellipsis: true,
      },
      {
        title: 'SPN',
        key: 'spn',
        dataIndex: 'spn',
        sorter: true,
        sortDirections: ['ascend', 'descend'],
        width: 180,
        sortOrder:
          _get(catalogs, 'sortBy') === 'spn'
            ? _get(catalogs, 'sortDir') === 'DESC'
              ? 'descend'
              : 'ascend'
            : false,
        fixed: true,
        ellipsis: true,
        render: (value) => value || '-',
      },
      {
        title: 'Description',
        key: 'description',
        dataIndex: 'description',
        sorter: true,
        sortOrder:
          _get(catalogs, 'sortBy') === 'description'
            ? _get(catalogs, 'sortDir') === 'DESC'
              ? 'descend'
              : 'ascend'
            : false,
        width: 300,
        render: (value, record) =>
          value ? (
            <Paragraph
              ellipsis={{ ...DESCRIPTION_PARAGRAPH_ELLIPSIS, tooltip: value }}
              style={{ maxWidth: 260 }}
              key={record.id}
            >
              {value}
            </Paragraph>
          ) : (
            '-'
          ),
      },
      {
        title: 'Manufacturer',
        key: 'manufacturer',
        dataIndex: 'manufacturer',
        ellipsis: true,
        width: 200,
        render: (value, record) => _get(record, 'Manufacturer.name', '-'),
      },
      {
        title: 'Cost',
        key: 'cost_standard',
        dataIndex: 'cost_standard',
        width: 100,
        align: 'right',
        // sorter: true,
        sortOrder:
          _get(catalogs, 'sortBy') === 'cost_standard'
            ? _get(catalogs, 'sortDir') === 'DESC'
              ? 'descend'
              : 'ascend'
            : false,
        render: (value) =>
          value != null
            ? currencyFormatter('USD').format(value.toFixed(2))
            : '-',
      },
      {
        title: 'MSRP',
        dataIndex: 'msrp',
        key: 'msrp',
        align: 'right',
        width: 100,
        ellipsis: true,
        // sorter: true,
        sortOrder:
          _get(catalogs, 'sortBy') === 'msrp'
            ? _get(catalogs, 'sortDir') === 'DESC'
              ? 'descend'
              : 'ascend'
            : false,
        render: (value) => currencyFormatter('USD').format(value) || '-',
      },
      ..._get(catalogs, 'SyncConnectors', []).map((item, index) => {
        return {
          title: (
            <Checkbox
              disabled={roleCode === ROLE_CATALOG_READ_ONLY_USER}
              checked={syncConnAllCheckbox[item.id]}
              onChange={(e) =>
                updateBulkSyncConnectors2(
                  item.id,
                  e.target.checked,
                  item.SyncConnectorType.max_spn_length
                )
              }
              key={item.id}
            >
              {item.name}
            </Checkbox>
          ),
          key: item.id,
          width: _get(item, 'name', ' ').length * 8 + 60,
          render: (value, record, rowIndex) => {
            return (
              <Checkbox
                disabled={roleCode === ROLE_CATALOG_READ_ONLY_USER}
                checked={syncConnectorsCheckboxData[`${record.id}-${item.id}`]}
                key={`${record.id}-${item.id}`}
                onChange={(e) =>
                  updateSyncConnector(
                    record.id,
                    item.id,
                    e.target.checked,
                    item.SyncConnectorType.max_spn_length,
                    rowIndex
                  )
                }
              />
            );
          },
        };
      }),
      {
        title: 'Labor',
        dataIndex: 'total_labor_hours',
        key: 'total_labor_hours',
        width: 80,
        align: 'right',
        // sorter: true,
        sortOrder:
          _get(catalogs, 'sortBy') === 'total_labor_hours'
            ? _get(catalogs, 'sortDir') === 'DESC'
              ? 'descend'
              : 'ascend'
            : false,
        render: (value, record) =>
          _get(record, 'total_labor_hours', null) !== null ? value : '-',
      },
      {
        title: 'Category',
        key: 'ProductCategory',
        dataIndex: 'ProductCategory',
        width: 200,
        render: (value, record) => _get(record, 'ProductCategory.name', '-'),
      },
      {
        title: 'RMR',
        dataIndex: 'rmr',
        key: 'rmr',
        width: 80,
        align: 'right',
        render: (value) => value || '-',
      },
      {
        title: 'RMR Frequency',
        dataIndex: 'rmr_frequency',
        key: 'rmr_frequency',
        width: 130,
        render: (value) => value || '-',
      },
      {
        title: 'Date Active',
        key: 'active_date',
        dataIndex: 'active_date',
        width: 120,
        ellipsis: true,
        render: (value) => (value ? moment(value).format('YYYY-MM-DD') : '-'),
      },
      {
        title: 'Date Inactive',
        key: 'inactive_date',
        dataIndex: 'inactive_date',
        width: 110,
        ellipsis: true,
        render: (value) => (value ? moment(value).format('YYYY-MM-DD') : '-'),
      },
    ];
  };

  const loadCatalogsData = () => {
    dispatch(setSyncConnAllCheckbox({}));
    dispatch(getCatalogsData({ ...tablePaginationParams }));
  };

  const updateSyncConnector = (id, syncId, value, spn_length, rowIndex) => {
    toggleCatalogDataLoading(true);
    let reqData = {
      connectors: [
        {
          is_active: !!value,
          item_master_sync_connector_id: syncId,
        },
      ],
    };
    let successFn = function (result) {
      toggleCatalogDataLoading(false);
      if (get(result, 'bad_item_ids').length > 0) {
        dispatch(
          setMessage({
            type: TYPE_ERROR,
            msg: 'The SPN length is too long for this Connector Type, please set a new part number(s) for these items:',
          })
        );
        dispatch(changeBadCpnModalState(true));
        dispatch(changeBadCpnModalName(CATALOG));
        dispatch(setBadCpnDataEditCatalog(result, id));
        dispatch(
          setBadCpnCallbackFuncName(
            BAD_CPN_CALLBACK_FUNC_NAMES.UPDATE_CATALOGS_DATA
          )
        );
        dispatch(
          setBadCpnCallbackFuncParams({
            id,
            catalogs,
            syncConnectorsCheckboxData,
            activeItemSyncConnectorIds: get(
              result,
              'active_item_sync_connector_ids',
              []
            ),
            rowIndex,
            syncId,
          })
        );
      } else {
        dispatch(
          updateCatalogsData(
            id,
            catalogs,
            syncConnectorsCheckboxData,
            get(result, 'active_item_sync_connector_ids', []),
            rowIndex
          )
        );
        dispatch(
          setSyncConnAllCheckbox({ ...syncConnAllCheckbox, [syncId]: false })
        );
      }
    };
    let errrorFn = function () {
      toggleCatalogDataLoading(false);
    };
    putAPI(
      interpolate(CATALOG_CONNECTOR_UPDATE, [id]),
      reqData,
      successFn,
      errrorFn
    );
  };

  const updateBulkSyncConnectors2 = (syncId, value, spn_length) => {
    toggleCatalogDataLoading(true);
    let reqData = {
      item_master_sync_connector_id: syncId,
      is_active: value,
      filters: {
        ...get(tablePaginationParams, 'filters'),
      },
    };
    let successFn = function (result) {
      toggleCatalogDataLoading(false);
      if (value && get(result, 'bad_item_ids')?.length) {
        dispatch(
          setMessage({
            type: TYPE_ERROR,
            msg: 'The SPN length is too long for this Connector Type, please set a new part number(s) for these items:',
          })
        );
        dispatch(changeBadCpnModalState(true));
        dispatch(changeBadCpnModalName(CATALOG));
        dispatch(setBadCpnDataEditCatalog(result, undefined, syncId));
        dispatch(
          setBadCpnCallbackFuncName(
            BAD_CPN_CALLBACK_FUNC_NAMES.GET_CATALOGS_DATA
          )
        );
        dispatch(setBadCpnCallbackFuncParams({ ...tablePaginationParams }));
      } else {
        loadCatalogsData();
        dispatch(
          setSyncConnAllCheckbox({ ...syncConnAllCheckbox, [syncId]: !!value })
        );
      }
    };
    let errorFn = function (error) {
      toggleCatalogDataLoading(false);
    };

    putAPI(CATALOG_BULK_CONNECTOR_UPDATE_V2, reqData, successFn, errorFn);
  };

  const setTableSortingParams = (
    field,
    order,
    current,
    offset,
    paginationObj
  ) => {
    setTablePaginationParams((prevState) => {
      return {
        ...prevState,
        loadAllowed: true,
        filters: { ...activeFilters },
        current,
        offset,
        limit: paginationObj.pageSize,
        sortBy: order ? field : undefined,
        sortDir: order ? (order === 'ascend' ? 'ASC' : 'DESC') : undefined,
      };
    });
  };

  const renderTable = useMemo(
    () => (
      <Table
        className='data-table full-height-table'
        virtual={catalogs.data && catalogs.data.length ? true : false}
        ref={tblRef}
        scroll={{
          x: 2500,
          y:
            catalogs.data && catalogs.data.length
              ? window.innerHeight - 410
              : window.innerHeight,
        }}
        pagination={{
          position: ['bottomRight'],
          hideOnSinglePage: false,
          showSizeChanger: true,
          pageSize: _get(catalogs, 'limit', 0),
          total: _get(catalogs, 'total', 0),
          current: _get(tablePaginationParams, 'current', 1),
          showQuickJumper: {
            goButton: (
              <input
                type='number'
                aria-label='Page'
                value={gotoPage}
                onChange={(e) => {
                  setGotoPage(e.target.value);
                }}
                style={{ width: '90px' }}
                onKeyDown={(e) => {
                  if (e.key === '.' || e.key === 'e') {
                    e.preventDefault();
                  }
                  if (e.key === 'Enter') {
                    if (
                      checkGotoInput(
                        gotoPage,
                        _get(catalogs, 'total'),
                        _get(catalogs, 'limit', 0)
                      )
                    ) {
                      setTableSortingParams(
                        _get(tablePaginationParams, 'sortBy', undefined),
                        'ascend',
                        gotoPage,
                        (gotoPage - 1) * _get(catalogs, 'limit'),
                        { pageSize: _get(catalogs, 'limit', 0) }
                      );
                    } else {
                      dispatch(
                        setMessage({
                          type: TYPE_ERROR,
                          msg: 'Please enter the correct value',
                        })
                      );
                    }
                  }
                }}
              />
            ),
          },
          locale: {
            jump_to: 'Go to ',
            jump_to_confirm: '',
          },
          pageSizeOptions: [25, 50, 100, 500],
        }}
        onChange={(paginationObj, b, sorting) => {
          setTableSortingParams(
            sorting.field,
            sorting.order,
            _get(paginationObj, 'current'),
            (_get(paginationObj, 'current') - 1) * _get(catalogs, 'limit'),
            paginationObj
          );
        }}
        rowKey='id'
        showSorterTooltip={false}
        dataSource={catalogs.data ? catalogs.data : []}
        columns={tableColumns()}
        loading={catalogDataLoading}
        rowClassName={(record) => !record.is_active && 'table-inactive-row'}
      />
    ),
    [catalogs, catalogDataLoading, syncConnectorsCheckboxData, gotoPage]
  );

  return (
    <AppBase>
      <>
        <Title className={'page-title'}>Catalog Management</Title>
        <Title className={'page-title'} level={3}>
          Item Master
        </Title>
        <ConfigProvider
          renderEmpty={
            !tablePaginationParams.loadAllowed
              ? customizeRenderEmpty
              : undefined
          }
        >
          <CatalogTableTitle
            toggleFilters={toggleFilters}
            setToggleFilters={setToggleFilters}
            roleCode={roleCode}
            tablePaginationParams={tablePaginationParams}
            loadCatalogsData={loadCatalogsData}
            setTablePaginationParams={setTablePaginationParams}
            activeFilters={activeFilters}
            setActiveFilters={setActiveFilters}
            manufacturerFilterListData={manufacturerFilterListData}
            categoryFilterData={categoryFilterData}
            syncConnectorFilterData={syncConnectorFilterData}
            loadCatalogModal={loadCatalogModal}
            setLoadCatalogModal={setLoadCatalogModal}
          />
          {renderTable}
        </ConfigProvider>
      </>
    </AppBase>
  );
}

export default CatalogManagement;

const customizeRenderEmpty = () => (
  <Empty description={'Apply Filters or Click on Load to Fetch Records'} />
);
