import { configureStore } from '@reduxjs/toolkit';
import authReducer from './reducers/authReducer';
import globalReducer from './reducers/globalReducer';
import { releaseNotesReducer } from './reducers/releaseNotes/releaseNotesReducer';
import manufacturerFilterListReducer from './Slices/manufacturerFilterListSlice';
import categoryFilterDataReducer from './Slices/categoryFilterDataSlice';
import syncConnectorFilterDataReducer from './Slices/syncConnectorFilterData';
import catalogReducer from './Slices/catalogSlice';
import apiLoaderReducer from './Slices/apiLoaderSlice';
import messageReducer from './Slices/messageSlice';
import duplicateBatchReducer from './Slices/duplicateBatchSlice';
import badCpnBatchesReducer from './Slices/badCpnBatchesSlice';
import systemSyncConnectorIntegrationReducer from './Slices/systemSyncConnectorIntegrationSlice';
import catalogConnectorReducer from './Slices/catalogConnectorSlice';
import productCatLaborCatMappingReducer from './Slices/productCatLaborCatSlice';
import editCatalogTabReducer from './Slices/editCatalogTabSlice';
import productCatSyncConnMapReducer from './Slices/productCatSyncConnMapSlice';

export const Store = configureStore({
  reducer: {
    auth: authReducer,
    global: globalReducer,
    releaseNotesReducer: releaseNotesReducer,
    manufacturerFilterList: manufacturerFilterListReducer,
    categoryFilterData: categoryFilterDataReducer,
    syncConnectorFilterData: syncConnectorFilterDataReducer,
    catalogs: catalogReducer,
    apiLoader: apiLoaderReducer,
    message: messageReducer,
    duplicateBatchReducer: duplicateBatchReducer,
    badCpnBatchesReducer: badCpnBatchesReducer,
    systemSyncConnectorIntegrationReducer:
      systemSyncConnectorIntegrationReducer,
    catalogConnectorReducer: catalogConnectorReducer,
    productCatLaborCatMappingReducer: productCatLaborCatMappingReducer,
    editCatalogTabReducer: editCatalogTabReducer,
    productCatSyncConnMapReducer: productCatSyncConnMapReducer,
  },
});
