/** *
 * App Default Config
 * */

const CONFIG = {
  prodDomain: [],
  crashHandling: {
    slack: {
      sendOnProduction: true,
      sendOnDevelopment: false,
      webHookUrl:
        '',
    },
  },
};

export default CONFIG;
