import React, { useState } from 'react';
import ModalCom from '../../common/Modal';
import {
  Button,
  Checkbox,
  Col,
  Divider,
  Form,
  Input,
  Modal,
  Radio,
  Row,
  Select,
  Table,
  Tabs,
  TreeSelect,
  Typography,
} from 'antd';
import { patchAPI, postAPI } from '../../../utils/apiRequest';
import {
  INTGERATION_CATEGORY_CREATE,
  INTGERATION_CATEGORY_MAPPING,
  PRODUCT_CATEGORY_LABOR_CATEGORY_MAP,
  PRODUCT_CATEGORY_SINGLE_DATA,
} from '../../../constants/api';
import { interpolate } from '../../../utils/common';
import {
  REQUIRED_FIELD_MESSAGE,
  REQUIRED_FIELD_WITH_NAME_MESSAGE,
  TYPE_SUCCESS,
} from '../../../constants/message';
import { _get } from '../../../utils/lodashUtils';
import { useDispatch, useSelector } from 'react-redux';
import { setProductCatLaborCatMappingData } from '../../../redux/Slices/productCatLaborCatSlice';
import { changeApiLoader } from '../../../redux/Slices/apiLoaderSlice';
import { PlusOutlined } from '@ant-design/icons';
import { SyncConnectorIntegrationQuickbooksAddModal } from '../system/SyncConnectorIntegrationQuickbooksAddModal';
import { CATEGORY } from '../../../constants/constants';
import {
  getProductCatSyncConnQuickbooksData,
  setProductCatSyncConnMapData,
} from '../../../redux/Slices/productCatSyncConnMapSlice';
import { setMessage } from '../../../redux/Slices/messageSlice';

export const EditProductCategoryModal = (props) => {
  const {
    toggleEditCategoryModal,
    setToggleEditCategoryModal,
    loadProductCategories,
    parentCategoryAvailable,
    setParentCategoryAvailable,
    generalData,
  } = props;

  // const [categoryLaborHoursUpdateFlag, setCategoryLaborHoursUpdateFlag] =
  //   useState(false);
  const [addNewModal, setAddNewModal] = useState(false);
  const [selectedSyncConnId, setSelectedSyncConnId] = useState(-1);

  const productCatLaborCatMappingData = useSelector(
    (state) => state.productCatLaborCatMappingReducer.data
  );
  const copiedProductCatLaborCatMappingData = useSelector(
    (state) => state.productCatLaborCatMappingReducer.copiedData
  );
  const productCatSyncConnMapData = useSelector(
    (state) => state.productCatSyncConnMapReducer.data
  );
  const quickBooksDropdownData = useSelector(
    (state) => state.productCatSyncConnMapReducer.quickbooksdata
  );
  const defaultItems = useSelector(
    (state) => state.productCatSyncConnMapReducer.productCatDefaultItemData
  );

  const apiLoader = useSelector((state) => state.apiLoader.bool);

  const dispatch = useDispatch();

  const mapProductCatLaborCat = (
    id,
    shouldIncludeLaborHourChangeFlag,
    categoryLaborHoursUpdateFlag
  ) => {
    const arr = productCatLaborCatMappingData
      .filter((item) => !!item.default_labor_hours)
      .map((item) => {
        return {
          labor_category_id: item.id,
          default_labor_hours: parseInt(item.default_labor_hours),
        };
      });
    if (arr.length === 0) {
      dispatch(changeApiLoader(false));
      setToggleEditCategoryModal(false);
      loadProductCategories();
      return;
    }
    let successFn = function (results) {
      dispatch(changeApiLoader(false));
      setToggleEditCategoryModal(false);
      loadProductCategories();
      // setCategoryLaborHoursUpdateFlag(false);
    };
    let errorFn = function (error) {
      dispatch(changeApiLoader(false));
    };
    let data = shouldIncludeLaborHourChangeFlag
      ? {
          data: arr,
          category_labor_hours_update_flag: categoryLaborHoursUpdateFlag,
        }
      : { data: arr };
    patchAPI(
      interpolate(PRODUCT_CATEGORY_LABOR_CATEGORY_MAP, [id]),
      data,
      successFn,
      errorFn
    );
  };

  const updateCategory = (
    id,
    data,
    shouldIncludeLaborHourChangeFlag,
    categoryLaborHoursUpdateFlag
  ) => {
    dispatch(changeApiLoader(true));
    let successFn = function () {
      mapProductCatLaborCat(
        id,
        shouldIncludeLaborHourChangeFlag,
        categoryLaborHoursUpdateFlag
      );
    };
    let errorFn = function () {
      dispatch(changeApiLoader(false));
    };
    patchAPI(
      interpolate(PRODUCT_CATEGORY_SINGLE_DATA, [id]),
      {
        ...data,
        parent_category_id: data.make_parent ? null : data.parent_category_id,
        make_parent: undefined,
      },
      successFn,
      errorFn
    );
  };

  const validateLaborHoursChanges = () => {
    let check = false;
    productCatLaborCatMappingData.forEach((item, index) => {
      if (
        item.default_labor_hours !== undefined &&
        item.default_labor_hours !==
          copiedProductCatLaborCatMappingData[index].default_labor_hours
      ) {
        check = true;
      }
    });
    return check;
  };

  const submitForm = (id, data) => {
    if (validateLaborHoursChanges()) {
      Modal.confirm({
        content: (
          <>
            <Typography.Paragraph>
              Do you wish to update the labor hours on all parts assigned to
              this category
            </Typography.Paragraph>
            <Form
              onFinish={(values) => {
                updateCategory(
                  id,
                  data,
                  true,
                  values.categoryLaborHoursUpdateFlag
                );
                Modal.destroyAll();
              }}
              key={'categoryLaborHoursUpdateFlagModal'}
            >
              <Form.Item
                name={'categoryLaborHoursUpdateFlag'}
                rules={[{ required: true, message: 'Please select an option' }]}
              >
                <Radio.Group>
                  <Radio value={true}>
                    Yes: update all parts with labor hours from category labor
                    hours default
                  </Radio>
                  <Radio value={false}>
                    No: do not update labor hours on existing parts
                  </Radio>
                </Radio.Group>
              </Form.Item>
              <Form.Item>
                <Button type='primary' htmlType='submit'>
                  Submit
                </Button>
              </Form.Item>
            </Form>
          </>
        ),
        footer: null,
      });
    } else {
      updateCategory(id, data);
    }
  };

  const updateMapping = (value, label, index, record, extra) => {
    dispatch(changeApiLoader(true));
    let successFn = (result) => {
      dispatch(changeApiLoader(false));
      let arr = [...productCatSyncConnMapData];
      arr[index] = {
        ...arr[index],
        QuickBooksCategory: {
          category_code: value,
          category_name: label[0],
        },
      };
      dispatch(setProductCatSyncConnMapData(arr));
      dispatch(
        setMessage({
          type: TYPE_SUCCESS,
          msg: `Category updated successfully!`,
        })
      );
    };
    let errorFn = (error) => {
      console.log('updateMapping error', error);
      dispatch(changeApiLoader(false));
    };
    const payload = {
      category_id: _get(toggleEditCategoryModal, 'id'),
      category_code: value,
    };
    postAPI(
      interpolate(INTGERATION_CATEGORY_MAPPING, [record.id]),
      payload,
      successFn,
      errorFn
    );
  };

  const changeDefaultLaborHours = (value, index) => {
    let tempArr = [...productCatLaborCatMappingData];
    tempArr[index] = {
      ...tempArr[index],
      default_labor_hours: value,
    };
    dispatch(setProductCatLaborCatMappingData(tempArr));
  };

  const mapTableColumns = () => {
    const columns = [
      {
        title: 'Labor Category',
        key: 'name',
        dataIndex: 'name',
        render: (value) => (value ? value : '--'),
      },
      {
        title: 'Default Labor Hours',
        key: 'default_labor_hours',
        dataIndex: 'default_labor_hours',
        render: (val, record, index) => (
          <Input
            type='number'
            name='default_labor_hours'
            value={val}
            onKeyDown={(evt) => {
              if (evt.key === 'e' || evt.key === '.') {
                evt.preventDefault();
              }
            }}
            onChange={(e) => changeDefaultLaborHours(e.target.value, index)}
          />
        ),
      },
    ];
    return columns;
  };

  const mapIntegrationTableColumns = () => {
    const columns = [
      {
        title: 'Sync Connector',
        key: 'sync_connector_id',
        dataIndex: 'name',
        render: (value) => (value ? value : '--'),
      },
      {
        title: 'QuickBooks Category',
        dataIndex: 'QuickBooksCategory',
        render: (value, record, index) => {
          return (
            <TreeSelect
              value={value ? value['category_code'] : null}
              style={{
                width: 300,
              }}
              onChange={(val, label, extra) =>
                updateMapping(val, label, index, record, extra)
              }
              dropdownRender={(menu) => (
                <>
                  {menu} <Divider style={{ margin: '8px 0' }} key={'add_btn'} />
                  <Button
                    type='text'
                    style={{ width: '100%' }}
                    icon={<PlusOutlined />}
                    onClick={() => {
                      setAddNewModal(true);
                      setSelectedSyncConnId(record.id);
                    }}
                  >
                    Add item
                  </Button>
                </>
              )}
              treeData={quickBooksDropdownData[record.id]}
              fieldNames={{
                label: 'category_name',
                value: 'category_code',
                children: 'items',
              }}
            />
          );
        },
      },
    ];

    return columns;
  };

  const laborHours = () => {
    return productCatLaborCatMappingData.length ? (
      <Table
        className='data-table full-height-table'
        size={'small'}
        rowKey={'id'}
        dataSource={productCatLaborCatMappingData}
        columns={mapTableColumns()}
        pagination={false}
        loading={apiLoader}
      />
    ) : (
      ''
    );
  };

  const integration = () => {
    return productCatSyncConnMapData.length ? (
      <Table
        className='data-table full-height-table'
        size={'small'}
        rowKey={'id'}
        dataSource={productCatSyncConnMapData}
        columns={mapIntegrationTableColumns()}
        pagination={false}
        loading={apiLoader}
      />
    ) : (
      ''
    );
  };

  const tabItems = () => {
    const items = [
      {
        key: 'labor_hours',
        label: 'Map Labor Category',
        children: laborHours(),
      },
      {
        key: 'integration',
        label: 'Integration',
        children: integration(),
      },
    ];
    return items;
  };

  const addNewQuickbooksData = (values) => {
    dispatch(changeApiLoader(true));
    let successFn = (result) => {
      dispatch(changeApiLoader(false));
      dispatch(getProductCatSyncConnQuickbooksData(selectedSyncConnId));
      setAddNewModal(false);
    };
    let errorFn = (error) => {
      dispatch(changeApiLoader(false));
    };

    const payload = {
      category_code: values.code,
      category_name: values.name,
    };

    postAPI(
      interpolate(INTGERATION_CATEGORY_CREATE, [selectedSyncConnId]),
      payload,
      successFn,
      errorFn
    );
  };

  return (
    <ModalCom
      open={toggleEditCategoryModal}
      title={'Edit Category'}
      onCancel={() => setToggleEditCategoryModal(false)}
      footer={null}
    >
      <Form
        onFinish={(values) =>
          submitForm(_get(toggleEditCategoryModal, 'id'), values)
        }
        layout='vertical'
        key={_get(toggleEditCategoryModal, 'id')}
        initialValues={{ ...toggleEditCategoryModal }}
      >
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              label='Category Name'
              name='name'
              rules={[
                { required: true, message: REQUIRED_FIELD_WITH_NAME_MESSAGE },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label='Make Parent Category'
              name='make_parent'
              valuePropName={'checked'}
              initialValue={!parentCategoryAvailable}
            >
              <Checkbox
                onChange={(e) => setParentCategoryAvailable(!e.target.checked)}
                disabled={_get(toggleEditCategoryModal, 'items')?.length > 0}
              />
            </Form.Item>
          </Col>
          {/*<Col span={24}> {_get(toggleEditCategoryModal,'items')?.length>0 ? <Alert type="info" message={"This category has sub categories. Remove them to be able to assign a parent category."}/> :null}*/}
          {/*</Col>*/}
          {parentCategoryAvailable ? (
            <Col span={12}>
              <Form.Item
                label='Parent Category'
                name='parent_category_id'
                rules={[
                  {
                    required: true,
                    message: REQUIRED_FIELD_WITH_NAME_MESSAGE,
                  },
                ]}
              >
                <Select>
                  {generalData?.map((item) => (
                    <Select.Option value={item.id} key={item.id}>
                      {item.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          ) : null}
          <Col span={12}>
            <Form.Item
              label='Default Item Type'
              name='item_type_id'
              rules={[
                {
                  required: true,
                  message: REQUIRED_FIELD_MESSAGE,
                },
              ]}
            >
              <Select>
                {defaultItems.map((item) => {
                  return (
                    <Select.Option value={item['id']} key={item['id']}>
                      {item['name']}
                    </Select.Option>
                  );
                })}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Tabs type='card' destroyInactiveTabPane={true} items={tabItems()} />
        <Row gutter={16} justify={'end'}>
          <Col>
            <Form.Item>
              <Button type='primary' htmlType='submit' loading={false}>
                {' '}
                Update{' '}
              </Button>
            </Form.Item>
          </Col>
          <Col>
            <Button
              type='secondary'
              onClick={() => setToggleEditCategoryModal(false)}
            >
              {' '}
              Cancel
            </Button>
          </Col>
        </Row>
      </Form>
      <SyncConnectorIntegrationQuickbooksAddModal
        title={`Add ${CATEGORY}`}
        open={addNewModal}
        onCancel={() => setAddNewModal(false)}
        onSubmit={addNewQuickbooksData}
      />
    </ModalCom>
  );
};
