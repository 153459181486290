import axios from 'axios';
import { displayMessage, handleErrorResponse, makeURL } from '../common';
import { get, rm } from 'lockr';
import { AUTH_TOKEN, SUCCESS_MSG_TYPE } from '../../constants/dataKeys';

export const getAuthToken = function () {
  const token = get(AUTH_TOKEN);
  return token;
};
export const clearStorage = function () {
  rm(AUTH_TOKEN);
};
export const putAPI = function (
  URL,
  data,
  successFn,
  errorFn,
  headerConfig = {}
) {
  let token = getAuthToken();
  let authHeaders = {};
  if (token) {
    authHeaders.Authorization = `${token}`;
  }
  axios({
    method: 'put',
    url: makeURL(URL),
    data: data,
    headers: {
      ...authHeaders,
      ...headerConfig,
    },
  })
    .then(function (response) {
      let data = response.data;
      successFn(data);
      if (data.message) displayMessage(SUCCESS_MSG_TYPE, data.message);
    })
    .catch(function (error) {
      handleErrorResponse(error);
      errorFn(error.response.data);
    });
};
export const patchAPI = function (
  URL,
  data,
  successFn,
  errorFn,
  headerConfig = {}
) {
  let token = getAuthToken();
  let authHeaders = {};
  if (token) {
    authHeaders.Authorization = `${token}`;
  }
  axios({
    method: 'patch',
    url: makeURL(URL),
    data: data,
    headers: {
      ...authHeaders,
      ...headerConfig,
    },
  })
    .then(function (response) {
      let data = response.data;
      successFn(data);
      if (data.message) displayMessage(SUCCESS_MSG_TYPE, data.message);
    })
    .catch(function (error) {
      handleErrorResponse(error);
      errorFn(error.response.data);
    });
};
export const postAPI = function (
  URL,
  data,
  successFn,
  errorFn,
  headerConfig = {}
) {
  let token = getAuthToken();
  let authHeaders = {};
  if (token) {
    authHeaders.Authorization = `${token}`;
  }
  axios({
    method: 'post',
    url: makeURL(URL),
    data: data,
    headers: {
      ...authHeaders,
      ...headerConfig,
    },
  })
    .then(function (response) {
      let data = response.data;
      successFn(data);
      if (data.message) displayMessage(SUCCESS_MSG_TYPE, data.message);
    })
    .catch(function (error) {
      handleErrorResponse(error);
      errorFn(error.response.data, error);
    });
};
export const postOuterAPI = function (
  URL,
  data,
  successFn,
  errorFn,
  headerConfig = {}
) {
  axios({
    method: 'post',
    url: URL,
    data: data,
    headers: {
      ...headerConfig,
    },
  })
    .then(function (response) {
      let data = response.data;
      successFn(data);
      if (data.message) displayMessage(SUCCESS_MSG_TYPE, data.message);
    })
    .catch(function (error) {
      handleErrorResponse(error);
      errorFn(error?.response?.data);
    });
};
export const getOuterAPI = function (URL, successFn, errorFn, params = {}) {
  axios({
    method: 'get',
    url: URL,
    params: params,
  })
    .then(function (response) {
      let data = response.data;
      successFn(data);
      if (data.message) displayMessage(SUCCESS_MSG_TYPE, data.message);
    })
    .catch(function (error) {
      handleErrorResponse(error);
      errorFn(error.response.data);
    });
};
export const getAPI = function (URL, successFn, errorFn, params = {}) {
  let token = getAuthToken();
  let authHeaders = {};
  if (token) {
    authHeaders.Authorization = `${token}`;
  }
  axios({
    method: 'get',
    url: makeURL(URL),
    headers: {
      ...authHeaders,
    },
    params: params,
  })
    .then(function (response) {
      let data = response.data;
      successFn(data);
      if (data.message) displayMessage(SUCCESS_MSG_TYPE, data.message);
    })
    .catch(function (error) {
      handleErrorResponse(error);
      errorFn();
    });
};

export const deleteAPI = function (URL, successFn, errorFn) {
  let token = getAuthToken();
  let authHeaders = {};
  if (token) {
    authHeaders.Authorization = `${token}`;
  }
  axios({
    method: 'delete',
    url: makeURL(URL),
    headers: {
      ...authHeaders,
    },
  })
    .then(function (response) {
      let data = response.data;
      successFn(data);
      if (data.message) displayMessage(SUCCESS_MSG_TYPE, data.message);
    })
    .catch(function (error) {
      handleErrorResponse(error);
      errorFn(error.response.data, error);
    });
};

export const deleteMultipleAPI = function (URL, data, successFn, errorFn) {
  let token = getAuthToken();
  let authHeaders = {};
  if (token) {
    authHeaders.Authorization = `${token}`;
  }
  axios({
    method: 'delete',
    url: makeURL(URL),
    data: data,
    headers: {
      ...authHeaders,
    },
  })
    .then(function (response) {
      let data = response.data;
      successFn(data);
      if (data.message) displayMessage(SUCCESS_MSG_TYPE, data.message);
    })
    .catch(function (error) {
      handleErrorResponse(error);
      errorFn(error.response.data, error);
    });
};

export const convertRouteSearchToObj = function (searchString) {
  if (searchString) {
    let returnObj = {};
    let searchStringAfterTrim = searchString.substr(1);
    let searchStringGroups = searchStringAfterTrim.split('&');
    searchStringGroups.forEach(function (pairString) {
      let valuePair = pairString.split('=');
      returnObj[valuePair[0]] = valuePair[1];
    });
    return returnObj;
  }
  return {};
};

export const postWithOutTokenAPI = function (
  URL,
  data,
  successFn,
  errorFn,
  headerConfig = {}
) {
  axios({
    method: 'post',
    url: makeURL(URL),
    data: data,
    headers: {
      ...headerConfig,
    },
  })
    .then(function (response) {
      let data = response.data;
      successFn(data);
    })
    .catch(function (error) {
      handleErrorResponse(error);
      errorFn(error.response.data);
    });
};

export const patchOuterApi = function (
  URL,
  token,
  successFn,
  errorFn,
  headerConfig = {}
) {
  axios({
    method: 'patch',
    url: makeURL(URL),
    headers: {
      Authorization: `Bearer ${token}`,
      ...headerConfig,
    },
  })
    .then(function (response) {
      successFn();
    })
    .catch(function (error) {
      handleErrorResponse(error);
      errorFn(error.response.data);
    });
};
