import React, { createRef, useEffect, useState } from 'react';
import {
  Button,
  Card,
  Col,
  Dropdown,
  Form,
  Input,
  Menu,
  Row,
  Select,
  Space,
  Switch,
  Table,
  Tooltip,
  TreeSelect,
  Typography,
  Avatar,
} from 'antd';
import {
  CaretLeftOutlined,
  CaretRightOutlined,
  CloseOutlined,
  DownOutlined,
  FormOutlined,
  SlidersOutlined,
  SyncOutlined,
} from '@ant-design/icons';
import { _get } from '../../../utils/lodashUtils';
import isNull from 'lodash/isNull';
import {
  COMPACT_DISTRIBUTOR_CATEGORY_LIST,
  COMPACT_DISTRIBUTOR_LIST,
  COMPACT_DISTRIBUTOR_SUB_CATEGORY_LIST,
  COMPACT_PRODUCT_CATEGORY_LIST,
  DISTRIBUTOR_CATEGORY_LIST,
  DISTRIBUTOR_CATEGORY_SINGLE_DATA,
  PRODUCT_CATEGORY_LIST,
} from '../../../constants/api';
import { getAPI, patchAPI, postAPI } from '../../../utils/apiRequest';
import { interpolate } from '../../../utils/common';
import {
  ACTIVE_INACTIVE_FILTER_LIST,
  DEFAULT_TABLE_PAGINATION_DROPDOWN_PAGE_COUNT,
  PAGES_CODE as PAGES,
} from '../../../constants/hardData';
import { REQUIRED_FIELD_WITH_NAME_MESSAGE } from '../../../constants/message';
import { useDispatch, useSelector } from 'react-redux';
import {
  globalPagesFilterData,
  globalPagesFilterToggle,
  globalPagesPagination,
} from '../../../redux/actions/globalReducActions';
import ModalCom from '../../common/Modal';

const { Title } = Typography;

function DistributorManagement(props) {
  const distEditForm = createRef();
  const [toggleEditDistributorModal, setToggleEditDistributorModal] =
    useState(false);
  const [distributorData, setDistributorData] = useState({});
  const [distributorDataLoading, toggleDistributorDataLoading] =
    useState(false);
  const [distributorDataActionLoading, toggleDistributorDataActionLoading] =
    useState(false);
  const [generalData, setCategoriesData] = useState([]);
  const [generalDataLoading, toggleCategoriesDataLoading] = useState(false);
  const [distributorList, setDistributorList] = useState([]);
  const [categoryList, setCategoryList] = useState([]);
  const [distributorCatgList, setDistributorCatgList] = useState([]);
  // const [distributorSubCatgList, setDistributorSubCatgList] = useState([]);
  const tablePaginationParams = useSelector(
    (state) =>
      _get(state, 'global.globalPagesPagination')[PAGES.CATAGORIES_MAPPING]
  );
  const toggleFilters = useSelector(
    (state) =>
      _get(state, 'global.globalPagesFilterToggle')[PAGES.CATAGORIES_MAPPING]
  );
  const activeFilters = useSelector(
    (state) =>
      _get(state, 'global.globalPagesFilterData')[PAGES.CATAGORIES_MAPPING]
  );
  const dispatch = useDispatch();

  const setTablePaginationParams = (param) => {
    if (param && typeof param == 'function') {
      dispatch(
        globalPagesPagination(
          PAGES.CATAGORIES_MAPPING,
          param(tablePaginationParams)
        )
      );
    } else {
      dispatch(globalPagesPagination(PAGES.CATAGORIES_MAPPING, param));
    }
  };

  const setToggleFilters = (param) => {
    if (param && typeof param == 'function') {
      dispatch(
        globalPagesFilterToggle(PAGES.CATAGORIES_MAPPING, param(toggleFilters))
      );
    } else {
      dispatch(globalPagesFilterToggle(PAGES.CATAGORIES_MAPPING, param));
    }
  };

  const setActiveFilters = (param) => {
    if (param && typeof param == 'function') {
      dispatch(
        globalPagesFilterData(PAGES.CATAGORIES_MAPPING, param(activeFilters))
      );
    } else {
      dispatch(globalPagesFilterData(PAGES.CATAGORIES_MAPPING, param));
    }
  };

  useEffect(() => {
    loadDistributorsData();
  }, [tablePaginationParams]);

  useEffect(() => {
    loadProductCategories();
    fetchDistributorList();
    fetchCategoryList();
    // fetchDistributorCategoryList();
    fetchTempDistributorCatgList();
  }, []);

  const fetchDistributorList = () => {
    const successFn = function (result) {
      if (_get(result, 'success')) {
        setDistributorList(_get(result, 'data'));
      }
    };

    const errorFn = function (error) {
      console.log(error);
    };

    getAPI(COMPACT_DISTRIBUTOR_LIST, successFn, errorFn);
  };
  const fetchTempDistributorCatgList = () => {
    const successFn = function (result) {
      if (_get(result, 'success')) {
        setDistributorCatgList(_get(result, 'data'));
      }
    };
    const errorFn = function (error) {
      console.log(error);
    };

    getAPI('compact/distributor-category/list/v2', successFn, errorFn);
  };

  // const fetchDistributorCategoryList = () => {
  //   const successFn = function (result) {
  //     console.log("result", result);
  //     if(_get(result, 'success')){
  //       const temp = _get(result, 'data').map((item) => {
  //         return {
  //           id: item?.category_code,
  //           pId: 0,
  //           value: item?.category_code,
  //           title: item?.category_name,
  //         }
  //       });
  //       setDistributorCatgList(temp);
  //       // setDistributorCatgList(_get(result, 'data'));
  //     }
  //   };
  //   const errorFn = function(error) {
  //     console.log(error);
  //   };

  //   getAPI(COMPACT_DISTRIBUTOR_CATEGORY_LIST, successFn, errorFn);
  // };

  // const onLoadDistributorSubCategory = (option) => {
  //   const tempData = [];
  //   const successFn = function(result) {
  //     console.log("result sub", result);
  //     if(_get(result, 'success')){
  //       // setDistributorSubCatgList(_get(result, 'data'));
  //       tempData.concat(_get(result, 'data'));
  //     }
  //   }
  //   const errorFn = function(error){
  //     console.log(error)
  //   };

  //   postAPI(
  //     COMPACT_DISTRIBUTOR_SUB_CATEGORY_LIST,
  //     { category_code: option.value },
  //     successFn,
  //     errorFn,
  //   );

  //   return new Promise((resolve) => {
  //     setTimeout(() => {
  //       setDistributorCatgList([
  //         ...distributorCatgList,
  //         ...tempData,
  //       ], 2000)
  //     })
  //     resolve(undefined);
  //   })
  // };

  // console.log("distr", distributorCatgList);

  const fetchCategoryList = () => {
    const successFn = function (result) {
      if (_get(result, 'success')) {
        setCategoryList(_get(result, 'data'));
      }
    };

    const errorFn = function (error) {
      console.log(error);
    };

    getAPI(COMPACT_PRODUCT_CATEGORY_LIST, successFn, errorFn);
  };

  const loadProductCategories = () => {
    let params = {};
    toggleCategoriesDataLoading(true);
    let successFn = function (result) {
      setCategoriesData(result.data);
      toggleCategoriesDataLoading(false);
    };
    let errorFn = function () {
      toggleCategoriesDataLoading(false);
    };
    postAPI(PRODUCT_CATEGORY_LIST, params, successFn, errorFn);
  };

  const tableColumns = [
    {
      title: 'Edit',
      key: 'Actions',
      width: 80,
      render: (value, record) => (
        <Space>
          <Tooltip title={'Edit'}>
            <FormOutlined
              onClick={() => setToggleEditDistributorModal(record)}
            />{' '}
          </Tooltip>
        </Space>
      ),
    },
    {
      title: 'Distributor',
      key: 'Distributor',
      dataIndex: 'Distributor.name',
      render: (value, record) => (
        <Space>
          {!isNull(_get(record, 'Distributor.image_url')) ? (
            <Avatar size='large' src={_get(record, 'Distributor.image_url')} />
          ) : (
            <Avatar style={{ backgroundColor: '#FAA500' }} size='large'>
              {' '}
              {_get(record, 'Distributor.name').charAt(0)}
            </Avatar>
          )}
          {_get(record, 'Distributor.name') || '-'}
        </Space>
      ),
    },
    {
      title: 'Distributor Category Code',
      key: 'category_code',
      dataIndex: 'category_code',
      sorter: true,
      width: 210,
      sortOrder:
        _get(distributorData, 'sortBy') === 'category_code'
          ? _get(distributorData, 'sortDir') === 'DESC'
            ? 'descend'
            : 'ascend'
          : false,
      render: (value) => value || '-',
    },
    {
      title: 'Distributor Category Name',
      key: 'category_name',
      dataIndex: 'category_name',
      sorter: true,
      sortOrder:
        _get(distributorData, 'sortBy') === 'category_name'
          ? _get(distributorData, 'sortDir') === 'DESC'
            ? 'descend'
            : 'ascend'
          : false,
      render: (value) => value || '-',
    },
    {
      title: 'Distributor Sub Category Code',
      key: 'subcategory_code',
      dataIndex: 'subcategory_code',
      sorter: true,
      width: 240,
      sortOrder:
        _get(distributorData, 'sortBy') === 'subcategory_code'
          ? _get(distributorData, 'sortDir') === 'DESC'
            ? 'descend'
            : 'ascend'
          : false,
      render: (value) => value || '-',
    },
    {
      title: 'Distributor Sub Category Name',
      key: 'subcategory_name',
      dataIndex: 'subcategory_name',
      sorter: true,
      sortOrder:
        _get(distributorData, 'sortBy') === 'subcategory_name'
          ? _get(distributorData, 'sortDir') === 'DESC'
            ? 'descend'
            : 'ascend'
          : false,
      render: (value) => value || '-',
    },
    {
      title: 'Category ',
      key: 'product_category_name',
      dataIndex: 'product_category_name',
      render: (value) => value || '-',
    },
    {
      title: 'Sub Category ',
      key: 'product_subcategory_name',
      dataIndex: 'product_subcategory_name',
      render: (value) => value || '-',
    },
    {
      title: 'Active',
      key: 'active',
      dataIndex: 'is_active',
      width: 80,
      render: (value, record) => (
        <Switch
          checked={value}
          disabled={distributorDataActionLoading || !record.category_id}
          onClick={() =>
            updateDistributor(record.id, {
              is_active: !value,
              category_id: record.category_id,
            })
          }
        />
      ),
    },
  ];
  const loadDistributorsData = () => {
    toggleDistributorDataLoading(true);
    let params = {
      ...tablePaginationParams,
    };
    let successFn = function (result) {
      setDistributorData(result);
      toggleDistributorDataLoading(false);
    };
    let errorFn = function () {
      toggleDistributorDataLoading(false);
    };
    postAPI(DISTRIBUTOR_CATEGORY_LIST, params, successFn, errorFn);
  };
  const updateDistributor = (id, data) => {
    let reqData = {
      category_id: data.category_id,
      is_active: data.is_active || false,
    };
    toggleDistributorDataActionLoading(true);
    let successFn = function () {
      toggleDistributorDataActionLoading(false);
      setToggleEditDistributorModal(false);
      loadDistributorsData();
    };
    let errorFn = function () {
      toggleDistributorDataActionLoading(false);
    };
    patchAPI(
      interpolate(DISTRIBUTOR_CATEGORY_SINGLE_DATA, [id]),
      reqData,
      successFn,
      errorFn
    );
  };
  const setTableSortingParams = (field, order) => {
    setTablePaginationParams((prevState) => {
      return {
        ...prevState,
        sortBy: order ? field : undefined,
        sortDir: order ? (order === 'ascend' ? 'ASC' : 'DESC') : undefined,
      };
    });
  };

  const setDropDownItems = () => {
    const dropdownItems = DEFAULT_TABLE_PAGINATION_DROPDOWN_PAGE_COUNT.map(
      (item) => {
        return {
          ...item,
          label: (
            <Button
              type='text'
              style={{ width: '100%' }}
              onClick={() => {
                setTablePaginationParams({
                  ...tablePaginationParams,
                  limit: +item.key,
                });
              }}
            >
              {item.label}
            </Button>
          ),
        };
      }
    );
    return dropdownItems;
  };

  const renderTreeNodes = (nodes) =>
    nodes.map((item) => {
      if (item.items) {
        return (
          <TreeSelect.TreeNode title={item.name} value={item.id} dataRef={item}>
            {renderTreeNodes(item.items)}
          </TreeSelect.TreeNode>
        );
      }
      return (
        <TreeSelect.TreeNode value={item.id} title={item.name} {...item} />
      );
    });

  const renderTempTreeNodes = (nodes) =>
    nodes.map((item) => {
      if (item.child) {
        return (
          <TreeSelect.TreeNode
            title={item.category_name}
            value={item.category_code}
            dataRef={item}
          >
            {renderTempTreeNodes(item.child)}
          </TreeSelect.TreeNode>
        );
      }
      return (
        <TreeSelect.TreeNode
          value={item.subcategory_code}
          title={item.subcategory_name}
          {...item}
        />
      );
    });
  return (
    <Card>
      <>
        <Title className={'page-title'} level={2}>
          Product Map
        </Title>
        <Table
          className='data-table full-height-table'
          size={'small'}
          scroll={{ x: 2200, y: window.innerHeight - 450 }}
          pagination={false}
          onChange={(a, b, sorting) =>
            setTableSortingParams(sorting.field, sorting.order)
          }
          rowKey={'id'}
          dataSource={_get(distributorData, 'data', [])}
          columns={tableColumns}
          showSorterTooltip={false}
          loading={distributorDataLoading}
          title={() => (
            <Row justify={'end'}>
              <Col>
                <Button
                  size={'large'}
                  onClick={() => setToggleFilters(!toggleFilters)}
                >
                  {toggleFilters ? <CloseOutlined /> : <SlidersOutlined />}
                </Button>
                <Button
                  size={'large'}
                  type={'secondary'}
                  icon={<SyncOutlined />}
                  onClick={loadDistributorsData}
                >
                  Refresh
                </Button>
              </Col>
              {toggleFilters && (
                <Col span={24}>
                  <Form
                    className='filter-form'
                    onFinish={() =>
                      setTablePaginationParams({
                        ...tablePaginationParams,
                        offset: 0,
                        filters: { ...activeFilters },
                      })
                    }
                  >
                    <Space className={'mt-10 mb-10 ml-10 mr-10'}>
                      {/* <Input value={activeFilters.category_code} placeholder='Distributor Category Code'
                          onChange={(e) => setActiveFilters(prev => {
                            return { ...prev, category_code: e.target.value };
                          })} />
                   <Input value={activeFilters.category_name} placeholder='Distributor Category Name'
                          onChange={(e) => setActiveFilters(prev => {
                            return { ...prev, category_name: e.target.value };
                          })} />
                   <Input value={activeFilters.subcategory_code} placeholder='Distributor Sub Category Code'
                          onChange={(e) => setActiveFilters(prev => {
                            return { ...prev, subcategory_code: e.target.value };
                          })} />
                   <Input value={activeFilters.subcategory_name} placeholder='Distributor Sub Category Name'
                          onChange={(e) => setActiveFilters(prev => {
                            return { ...prev, subcategory_name: e.target.value };
                          })} /> */}
                      <Select
                        placeholder='Distributor'
                        allowClear
                        showSearch
                        style={{ width: 200 }}
                        value={activeFilters?.distributor_id}
                        optionFilterProp='children'
                        onChange={(value) =>
                          setActiveFilters((prev) => {
                            return { ...prev, distributor_id: value };
                          })
                        }
                      >
                        {distributorList?.map((distributor) => (
                          <Select.Option
                            key={distributor?.id}
                            value={distributor?.id}
                          >
                            {distributor?.name}
                          </Select.Option>
                        ))}
                      </Select>
                      <TreeSelect
                        placeholder={'Category'}
                        allowClear
                        value={
                          activeFilters.category_id
                            ? activeFilters.category_id.toString()
                            : null
                        }
                        style={{ width: 200 }}
                        onChange={(value) =>
                          setActiveFilters((prev) => {
                            return {
                              ...prev,
                              category_id: value ? value : undefined,
                            };
                          })
                        }
                      >
                        {renderTreeNodes(categoryList)}
                      </TreeSelect>

                      <TreeSelect
                        placeholder={'Distributor Category'}
                        allowClear
                        value={
                          activeFilters.common_category_code
                            ? activeFilters.common_category_code.toString()
                            : null
                        }
                        style={{ width: 200 }}
                        onChange={(value, abc, xyz, mnc) => {
                          // console.log("value", value, abc, xyz, mnc);
                          setActiveFilters((prev) => {
                            return {
                              ...prev,
                              common_category_code: value ? value : undefined,
                            };
                          });
                        }}
                      >
                        {renderTempTreeNodes(distributorCatgList)}
                      </TreeSelect>

                      {/* <TreeSelect
                    placeholder="Distributor Category"
                    allowClear
                    style={{ width: 200 }}
                    onChange={(value) => {
                      setActiveFilters((prev) => {
                        return { ...prev, value };
                      })
                    }}
                    // loadData={onLoadDistributorSubCategory}
                    // treeData={distributorCatgList}
                  >
                  </TreeSelect> */}

                      <Select
                        placeholder={'Status'}
                        showSearch
                        value={activeFilters.is_active}
                        optionFilterProp='children'
                        allowClear
                        style={{ width: 200 }}
                        onChange={(value) =>
                          setActiveFilters((prev) => {
                            return { ...prev, is_active: value };
                          })
                        }
                      >
                        {ACTIVE_INACTIVE_FILTER_LIST.map((item) => (
                          <Select.Option value={item.value} key={item.label}>
                            {item.label}
                          </Select.Option>
                        ))}
                      </Select>
                      <Button type={'primary'} htmlType={'submit'}>
                        Apply
                      </Button>
                      <Button
                        type={'secondary'}
                        onClick={() => {
                          setActiveFilters({});
                          setTablePaginationParams({
                            ...tablePaginationParams,
                            filters: {},
                          });
                        }}
                      >
                        Clear
                      </Button>
                    </Space>
                  </Form>
                </Col>
              )}
            </Row>
          )}
          footer={() => (
            <Row justify={'space-between'}>
              <Col>
                <Space>
                  <Dropdown
                    menu={{ items: setDropDownItems() }}
                    trigger={['click']}
                  >
                    <Button type='ghost'>
                      Items Per Page:{_get(tablePaginationParams, 'limit')}
                      <DownOutlined className={'ml-5'} />
                    </Button>
                  </Dropdown>
                  <Typography>{`${_get(distributorData, 'offset', 0) + 1} to ${
                    _get(distributorData, 'data', []).length +
                    _get(distributorData, 'offset', '--')
                  } of ${_get(
                    distributorData,
                    'total',
                    '--'
                  )} items`}</Typography>
                </Space>
              </Col>
              <Col>
                <Button.Group>
                  <Button
                    type='ghost'
                    icon={<CaretLeftOutlined />}
                    onClick={() =>
                      setTablePaginationParams({
                        ...tablePaginationParams,
                        offset:
                          _get(distributorData, 'offset', 0) -
                          _get(distributorData, 'limit', 0),
                      })
                    }
                    disabled={!_get(distributorData, 'offset', 0)}
                  />
                  <Button
                    type='ghost'
                    icon={<CaretRightOutlined />}
                    onClick={() =>
                      setTablePaginationParams({
                        ...tablePaginationParams,
                        offset:
                          _get(distributorData, 'offset', 0) +
                          _get(distributorData, 'limit', 0),
                      })
                    }
                    disabled={
                      _get(distributorData, 'offset', 0) +
                        _get(distributorData, 'limit', 0) >=
                      _get(distributorData, 'total', 0)
                    }
                  />
                </Button.Group>
              </Col>
            </Row>
          )}
        />
        <ModalCom
          open={toggleEditDistributorModal}
          title={'Edit Category'}
          key={_get(toggleEditDistributorModal, 'id')}
          onCancel={() => setToggleEditDistributorModal(false)}
          footer={null}
        >
          <Form
            layout='vertical'
            key={_get(toggleEditDistributorModal, 'id')}
            initialValues={{ ...toggleEditDistributorModal }}
            ref={distEditForm}
            onFinish={(values) =>
              updateDistributor(_get(toggleEditDistributorModal, 'id'), values)
            }
          >
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item
                  label='Distributor Category Code'
                  name='category_code'
                >
                  <Input disabled={true} />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label='Distributor Category Name'
                  name='category_name'
                >
                  <Input disabled={true} />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label='Distributor Sub Category Code'
                  name='subcategory_code'
                >
                  <Input disabled={true} />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label='Distributor Sub Category Name'
                  name='subcategory_name'
                >
                  <Input disabled={true} />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label='Category'
                  name='category_id'
                  rules={[
                    {
                      required: true,
                      message: REQUIRED_FIELD_WITH_NAME_MESSAGE,
                    },
                  ]}
                >
                  <TreeSelect
                    onChange={() =>
                      _get(toggleEditDistributorModal, 'category_id')
                        ? null
                        : distEditForm.current.setFieldsValue({
                            is_active: true,
                          })
                    }
                    // titleRender={(item) =>item.name}
                    // onExpand={(a,b)=>console.log(a,b)}
                  >
                    {renderTreeNodes(generalData)}
                  </TreeSelect>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label='Active'
                  name='is_active'
                  valuePropName='checked'
                >
                  <Switch />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16} justify={'end'}>
              <Col>
                <Form.Item>
                  <Button
                    type='primary'
                    htmlType='submit'
                    loading={distributorDataActionLoading}
                  >
                    {' '}
                    Update{' '}
                  </Button>
                </Form.Item>
              </Col>
              <Col>
                <Button
                  type='secondary'
                  onClick={() => setToggleEditDistributorModal(false)}
                >
                  {' '}
                  Cancel
                </Button>
              </Col>
            </Row>
          </Form>
        </ModalCom>
      </>
    </Card>
  );
}

export default DistributorManagement;
