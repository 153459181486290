export const agreement = () => {
    return(
        <div
        style={{
            display: "block",
            unicodeBidi: "embed",
            fontFamily: "monospace",
            whiteSpace: "pre",
          }}  
        >
            PLEASE READ THIS END USER LICENSE AGREEMENT ("EULA")<br />
            CAREFULLY BEFORE USING THE BLACKLEAF CONNECT SOFTWARE (THE "SOFTWARE")<br />
            PROVIDED BY BLACKLEAF SOLUTIONS LLC ("BLACKLEAF" OR "WE").<br />
            BY USING THE SOFTWARE AND THE SERVICES PROVIDED THEREWITH (IF ANY),<br />
            YOU AGREE TO BE BOUND BY THE TERMS AND CONDITIONS OF THIS EULA.<br />
            IF YOU DO NOT AGREE WITH THE TERMS AND CONDITIONS OF THIS EULA,<br />
            DO NOT USE OR ACCESS THE SOFTWARE.<br />
            <br />
            1. License Grant<br />
            Subject to the terms of this EULA, Blackleaf hereby<br />
            grants you a limited, non-exclusive, non-transferable,<br />
            non-sublicensable, revocable license to access and use the<br />
            Software solely for your internal business purposes.<br />
            <br />
            2. Restrictions<br />
            <br />
            You agree not to, and shall not permit any third party to:<br />
            <br />
            a. sublicense, sell, rent, lease, transfer, distribute or<br />
            otherwise commercially exploit the Software or<br />
            make the Software available to any third party;<br />
            b. modify, adapt, translate, or create derivative works based on the Software;<br />
            c. reverse engineer, decompile, disassemble or otherwise<br />
            attempt to derive the source code or underlying structure,<br />
            ideas or algorithms of the Software;<br />
            d. use the Software in violation of any applicable laws or regulations; or<br />
            e. remove, alter or obscure any proprietary notices or<br />
            labels on the Software, including but not limited to<br />
            copyright, trademark, and patent notices.<br />
            <br />
            3. Ownership<br />
            <br />
            Blackleaf and its licensors own all right, title, and interest<br />
            in and to the Software, including all intellectual property rights therein.<br />
            Except for the limited license granted to you in this EULA,<br />
            Blackleaf reserves all rights not expressly granted to you.<br />
            <br />
            4. Updates and Support<br />
            <br />
            Blackleaf may, in its sole discretion, provide updates, bug fixes,<br />
            or support for the Software. However, Blackleaf is not obligated to<br />
            provide any updates, bug fixes, or support to you. Any updates or<br />
            support provided by Blackleaf shall be subject to the terms of this EULA.<br />
            <br />
            5. Data and Privacy<br />
            <br />
            You are solely responsible for the accuracy, quality, integrity, legality,<br />
            reliability, and appropriateness of all data that you provide or otherwise<br />
            process using the Software. You must comply with all applicable data protection<br />
            and privacy laws and regulations in connection with your use of the Software.<br />
            Blackleaf's collection, use, and disclosure of any personal information<br />
            provided by you in connection with your use of the Software are subject<br />
            to Blackleaf's privacy policy, which can be found at <a href="https://BlackleafSolutions.com/Privacy" target="_blank">https://BlackleafSolutions.com/Privacy</a>.<br />
            <br />
            6. Warranty Disclaimer<br />
            <br />
            THE SOFTWARE IS PROVIDED "AS IS" AND WITHOUT WARRANTY OF ANY KIND, WHETHER EXPRESS,<br />
            IMPLIED, STATUTORY, OR OTHERWISE. BLACKLEAF DISCLAIMS ALL WARRANTIES, INCLUDING,<br />
            BUT NOT LIMITED TO, WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE,<br />
            NON-INFRINGEMENT, AND ANY WARRANTIES ARISING FROM A COURSE OF DEALING, USAGE,<br />
            OR TRADE PRACTICE.<br />
            <br />
            7. Limitation of Liability<br />
            <br />
            IN NO EVENT SHALL BLACKLEAF BE LIABLE FOR ANY INDIRECT, INCIDENTAL, CONSEQUENTIAL,<br />
            SPECIAL, EXEMPLARY, OR PUNITIVE DAMAGES, INCLUDING, BUT NOT LIMITED TO,<br />
            LOST PROFITS, LOST DATA, OR BUSINESS INTERRUPTION, ARISING OUT OF OR IN<br />
            CONNECTION WITH YOUR USE OR INABILITY TO USE THE SOFTWARE,<br />
            EVEN IF BLACKLEAF HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.<br />
            IN ANY EVENT, BLACKLEAF'S TOTAL LIABILITY TO YOU FOR ALL DAMAGES, LOSSES,<br />
            AND CAUSES OF ACTION SHALL NOT EXCEED THE AMOUNT PAID BY YOU,<br />
            IF ANY, FOR THE SOFTWARE.<br />
            <br />
            8. Termination<br />
            <br />
            This EULA is effective until terminated. Blackleaf may terminate this<br />
            EULA immediately and without notice if you fail to comply with any<br />
            term or condition of this EULA. Upon termination, you shall cease all<br />
            use of the Software and delete any copies or installations of the Software<br />
            in your possession or control. The provisions of Sections 3, 5, 6, 7, 8, and 9<br />
            shall survive any termination of this EULA.<br />
            <br />
            9. Governing Law and Dispute Resolution<br />
            <br />
            This EULA shall be governed by and construed in accordance with the laws of<br />
            the State of Maryland, without regard to its conflict of law principles.<br />
            Any disputes arising out of or in connection with this EULA shall be subject<br />
            to the exclusive jurisdiction of the state and federal courts located<br />
            in Anne Arundel County, Maryland.<br />
            <br />
            10. Export Compliance<br />
            <br />
            You agree to comply with all applicable export and re-export control<br />
            laws and regulations, including the Export Administration Regulations<br />
            and the International Traffic in Arms Regulations. You will not use the<br />
            Software for any purposes prohibited by United States law, including,<br />
            without limitation, the development, design, manufacture, or production<br />
            of nuclear, missile, or chemical or biological weapons.<br />
            <br />
            11. General<br />
            <br />
            This EULA, and any Terms of Service Agreement which you may have executed,<br />
            constitutes the entire agreement between you and Blackleaf concerning the<br />
            Software and supersedes all prior or contemporaneous communications,<br />
            agreements, and understandings, whether oral or written, with respect to<br />
            the subject matter of this EULA. Unless otherwise stated, if there are any<br />
            conflicts between this EULA and the Terms of Service Agreement, the term of<br />
            this EULA shall supersede any conflicting terms set forth in the Terms of Service.<br />
            If any provision of this EULA is held to be invalid or unenforceable by a court<br />
            of competent jurisdiction, the remaining provisions shall remain in full force<br />
            and effect. No waiver of any term or condition of this EULA shall be deemed a<br />
            further or continuing waiver of such term or condition or any other term or condition.<br />
            Blackleaf may assign its rights and obligations under<br />
            this EULA to any party without notice or consent.<br />
        </div>
    );
}