import { Card, Typography } from 'antd';
import React from 'react';

const { Title } = Typography;

export const EditCatalogCommonTabs = (props) => {
  const { title, children } = props;

  return (
    <Card>
      <Title level={4}>{title}</Title>
      {children}
    </Card>
  );
};
