import { App, Modal } from 'antd';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { TYPE_MODAL_CONFIRM } from '../../constants/message';
import { isEmpty } from 'lodash';

const ModalCom = (props) => {
  const { children, title, ...restProps } = props;
  // const modalReducer = useSelector((state) => state.modal.data);
  // const { modal } = App.useApp();

  // const confirm = (propsData) => {
  //   modal.confirm(propsData);
  // };

  // useEffect(() => {
  //   console.log('useEffect called', modalReducer, isEmpty(modalReducer));
  //   if (modalReducer.type === TYPE_MODAL_CONFIRM) {
  //     confirm(modalReducer.propsData);
  //   }
  // }, [modalReducer.type]);

  return (
    <Modal title={title} destroyOnClose={true} {...restProps}>
      {children}
    </Modal>
  );
};

export default ModalCom;
