import {
  Button,
  Checkbox,
  Col,
  Collapse,
  Divider,
  Drawer,
  Dropdown,
  Form,
  Input,
  Menu,
  Row,
  Select,
  Space,
  Spin,
  Table,
  Typography,
  Tooltip,
} from 'antd';
import React, { createRef, useEffect, useState } from 'react';
import { displayMessage, interpolate } from '../../../utils/common';
import {
  BATCH_SINGLE_DATA_IDS_LIST,
  BATCH_SINGLE_DATA_LIST,
  FETCH_MISSING_MANUFACTURER_CODE_BATCH,
  MANUFACTURER_CODE_LIST,
  UPDATE_MANUFACTURER_CODE_WITH_VOID,
} from '../../../constants/api';
import { getAPI, postAPI, putAPI } from '../../../utils/apiRequest';
import {
  CaretLeftOutlined,
  CaretRightOutlined,
  CloseOutlined,
  DownOutlined,
  PlusOutlined,
} from '@ant-design/icons';
import { _get, _indexOf } from '../../../utils/lodashUtils';
import {
  REQUIRED_FIELD_WITH_NAME_MESSAGE,
  TYPE_ERROR,
} from '../../../constants/message';
import {
  DEFAULT_TABLE_PAGINATION_DROPDOWN_PAGE_COUNT,
  DESCRIPTION_PARAGRAPH_ELLIPSIS,
  currencyFormatter,
} from '../../../constants/hardData';
import getSymbolFromCurrency from 'currency-symbol-map';
import { SUCCESS_MSG_TYPE } from '../../../constants/dataKeys';
import AppBase from '../../base/AppBase';
import ModalCom from '../../common/Modal';
import { isEmpty } from 'lodash';
import { useDispatch } from 'react-redux';
import { setMessage } from '../../../redux/Slices/messageSlice';

const { Paragraph, Title } = Typography;

function ManufacturerCodeDrawer(props) {
  const [drawerVisible, setDrawerVisible] = useState(
    props.defaultOpen || false
  );
  const [codeListDataLoading, setCodeListDataLoading] = useState(false);
  const [codeListDataActionLoading, setCodeListDataActionLoading] =
    useState(false);
  const [codesCompactList, setCodesCompactList] = useState([]);
  const [missingCodeList, setMissingCodeList] = useState([]);
  const [dataToSave, setDataToSave] = useState({});
  const [addNewCodeModal, toggleAddNewCodeModal] = useState(null);
  const [batchDataActionLoading, toggleBatchDataActionLoading] =
    useState(false);
  const [batchData, setBatchData] = useState({});
  const [batchDataIds, setBatchDataIds] = useState({});
  const [tablePaginationParams, setTablePaginationParams] = useState({
    limit: 10,
  });
  const [collapseActiveKey, setCollapseActiveKey] = useState(null);
  const [isDefaultCodeUseChecked, setDefaultCodeUseChecked] = useState(false);
  const [voidChecksManufacturer, setVoidChecksManufacturer] = useState({});
  const [voidChecksItem, setVoidChecksItem] = useState({});
  const [selectAll, setSelectAll] = useState(false);
  const dispatch = useDispatch();
  let formRef = createRef();
  useEffect(() => {
    loadMissingCodeData();
  }, [props.batchId]);
  const loadMissingCodeData = function () {
    setCodeListDataLoading(true);
    let successFn = function (result) {
      setMissingCodeList(result.data);
      setCodeListDataLoading(false);
      setCollapseActiveKey(null);
      if (result.data.length) {
        setDrawerVisible(true);
        loadManufacturerCodeList();
      } else {
        setDrawerVisible(false);
        if (props.callback) props.callback();
      }
    };
    let errorFn = function () {
      setCodeListDataLoading(false);
    };
    postAPI(
      interpolate(FETCH_MISSING_MANUFACTURER_CODE_BATCH, [props.batchId]),
      { limit: 2 },
      successFn,
      errorFn
    );
  };
  const loadManufacturerCodeList = function () {
    let successFn = function (result) {
      setCodesCompactList(result.data);
    };
    let errorFn = function () {};
    getAPI(MANUFACTURER_CODE_LIST, successFn, errorFn);
  };
  const saveData = function () {
    setCodeListDataActionLoading(true);
    let reqData = { manufacturers: [], void_manufacturers: [], void_item: [] };
    let msgCheck = false;
    Object.keys(dataToSave).forEach(function (key) {
      if (dataToSave[key]) {
        if (!dataToSave[key]['code'] && !dataToSave[key]['no_code']) {
          msgCheck = true;
        }
        if (dataToSave[key]['code']) {
          reqData.manufacturers.push({
            id: +key,
            code: dataToSave[key]['code'],
            has_code: !dataToSave[key]['no_code'],
          });
        } else {
          reqData.manufacturers.push({
            id: +key,
            has_code: !dataToSave[key]['no_code'],
          });
        }
      }
    });
    if (msgCheck) {
      dispatch(
        setMessage({
          type: TYPE_ERROR,
          msg: 'Please either check the no code checkbox or assign code',
        })
      );
      setCodeListDataActionLoading(false);
      return;
    }
    Object.keys(voidChecksManufacturer).forEach(function (key) {
      if (voidChecksManufacturer[key]) reqData.void_manufacturers.push(+key);
    });
    Object.keys(voidChecksItem).forEach(function (item) {
      Object.keys(voidChecksItem[item]).forEach(function (key) {
        if (voidChecksItem[item][key]) reqData.void_item.push(+key);
      });
    });
    let successFn = function () {
      setCodeListDataActionLoading(false);
      setDataToSave({});
      setVoidChecksManufacturer({});
      setVoidChecksItem({});
      loadMissingCodeData();
      loadBatchItemData();
      loadBatchItemIds();
    };
    let errorFn = function () {
      setCodeListDataActionLoading(false);
    };
    putAPI(
      interpolate(UPDATE_MANUFACTURER_CODE_WITH_VOID, [props.batchId]),
      reqData,
      successFn,
      errorFn
    );
  };
  const addNewCode = function (values) {
    setCodeListDataActionLoading(true);
    let reqData = {
      manufacturers: [
        {
          ...values,
          default_code: undefined,
          is_new_assignment: true,
          has_code: true,
        },
      ],
      void_manufacturers: [],
      void_item: [],
    };
    Object.keys(voidChecksManufacturer).forEach(function (key) {
      if (voidChecksManufacturer[key]) reqData.void_manufacturers.push(+key);
    });
    Object.keys(voidChecksItem).forEach(function (item) {
      Object.keys(voidChecksItem[item]).forEach(function (key) {
        if (voidChecksItem[item][key]) reqData.void_item.push(+key);
      });
    });
    let successFn = function () {
      displayMessage(
        SUCCESS_MSG_TYPE,
        `${values.name}  has been assigned code - ${values.code}`
      );
      toggleAddNewCodeModal(null);
      setCodeListDataActionLoading(false);
      setDataToSave((q) => {
        let newDataToSave = { ...q };
        delete newDataToSave[values.id];
        return newDataToSave;
      });
      setVoidChecksManufacturer({});
      setVoidChecksItem({});
      loadMissingCodeData();
      loadBatchItemData();
      loadBatchItemIds();
    };
    let errorFn = function () {
      setCodeListDataActionLoading(false);
    };
    putAPI(
      interpolate(UPDATE_MANUFACTURER_CODE_WITH_VOID, [props.batchId]),
      reqData,
      successFn,
      errorFn
    );
  };
  const loadBatchItemData = () => {
    if (!collapseActiveKey) return;
    setBatchData({});
    toggleBatchDataActionLoading(true);
    let reqData = {
      filters: {
        manufacturer_id: collapseActiveKey,
      },
      ...tablePaginationParams,
    };
    let successFn = function (result) {
      toggleBatchDataActionLoading(false);
      setBatchData(result);
    };
    let errorFn = function () {
      toggleBatchDataActionLoading(false);
    };
    postAPI(
      interpolate(BATCH_SINGLE_DATA_LIST, [props.batchId]),
      reqData,
      successFn,
      errorFn
    );
  };
  const loadBatchItemIds = () => {
    if (!collapseActiveKey) return;
    setBatchData({});
    toggleBatchDataActionLoading(true);
    let reqData = {
      filters: {
        manufacturer_id: collapseActiveKey,
      },
    };
    let successFn = function (result) {
      toggleBatchDataActionLoading(false);
      setBatchDataIds(result);
    };
    let errorFn = function () {
      toggleBatchDataActionLoading(false);
    };
    postAPI(
      interpolate(BATCH_SINGLE_DATA_IDS_LIST, [props.batchId]),
      reqData,
      successFn,
      errorFn
    );
  };
  useEffect(() => {
    loadBatchItemData();
  }, [tablePaginationParams]);
  useEffect(() => {
    loadBatchItemIds();
    setTablePaginationParams({ ...tablePaginationParams, offset: 0 });
  }, [collapseActiveKey]);
  const setDefaultCodeAsCode = (option, values) => {
    setDefaultCodeUseChecked(!!option);
    if (option) formRef.current.setFieldsValue(values);
  };
  const manageVoidItemCheckbox = (value, type = null) => {
    if (type === 'ALL') {
      setVoidChecksManufacturer({
        ...voidChecksManufacturer,
        [collapseActiveKey]: !!value,
      });
      setVoidChecksItem({ ...voidChecksItem, [collapseActiveKey]: {} });
    } else {
      if (voidChecksManufacturer[collapseActiveKey]) {
        let voidedChecked = {};
        _get(batchDataIds, 'data', []).forEach(function (id) {
          voidedChecked[id] = true;
        });
        setVoidChecksItem({
          ...voidChecksItem,
          [collapseActiveKey]: {
            ...voidChecksItem[collapseActiveKey],
            ...voidedChecked,
            [type]: !!value,
          },
        });
        setVoidChecksManufacturer({
          ...voidChecksManufacturer,
          [collapseActiveKey]: false,
        });
      } else {
        setVoidChecksItem({
          ...voidChecksItem,
          [collapseActiveKey]: {
            ...voidChecksItem[collapseActiveKey],
            [type]: !!value,
          },
        });
      }
    }
  };
  let batchTableColumns = [
    {
      title: (
        <Checkbox
          onChange={(e) => manageVoidItemCheckbox(e.target.checked, 'ALL')}
          checked={voidChecksManufacturer[collapseActiveKey]}
        >
          Void
        </Checkbox>
      ),
      key: 'void',
      width: 80,
      fixed: true,
      render: (value, record) => (
        <Checkbox
          checked={
            voidChecksManufacturer[collapseActiveKey] ||
            (voidChecksItem[collapseActiveKey] &&
              voidChecksItem[collapseActiveKey][record.batch_record_no])
          }
          onChange={(e) =>
            manageVoidItemCheckbox(e.target.checked, record.batch_record_no)
          }
        />
      ),
    },
    {
      title: 'Line',
      key: 'sequence_number',
      dataIndex: 'sequence_number',
      width: 120,
      fixed: true,
      align: 'right',
      render: (value) => value || '-',
    },
    {
      title: 'Manufacturer',
      key: 'manufacturer',
      dataIndex: 'Manufacturer',
      ellipsis: true,
      width: 300,
      render: (value, record) => _get(value, 'name', '-') || '-',
    },
    {
      title: (
        <Tooltip placement='top' title='Manufacturer Part Number'>
          MPN
        </Tooltip>
      ),
      key: 'vendor_part_num',
      dataIndex: 'vendor_part_num',
      ellipsis: true,
      width: 180,
      render: (value) => value || '-',
    },
    {
      title: (
        <Tooltip placement='top' title='Distributor Part Number'>
          DPN
        </Tooltip>
      ),
      key: 'item_num',
      dataIndex: 'item_num',
      ellipsis: true,
      width: 180,
      render: (value) => value || '-',
    },
    {
      title: 'Description',
      key: 'description',
      dataIndex: 'description',
      width: 300,
      render: (value, record) =>
        value ? (
          <Paragraph
            ellipsis={{ ...DESCRIPTION_PARAGRAPH_ELLIPSIS, tooltip: value }}
            style={{ maxWidth: 260 }}
            key={record.id}
          >
            {value}
          </Paragraph>
        ) : (
          '-'
        ),
    },
    {
      title: 'MSRP',
      dataIndex: 'msrp',
      key: 'msrp',
      width: 90,
      align: 'right',
      render: (value) =>
        value ? currencyFormatter('USD').format(value.toFixed(2)) : '-',
    },
    {
      title: 'Cost',
      dataIndex: 'price',
      key: 'price',
      align: 'right',
      width: 90,
      render: (value, record) => currencyFormatter('USD').format(value),
    },
    {
      title: 'Category',
      dataIndex: 'category_name',
      key: 'category_name',
      width: 300,
      render: (value, record) =>
        value ? (
          <Paragraph
            ellipsis={{ ...DESCRIPTION_PARAGRAPH_ELLIPSIS, tooltip: value }}
            style={{ maxWidth: 260 }}
            key={record.id}
          >
            {value}
          </Paragraph>
        ) : (
          '-'
        ),
    },
    {
      title: 'Sub Category',
      dataIndex: 'subcategory_name',
      key: 'subcategory_name',
      width: 300,
      render: (value, record) =>
        value ? (
          <Paragraph
            ellipsis={{ ...DESCRIPTION_PARAGRAPH_ELLIPSIS, tooltip: value }}
            style={{ maxWidth: 260 }}
            key={record.id}
          >
            {value}
          </Paragraph>
        ) : (
          '-'
        ),
    },
    {
      title: 'RMR',
      dataIndex: 'rmr',
      key: 'rmr',
      width: 90,
      align: 'right',
      render: (value) => (value ? (+value).toFixed(2) : '-'),
    },
    {
      title: 'RMR Frequency',
      dataIndex: 'rmr_frequency',
      key: 'rmr_frequency',
      width: 140,
      align: 'right',
      render: (value) => value || '-',
    },
  ];

  const setDropDownItems = () => {
    const dropdownItems = DEFAULT_TABLE_PAGINATION_DROPDOWN_PAGE_COUNT.map(
      (item) => {
        return {
          ...item,
          label: (
            <Button
              type='text'
              style={{ width: '100%' }}
              onClick={() => {
                setTablePaginationParams({
                  ...tablePaginationParams,
                  limit: +item.key,
                });
              }}
            >
              {item.label}
            </Button>
          ),
        };
      }
    );
    return dropdownItems;
  };

  useEffect(() => {
    if (!selectAll && !isEmpty(dataToSave)) {
      let c = false;
      missingCodeList.forEach((mc) => {
        if (
          !dataToSave[mc.id] ||
          (!isEmpty(dataToSave[mc.id]) && !dataToSave[mc.id]['no_code'])
        ) {
          c = true;
        }
      });
      if (!c) setSelectAll(true);
    }
  }, [dataToSave]);

  const renderCollapseHeader = (missingCode) => {
    const node = (
      <Row>
        <Col span={12}>
          <Typography.Text>{`Manufacturer: ${missingCode.name} (${missingCode.id}) Total: ${missingCode.missing_manufacturer_count}`}</Typography.Text>
        </Col>
        <Col span={4} onClick={(e) => e.stopPropagation()}>
          <Checkbox
            checked={
              dataToSave[missingCode.id]
                ? dataToSave[missingCode.id]['no_code']
                : false
            }
            onChange={(e) => {
              if (!e.target.checked && selectAll) {
                setSelectAll(e.target.checked);
              }
              setDataToSave((prevState) => {
                return {
                  ...prevState,
                  [missingCode.id]: {
                    ...prevState[missingCode.id],
                    no_code: e.target.checked,
                    code: e.target.checked
                      ? undefined
                      : prevState[missingCode.id]['code'],
                  },
                };
              });
            }}
          >
            No Code
          </Checkbox>
        </Col>
        <Col span={8}>
          <Select
            onClick={(e) => e.stopPropagation()}
            showSearch
            value={
              dataToSave[missingCode.id]
                ? dataToSave[missingCode.id]['code']
                : undefined
            }
            optionFilterProp='children'
            key={missingCode.id}
            style={{ width: '100%' }}
            placeholder='Assign code'
            disabled={
              dataToSave[missingCode.id] &&
              dataToSave[missingCode.id]['no_code']
            }
            onChange={(value) =>
              setDataToSave((prevState) => {
                return {
                  ...prevState,
                  [missingCode.id]: {
                    ...prevState[missingCode.id],
                    code: value,
                  },
                };
              })
            }
            dropdownRender={(menu) => (
              <>
                {menu}
                <Divider style={{ margin: '8px 0' }} />
                <Button
                  type={'link'}
                  size={'small'}
                  icon={<PlusOutlined />}
                  onClick={() => {
                    toggleAddNewCodeModal(missingCode);
                    setDefaultCodeUseChecked(false);
                  }}
                >
                  Add new code
                </Button>
              </>
            )}
          >
            {codesCompactList.map((item) => (
              <Select.Option key={item.code}>
                {item.code} - {item.description}
              </Select.Option>
            ))}
          </Select>
        </Col>
      </Row>
    );
    return node;
  };

  const renderCollapseItems = () => {
    const items = missingCodeList.map((missingCode, index) => ({
      label: renderCollapseHeader(missingCode),
      key: missingCode.id,
      children: (
        <Table
          className={'data-table'}
          columns={batchTableColumns}
          key={missingCode.id}
          size={'small'}
          scroll={{ y: 300, x: 2100 }}
          loading={batchDataActionLoading}
          rowKey={'id'}
          dataSource={_get(batchData, 'data', [])}
          footer={() => (
            <Row justify={'space-between'}>
              <Col>
                <Space>
                  <Dropdown
                    menu={{ items: setDropDownItems() }}
                    trigger={['click']}
                  >
                    <Button type='ghost'>
                      Items Per Page:
                      {_get(tablePaginationParams, 'limit')}
                      <DownOutlined className={'ml-5'} />
                    </Button>
                  </Dropdown>
                  <Typography>{`${_get(batchData, 'offset', 0) + 1} to ${
                    _get(batchData, 'data', []).length +
                    _get(batchData, 'offset', '--')
                  } of ${_get(batchData, 'total', '--')} items`}</Typography>
                </Space>
              </Col>
              <Col>
                <Button.Group>
                  <Button
                    type='ghost'
                    icon={<CaretLeftOutlined />}
                    onClick={() =>
                      setTablePaginationParams({
                        ...tablePaginationParams,
                        offset:
                          _get(batchData, 'offset', 0) -
                          _get(batchData, 'limit', 0),
                      })
                    }
                    disabled={!_get(batchData, 'offset', 0)}
                  />
                  <Button
                    type='ghost'
                    icon={<CaretRightOutlined />}
                    onClick={() =>
                      setTablePaginationParams({
                        ...tablePaginationParams,
                        offset:
                          _get(batchData, 'offset', 0) +
                          _get(batchData, 'limit', 0),
                      })
                    }
                    disabled={
                      _get(batchData, 'offset', 0) +
                        _get(batchData, 'limit', 0) >=
                      _get(batchData, 'total', 0)
                    }
                  />
                </Button.Group>
              </Col>
            </Row>
          )}
          pagination={false}
        />
      ),
    }));
    return items;
  };

  const selectAllNoCode = (checked) => {
    setSelectAll(checked);
    missingCodeList.forEach((missingCode) => {
      setDataToSave((prevState) => {
        return {
          ...prevState,
          [missingCode.id]: {
            ...prevState[missingCode.id],
            no_code: checked,
            code: checked ? undefined : prevState[missingCode.id]['code'],
          },
        };
      });
    });
  };

  return (
    <Drawer
      open={drawerVisible}
      height='100%'
      width='100%'
      closable={false}
      classNames={{
        body: 'manufacturer-code-drawer-body',
      }}
      // onClose={() => setDrawerVisible(false)}
    >
      <AppBase noSecondaryHeader>
        <Title level={2}>
          {`Missing Manufacturer Assignment - ${props.batchId}`}{' '}
          <Button
            className='pull-right mt-2 no-border'
            icon={<CloseOutlined />}
            type={'ghost'}
            onClick={() => setDrawerVisible(false)}
          />
        </Title>
        <Row>
          <Col span={12}></Col>
          <Col span={4}>
            <Checkbox
              checked={selectAll}
              onChange={({ target: { checked } }) => selectAllNoCode(checked)}
              style={{ marginLeft: '12px', marginBottom: '12px' }}
            >
              Select All
            </Checkbox>
          </Col>
        </Row>
        <Spin spinning={codeListDataLoading} style={{ minHeight: '80vh' }}>
          <Row justify={'space-around'}>
            <Col span={24}>
              <Collapse
                accordion
                items={renderCollapseItems()}
                onChange={(value) => setCollapseActiveKey(value)}
                activeKey={collapseActiveKey}
                className={'mb-5'}
              />
            </Col>
            <Col>
              <Space>
                <Button
                  type={'primary'}
                  disabled={
                    !Object.keys(dataToSave).length &&
                    !Object.keys(voidChecksManufacturer).length &&
                    !Object.keys(voidChecksItem).length
                  }
                  onClick={saveData}
                  loading={codeListDataActionLoading}
                >
                  Save
                </Button>
                <Button onClick={() => setDrawerVisible(false)}>Cancel</Button>
              </Space>
            </Col>
          </Row>
        </Spin>
        <ModalCom
          open={addNewCodeModal}
          key={_get(addNewCodeModal, 'id')}
          footer={null}
          title={'Assign New Code to Manufacturer'}
          onCancel={() => toggleAddNewCodeModal(false)}
        >
          <Form
            layout='vertical'
            key={_get(addNewCodeModal, 'id', '--')}
            ref={formRef}
            initialValues={{
              ...addNewCodeModal,
              description: _get(addNewCodeModal, 'name'),
            }}
            onFinish={addNewCode}
          >
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item label='ID' name='id'>
                  <Input disabled={true} />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label='Name' name='name'>
                  <Input disabled={true} />
                </Form.Item>
              </Col>
              {_get(addNewCodeModal, 'show_default_code') &&
              !(
                _indexOf(
                  codesCompactList.map((i) => i.code),
                  _get(addNewCodeModal, 'show_default_code')
                ) > -1
              ) ? (
                <Col span={12}>
                  <Form.Item
                    label='Default Code'
                    name='default_code'
                    hidden={_get(addNewCodeModal, 'has_code') === false}
                  >
                    <Input disabled={true} />
                  </Form.Item>
                </Col>
              ) : null}
              <Col span={12}>
                <Form.Item
                  label='Code'
                  name='code'
                  rules={[
                    {
                      required: true,
                      message: REQUIRED_FIELD_WITH_NAME_MESSAGE,
                    },
                    {
                      message: "'Code' must be 3 characters long",
                      min: 3,
                      max: 3,
                    },
                  ]}
                  hidden={_get(addNewCodeModal, 'has_code') === false}
                >
                  <Input disabled={isDefaultCodeUseChecked} />
                </Form.Item>
                {_get(addNewCodeModal, 'show_default_code') &&
                !(
                  _indexOf(
                    codesCompactList.map((i) => i.code),
                    _get(addNewCodeModal, 'default_code')
                  ) > -1
                ) ? (
                  <Checkbox
                    checked={isDefaultCodeUseChecked}
                    onChange={(e) =>
                      setDefaultCodeAsCode(e.target.checked, {
                        code: _get(addNewCodeModal, 'default_code'),
                      })
                    }
                    hidden={_get(addNewCodeModal, 'has_code') === false}
                  >
                    Use Default Code
                  </Checkbox>
                ) : null}
              </Col>
              <Col span={24}>
                <Form.Item
                  label='Description'
                  name='description'
                  rules={[
                    {
                      required: true,
                      message: REQUIRED_FIELD_WITH_NAME_MESSAGE,
                    },
                    {
                      message:
                        "'Description' must be between 3 - 255 characters long",
                      min: 3,
                      max: 255,
                    },
                  ]}
                  hidden={_get(addNewCodeModal, 'has_code') === false}
                >
                  <Input.TextArea disabled={isDefaultCodeUseChecked} />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16} justify={'end'}>
              <Col>
                <Form.Item>
                  <Button
                    type='primary'
                    htmlType='submit'
                    loading={codeListDataActionLoading}
                  >
                    {' '}
                    Update{' '}
                  </Button>
                </Form.Item>
              </Col>
              <Col>
                <Button
                  type='secondary'
                  onClick={() => toggleAddNewCodeModal(false)}
                >
                  {' '}
                  Cancel
                </Button>
              </Col>
            </Row>
          </Form>
        </ModalCom>
      </AppBase>
    </Drawer>
  );
}

export default ManufacturerCodeDrawer;
