import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { connect } from 'react-redux';
import { _get } from './utils/lodashUtils';
import Error404 from './components/common/errors/Error404';
import { globalUpdateRoute } from './redux/actions/globalReducActions';
import LogIn from './components/modules/auth/login';
import { cloneOrgDetails, cloneUser } from './redux/actions/authReduxActions';

import AdminLogin from './components/modules/auth/login/AdminLogin';
import UserManagement from './components/modules/userManagement';
import Profile from './components/modules/me';
// import ManufacturerManagement from './components/modules/manufacturers';
import SystemConfiguration from './components/modules/system';
import CatalogManagement from './components/modules/catalog';
import BatchManagement from './components/modules/batch';
import { get } from 'lockr';
import { AUTH_ORG_UUID, AUTH_TOKEN } from './constants/dataKeys';
import ProductCategories from './components/modules/productCatagories';
import { Spin } from 'antd';
import AddOrEditCatalog from './components/modules/catalog/AddOrEditCatalog';
import BatchDetail from './components/modules/batch/batchDetail';
import ResetPassword from './components/modules/auth/resetPassword';
import ForgotPasswordLink from './components/modules/auth/resetPassword/ForgotPasswordLink';
import { hasPermission } from './utils/helper';
import * as ROLES from './constants/roles';
import Maintenance from './components/modules/maintenance';
import ReleaseNotes from './components/modules/releaseNotes';
import Manufacturers from './components/modules/manufacturers';
import { ARCHIVE } from './constants/constants';

class RoutesHome extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loadingPermissions: true,
    };
  }

  componentDidMount() {
    cloneUser(this);
    let uuid = get(AUTH_ORG_UUID);
    let token = get(AUTH_TOKEN);
    if (uuid && token) cloneOrgDetails(uuid);
  }

  protectComponent = (Component, render) => {
    if (this.props.loggedIn) {
      return Component;
    }
    let uuid = get(AUTH_ORG_UUID);
    return <Navigate to={`/login/?org_id=${uuid}`} />;
  };

  render() {
    // let that = this;
    const { loadingPermissions } = this.state;
    const { roleCode } = this.props;
    return loadingPermissions ? (
      <Spin spinning={loadingPermissions} tip='Loading...'>
        <div style={{ height: '100vh', width: '100vw' }} />
      </Spin>
    ) : (
      <Routes>
        {/*Auth Modulus Routes*/}
        <Route path='/login' element={<LogIn />} />
        <Route path='/login/root' element={<AdminLogin />} />
        <Route exact path={'/reset-password'} element={<ResetPassword />} />
        <Route
          exact
          path={'/reset-password/:id'}
          element={<ForgotPasswordLink />}
        />

        <Route
          exact
          path='/users'
          element={this.protectComponent(
            hasPermission(
              [ROLES.ROLE_ADMIN_USER, ROLES.ROLE_ROOT_USER],
              roleCode
            ) ? (
              <UserManagement />
            ) : (
              <Navigate to={'/batch'} />
            )
          )}
        />
        <Route
          exact
          path='/categories'
          element={this.protectComponent(
            hasPermission(
              [
                ROLES.ROLE_ADMIN_USER,
                ROLES.ROLE_ROOT_USER,
                ROLES.ROLE_STANDARD_USER,
              ],
              roleCode
            ) ? (
              <ProductCategories />
            ) : (
              <Error404 />
            )
          )}
        />
        <Route
          exact
          path='/manufacturers'
          element={this.protectComponent(
            hasPermission(
              [
                ROLES.ROLE_ADMIN_USER,
                ROLES.ROLE_ROOT_USER,
                ROLES.ROLE_STANDARD_USER,
              ],
              roleCode
            ) ? (
              // ? <ManufacturerManagement />
              <Manufacturers />
            ) : (
              <Error404 />
            )
          )}
        />
        <Route
          exact
          path='/system'
          element={this.protectComponent(
            hasPermission(
              [
                ROLES.ROLE_ADMIN_USER,
                ROLES.ROLE_ROOT_USER,
                ROLES.ROLE_STANDARD_USER,
              ],
              roleCode
            ) ? (
              <SystemConfiguration />
            ) : (
              <Error404 />
            )
          )}
        />
        <Route
          exact
          path='/catalog'
          element={this.protectComponent(
            hasPermission(
              [
                ROLES.ROLE_ADMIN_USER,
                ROLES.ROLE_ROOT_USER,
                ROLES.ROLE_STANDARD_USER,
                ROLES.ROLE_CATALOG_READ_ONLY_USER,
              ],
              roleCode
            ) ? (
              <CatalogManagement />
            ) : (
              <Error404 />
            )
          )}
        />
        <Route
          exact
          path='/catalog/add'
          element={this.protectComponent(
            hasPermission(
              [
                ROLES.ROLE_ADMIN_USER,
                ROLES.ROLE_ROOT_USER,
                ROLES.ROLE_STANDARD_USER,
                ROLES.ROLE_CATALOG_READ_ONLY_USER,
              ],
              roleCode
            ) ? (
              <AddOrEditCatalog type={'ADD'} />
            ) : (
              <Error404 />
            )
          )}
        />
        <Route
          exact
          path='/catalog/:id'
          element={this.protectComponent(
            hasPermission(
              [
                ROLES.ROLE_ADMIN_USER,
                ROLES.ROLE_ROOT_USER,
                ROLES.ROLE_STANDARD_USER,
                ROLES.ROLE_CATALOG_READ_ONLY_USER,
              ],
              roleCode
            ) ? (
              <AddOrEditCatalog type={'EDIT'} />
            ) : (
              <Error404 />
            )
          )}
        />
        <Route
          exact
          path='/batch'
          element={this.protectComponent(
            hasPermission(
              [
                ROLES.ROLE_ADMIN_USER,
                ROLES.ROLE_ROOT_USER,
                ROLES.ROLE_STANDARD_USER,
              ],
              roleCode
            ) ? (
              <BatchManagement />
            ) : (
              <Error404 />
            )
          )}
        />
        <Route
          exact
          path='/batch/:id'
          element={this.protectComponent(
            hasPermission(
              [
                ROLES.ROLE_ADMIN_USER,
                ROLES.ROLE_ROOT_USER,
                ROLES.ROLE_STANDARD_USER,
              ],
              roleCode
            ) ? (
              <BatchDetail />
            ) : (
              <Error404 />
            )
          )}
        />
        <Route
          exact
          path='/archived-batch/:id'
          element={this.protectComponent(
            hasPermission(
              [
                ROLES.ROLE_ADMIN_USER,
                ROLES.ROLE_ROOT_USER,
                ROLES.ROLE_STANDARD_USER,
              ],
              roleCode
            ) ? (
              <BatchDetail type={ARCHIVE} />
            ) : (
              <Error404 />
            )
          )}
        />

        <Route
          exact
          path='/maintenance'
          element={this.protectComponent(
            hasPermission(
              [
                ROLES.ROLE_ADMIN_USER,
                ROLES.ROLE_ROOT_USER,
                ROLES.ROLE_STANDARD_USER,
              ],
              roleCode
            ) ? (
              <Maintenance />
            ) : (
              <Error404 />
            )
          )}
        />
        <Route exact path='/me' element={this.protectComponent(<Profile />)} />
        <Route
          exact
          path='/release-notes'
          element={this.protectComponent(<ReleaseNotes />)}
        />
        <Route
          exact
          path='/'
          element={
            this.props.loggedIn ? (
              roleCode === ROLES.ROLE_CATALOG_READ_ONLY_USER ? (
                <CatalogManagement />
              ) : (
                <BatchManagement />
              )
            ) : (
              <LogIn />
            )
          }
        />
        <Route path='*' element={<Error404 />} />
      </Routes>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: _get(state, 'auth.user'),
    loggedIn: _get(state, 'auth.loggedIn'),
    rolePermissions: _get(state, 'auth.Role.RolePermissions', []),
    roleCode: _get(state, 'auth.Role.role_code'),
  };
};
const mapDispatchToProps = {
  globalUpdateRoute,
};
export default connect(mapStateToProps, mapDispatchToProps)(RoutesHome);
