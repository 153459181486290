import { createSlice } from '@reduxjs/toolkit';
import { CATALOG_CONNECTOR_DATA } from '../reduxConstant';
import { changeApiLoader } from './apiLoaderSlice';
import { postAPI } from '../../utils/apiRequest';
import { CATALOG_CONNECTOR_LIST } from '../../constants/api';
import { interpolate } from '../../utils/common';

export const catalogConnectorSlice = createSlice({
  name: CATALOG_CONNECTOR_DATA,
  initialState: {
    data: {},
  },
  reducers: {
    setCatalogConnectorData: (state, action) => {
      state.data = action.payload;
    },
  },
});

export const getCatalogConnectorData = (id) => (dispatch) => {
  dispatch(changeApiLoader(true));

  let successFn = (results) => {
    dispatch(changeApiLoader(false));
    dispatch(setCatalogConnectorData(results));
  };

  let errorFn = (error) => {
    dispatch(changeApiLoader(false));
  };

  postAPI(
    interpolate(CATALOG_CONNECTOR_LIST, [id]),
    { limit: -1 },
    successFn,
    errorFn
  );
};

export const { setCatalogConnectorData } = catalogConnectorSlice.actions;

export default catalogConnectorSlice.reducer;
