import React, { useEffect } from 'react';
import { Card, Col, Layout, Row } from 'antd';
import { _get } from '../../../utils/lodashUtils';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const { Content } = Layout;

function AuthBase(props) {
  const navigate = useNavigate();
  const loggedIn = useSelector(state => _get(state, 'auth.loggedIn'));
  useEffect(() => {
    if (loggedIn)
      navigate('/', { replace: true });
  }, [loggedIn]);

  return (
    <Layout style={{ background: '#E5E5E5' }}>
      <Content
        style={{
          minHeight: '100vh',
          overflow: 'auto',
        }}
      >
        <Row justify={'space-around'} align='middle' style={{
          minHeight: '100vh',
        }}>
          <Col md={8}>
            <Card>
              <img src={'/loginLogo.png'} alt='Company Logo' className={'mb-25'} style={{ maxHeight: 48, maxWidth: '100%' }} />
              {props.children}
            </Card>
          </Col>
        </Row>
      </Content>
    </Layout>
  );
}

export default AuthBase;