import { createSlice } from "@reduxjs/toolkit";
import { API_LOADER } from "../reduxConstant";

export const apiLoaderSlice = createSlice({
    name: API_LOADER,
    initialState: {
        bool: false
    },
    reducers: {
        changeApiLoader: (state, action) => {
            state.bool = action.payload
        }
    }
})

export const { changeApiLoader } = apiLoaderSlice.actions;

export default apiLoaderSlice.reducer;