import React, { useEffect } from 'react';
import { App } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty } from 'lodash';
import {
  TYPE_ERROR,
  TYPE_INFO,
  TYPE_LOADING,
  TYPE_SUCCESS,
  TYPE_WARNING,
} from '../../constants/message';
import { resetMessage } from '../../redux/Slices/messageSlice';

const MessageCom = (props) => {
  const { message } = App.useApp();
  const messageReducer = useSelector((state) => state.message.data);
  const dispatch = useDispatch();

  const error = (content) => {
    message.error({
      content: content,
      onClose: () => dispatch(resetMessage()),
    });
  };

  const success = (content) => {
    message.success({
      content: content,
      onClose: () => dispatch(resetMessage()),
    });
  };

  const info = (content) => {
    message.info({
      content: content,
      onClose: () => dispatch(resetMessage()),
    });
  };

  const warning = (content) => {
    message.warning({
      content: content,
      onClose: () => dispatch(resetMessage()),
    });
  };

  const loading = (content) => {
    message.loading({
      content: content,
      duration: 0,
      onClose: () => dispatch(resetMessage()),
    });
  };

  useEffect(() => {
    if (!isEmpty(messageReducer)) {
      switch (messageReducer.type) {
        case TYPE_ERROR:
          error(messageReducer.msg);
          break;
        case TYPE_SUCCESS:
          success(messageReducer.msg);
          break;
        case TYPE_INFO:
          info(messageReducer.msg);
          break;
        case TYPE_WARNING:
          warning(messageReducer.msg);
          break;
        case TYPE_LOADING:
          loading(messageReducer.msg);
          break;
        default:
          return;
      }
    }
  }, [messageReducer]);
};

export default () => (
  <App>
    <MessageCom />
  </App>
);
