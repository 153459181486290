import { createSlice } from '@reduxjs/toolkit';
import { DUPLICATE_BATCHES } from '../reduxConstant';
import { postAPI } from '../../utils/apiRequest';
import {
  DUPLICATE_BATCH_ITEM_IDS,
  DUPLICATE_BATCH_ITEM_ID_ITEMS,
} from '../../constants/api';
import { changeApiLoader } from './apiLoaderSlice';
import { interpolate } from '../../utils/common';

export const duplicateBatchSlice = createSlice({
  name: DUPLICATE_BATCHES,
  initialState: {
    data: [],
    itemData: [],
    batchId: -1,
  },
  reducers: {
    setDuplicateBatchesData: (state, action) => {
      state.data = action.payload;
    },
    setBatchId: (state, action) => {
      state.batchId = action.payload;
    },
    setDuplicateBatchItemData: (state, action) => {
      state.itemData = action.payload;
    },
  },
});

export const getDuplicateBatchesData = (batchId) => (dispatch) => {
  dispatch(changeApiLoader(true));

  let successFn = function (result) {
    dispatch(changeApiLoader(false));
    dispatch(setBatchId(batchId));
    dispatch(setDuplicateBatchesData(result.data));
  };
  let errorFn = function (error) {
    dispatch(changeApiLoader(false));
  };

  postAPI(
    interpolate(DUPLICATE_BATCH_ITEM_IDS, [batchId]),
    {},
    successFn,
    errorFn
  );
};

export const getDuplicateBatchItemData = (batchId, itemId) => (dispatch) => {
  dispatch(changeApiLoader(true));

  let successFn = function (results) {
    dispatch(setDuplicateBatchItemData(results.data));
    dispatch(changeApiLoader(false));
  };

  let errorFn = function (error) {
    dispatch(changeApiLoader(false));
  };

  postAPI(
    interpolate(DUPLICATE_BATCH_ITEM_ID_ITEMS, [batchId, itemId]),
    {},
    successFn,
    errorFn
  );
};

export const {
  setDuplicateBatchesData,
  setBatchId,
  setDuplicateBatchItemData,
} = duplicateBatchSlice.actions;

export default duplicateBatchSlice.reducer;
