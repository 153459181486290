import {set} from 'lockr';
import {
  AUTH_LOGIN_TYPE,
  AUTH_LOGOUT_TYPE,
  REDUX_PERSIST,
  AUTH_TOKEN,
  AUTH_USER_DATA, AUTH_ORG_DETAIL,
} from '../reduxConstant';
import { _get } from '../../utils/lodashUtils';
import { clearStorage } from '../../utils/apiRequest';
import { APPLICATION_URL, AUTH_ORG_UUID } from '../../constants/dataKeys';

export default (state = {}, action) => {
  switch (action.type) {
    case AUTH_LOGIN_TYPE: {
      set(AUTH_TOKEN, _get(action, 'payload.token'));
      if ( _get(action, 'payload.org_id')) set(AUTH_ORG_UUID, _get(action, 'payload.org_id'));
      if (_get(action, 'payload.portal_backend_url'))  set(APPLICATION_URL, _get(action, 'payload.portal_backend_url'));
      return {
        ...state,
        ...action.payload,
        loggedIn: true,
      };
    }
    case AUTH_LOGOUT_TYPE: {
      clearStorage();
      return {
        ...state,
        user: null,
        token: null,
        loggedIn: false,
        isSuperUser: null,
      };
    }

    case AUTH_USER_DATA:
      return {
        ...state,
        ...action.payload,
        loggedIn: true,
      };
    case AUTH_ORG_DETAIL:
      return {
        ...state,
        org:{...action.payload},
      }
    default:
      return state;
  }
};
