import {
  Button,
  Card,
  Checkbox,
  Col,
  Form,
  Input,
  Row,
  Select,
  Spin,
  Tooltip,
  Tree,
  Typography,
} from 'antd';
import React, { useEffect, useState } from 'react';
import { postAPI } from '../../../utils/apiRequest';
import {
  PRODUCT_CATEGORY_LIST,
  PRODUCT_CATEGORY_NEW,
} from '../../../constants/api';
import { EditOutlined, PlusOutlined } from '@ant-design/icons';
import { REQUIRED_FIELD_WITH_NAME_MESSAGE } from '../../../constants/message';
import ModalCom from '../../common/Modal';
import { useDispatch } from 'react-redux';
import { EditProductCategoryModal } from './EditProductCategoryModal';
import { getProductCatLaborCatMappingData } from '../../../redux/Slices/productCatLaborCatSlice';
import {
  getProductCatDefaultItemData,
  getProductCatSyncConnMapData,
} from '../../../redux/Slices/productCatSyncConnMapSlice';

const { Title } = Typography;

function ProductCategoriesList() {
  const [toggleAddCategoryModal, setToggleAddCategoryModal] = useState(false);
  const [toggleEditCategoryModal, setToggleEditCategoryModal] = useState(false);
  const [parentCategoryAvailable, setParentCategoryAvailable] = useState(true);
  const [generalData, setCategoriesData] = useState([]);
  const [generalDataLoading, toggleCategoriesDataLoading] = useState(false);
  const [CategoryDataActionLoading, toggleCategoryDataActionLoading] =
    useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    loadProductCategories();
  }, []);
  const loadProductCategories = () => {
    let params = {};
    toggleCategoriesDataLoading(true);
    let successFn = function (result) {
      setCategoriesData(result.data);
      toggleCategoriesDataLoading(false);
    };
    let errorFn = function () {
      toggleCategoriesDataLoading(false);
    };
    postAPI(PRODUCT_CATEGORY_LIST, params, successFn, errorFn);
  };
  const addKeyInArrayData = (data) => {
    return data.map((node) => {
      if (node.items.length) {
        return {
          ...node,
          title: node.name,
          key: node.id,
          children: addKeyInArrayData(node.items),
        };
      }
      return { ...node, title: node.name, key: node.id };
    });
  };

  const addCategory = (values) => {
    toggleCategoryDataActionLoading(true);
    let reqData = {
      ...values,
      parent_category_id: values.make_parent ? null : values.parent_category_id,
      make_parent: undefined,
    };
    let successFn = function (result) {
      toggleCategoryDataActionLoading(false);
      setParentCategoryAvailable(true);
      setToggleAddCategoryModal(false);
      loadProductCategories();
    };
    let errorFn = function () {
      toggleCategoryDataActionLoading(false);
    };
    postAPI(PRODUCT_CATEGORY_NEW, reqData, successFn, errorFn);
  };
  return (
    <Card>
      <Row justify={'space-between'}>
        <Col>
          <Title className={'page-title'} level={2}>
            Product Categories
          </Title>
        </Col>
        <Col>
          <Button
            size='large'
            icon={<PlusOutlined />}
            type={'primary'}
            onClick={() => {
              setToggleAddCategoryModal(true);
              setParentCategoryAvailable(true);
            }}
          >
            Add New Category
          </Button>
        </Col>
      </Row>
      <Row>
        <Col>
          <Spin spinning={generalDataLoading}>
            <Tree
              showLine={true}
              showIcon={false}
              showLeafIcon={false}
              treeData={generalData}
              fieldNames={{
                title: 'name',
                key: 'id',
                children: 'items',
              }}
              rootStyle={{ background: 'transparent' }}
              titleRender={(item) => (
                <Typography>
                  {item.name}{' '}
                  <Tooltip title={'Edit'} overlayClassName='tooltip'>
                    <EditOutlined
                      onClick={() => {
                        setToggleEditCategoryModal(item);
                        setParentCategoryAvailable(!!item.parent_category_id);
                        dispatch(getProductCatLaborCatMappingData(item.id));
                        dispatch(getProductCatSyncConnMapData(item.id));
                        dispatch(getProductCatDefaultItemData());
                      }}
                    />
                  </Tooltip>{' '}
                </Typography>
              )}
            ></Tree>
          </Spin>
        </Col>
      </Row>
      <ModalCom
        open={toggleAddCategoryModal}
        key={toggleAddCategoryModal}
        title={'Add New Category'}
        onCancel={() => {
          setToggleAddCategoryModal(false);
          setParentCategoryAvailable(true);
        }}
        footer={null}
      >
        <Form
          layout='vertical'
          onFinish={addCategory}
          initialValues={{
            send_notifications: false,
            is_active: true,
            make_parent: !parentCategoryAvailable,
          }}
        >
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                label='Category Name'
                name='name'
                rules={[
                  { required: true, message: REQUIRED_FIELD_WITH_NAME_MESSAGE },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label='Make Parent Category'
                name='make_parent'
                valuePropName={'checked'}
              >
                <Checkbox
                  onChange={(e) =>
                    setParentCategoryAvailable(!e.target.checked)
                  }
                />
              </Form.Item>
            </Col>
            {parentCategoryAvailable ? (
              <Col span={12}>
                <Form.Item
                  label='Parent Category'
                  name='parent_category_id'
                  rules={[
                    {
                      required: true,
                      message: REQUIRED_FIELD_WITH_NAME_MESSAGE,
                    },
                  ]}
                >
                  <Select>
                    {generalData?.map((item) => (
                      <Select.Option value={item.id} key={item.id}>
                        {item.name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            ) : null}
          </Row>
          <Row gutter={16} justify={'end'}>
            <Col>
              <Form.Item>
                <Button
                  type='primary'
                  htmlType='submit'
                  loading={CategoryDataActionLoading}
                  className='btn-green'
                >
                  {' '}
                  Add Category{' '}
                </Button>
              </Form.Item>
            </Col>
            <Col>
              <Button
                type='secondary'
                onClick={() => {
                  setToggleAddCategoryModal(false);
                  setParentCategoryAvailable(true);
                }}
              >
                {' '}
                Cancel
              </Button>
            </Col>
          </Row>
        </Form>
      </ModalCom>
      <EditProductCategoryModal
        toggleEditCategoryModal={toggleEditCategoryModal}
        setToggleEditCategoryModal={setToggleEditCategoryModal}
        loadProductCategories={loadProductCategories}
        parentCategoryAvailable={parentCategoryAvailable}
        setParentCategoryAvailable={setParentCategoryAvailable}
        generalData={generalData}
      />
    </Card>
  );
}

export default ProductCategoriesList;
