import React, { useEffect, useState } from 'react';
import ModalCom from '../../common/Modal';
import {
  Col,
  Collapse,
  Input,
  Modal,
  Pagination,
  Row,
  Spin,
  Switch,
  Table,
  Tooltip,
  Typography,
} from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import {
  BAD_CPN_CALLBACK_FUNC_NAMES,
  BATCH,
  CATALOG,
  PRIMARY_COLOR,
} from '../../../constants/constants';
import { useDispatch, useSelector } from 'react-redux';
import { _get } from '../../../utils/lodashUtils';
import {
  getBadCpnData,
  getBadCpnDataItems,
  setBadCpnData,
  setBadCpnDataEditCatalog,
  setBadCpnDataItems,
} from '../../../redux/Slices/badCpnBatchesSlice';
import { get, isEmpty } from 'lodash';
import { setMessage } from '../../../redux/Slices/messageSlice';
import { TYPE_ERROR, TYPE_WARNING } from '../../../constants/message';
import { postAPI, putAPI } from '../../../utils/apiRequest';
import {
  CATALOG_CONNECTOR_UPDATE,
  CATALOG_RECTIFY_BAD_CPN,
  RECTIFY_BAD_CPN_BATCHES,
} from '../../../constants/api';
import { changeApiLoader } from '../../../redux/Slices/apiLoaderSlice';
import { useNavigate } from 'react-router';
import { interpolate } from '../../../utils/common';
import { getCatalogConnectorData } from '../../../redux/Slices/catalogConnectorSlice';
import {
  getCatalogsData,
  setSyncConnAllCheckbox,
  updateCatalogsData,
} from '../../../redux/Slices/catalogSlice';

export const BadCpnModal = (props) => {
  const { open, modalName, onCancel, ...restProps } = props;

  const [rectifiedBadCpnArr, setRectifiedBadCpnArr] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [activeKey, setActiveKey] = useState([]);

  const apiLoader = useSelector((state) => state.apiLoader.bool);
  const badCpnData = useSelector((state) => state.badCpnBatchesReducer.data);
  const badCpnDataItems = useSelector(
    (state) => state.badCpnBatchesReducer.dataItems
  );
  const callbackFuncName = useSelector(
    (state) => state.badCpnBatchesReducer.callbackFuncName
  );
  const callbackFuncParams = useSelector(
    (state) => state.badCpnBatchesReducer.callbackFuncParams
  );
  const syncConnAllCheckbox = useSelector(
    (state) => state.catalogs.syncConnAllCheckbox
  );

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (open && modalName === BATCH)
      dispatch(getBadCpnData({ limit: 10, offset: 0 }));
  }, [open, modalName]);

  const setData = (index, value) => {
    let tempArr = [...badCpnDataItems];
    if (typeof value === 'boolean') {
      tempArr[index] = {
        ...tempArr[index],
        is_active: value,
        connector_part_number: value
          ? tempArr[index].connector_part_number
          : '',
      };
    } else {
      tempArr[index] = {
        ...tempArr[index],
        is_active: true,
        connector_part_number: value,
      };
    }
    const tempRectifiedArr = [...rectifiedBadCpnArr];
    let findI;
    let check = false;
    tempRectifiedArr.forEach((value, i) => {
      let checkV = `${value.id.toString()}-${value.sync_connector_id.toString()}`;
      let checkT = `${tempArr[index].id.toString()}-${tempArr[
        index
      ].sync_connector_id.toString()}`;
      if (checkV === checkT) {
        findI = i;
        check = true;
      }
    });
    if (check) {
      tempRectifiedArr[findI] = {
        ...tempRectifiedArr[findI],
        is_active: tempArr[index].is_active,
        connector_part_number: tempArr[index].connector_part_number,
      };
    } else {
      tempRectifiedArr.push({
        id: tempArr[index].id,
        item_id: tempArr[index].item_id,
        sync_connector_id: tempArr[index].sync_connector_id,
        is_active: tempArr[index].is_active,
        connector_part_number: tempArr[index].connector_part_number,
      });
    }
    setRectifiedBadCpnArr(tempRectifiedArr);
    dispatch(setBadCpnDataItems(tempArr));
  };

  const renderColumns = () => {
    const columns = [
      {
        title: 'Status',
        dataIndex: 'is_active',
        ellipsis: true,
        width: 70,
        render: (value, record, index) => (
          <Switch
            defaultValue={true}
            value={value}
            onChange={(ev) => {
              setData(index, ev);
            }}
          />
        ),
      },
      {
        title: 'Connector',
        dataIndex: 'SyncConnector',
        render: (obj) => (!isEmpty(obj) ? obj.name : '--'),
        ellipsis: true,
        width: 100,
      },
      {
        title: 'Connector Description',
        dataIndex: 'SyncConnector',
        render: (value) =>
          value ? (
            <Tooltip title={value.description} overlayClassName='tooltip'>
              <Typography.Text ellipsis>{value.description}</Typography.Text>
            </Tooltip>
          ) : (
            '--'
          ),
        ellipsis: true,
        width: 200,
      },
      {
        title: 'Issue Description',
        dataIndex: 'issue_description',
        ellipsis: true,
        width: 200,
        render: (value) =>
          value ? (
            <Tooltip title={value} overlayClassName='tooltip'>
              <Typography.Text ellipsis>{value}</Typography.Text>
            </Tooltip>
          ) : (
            '--'
          ),
      },
      {
        title: 'CPN',
        dataIndex: 'connector_part_number',
        ellipsis: true,
        width: 120,
        render: (value, record, index) => (
          <Input
            placeholder=''
            value={value}
            type='text'
            showCount={true}
            maxLength={get(
              record,
              'SyncConnector.SyncConnectorType.max_spn_length'
            )}
            disabled={record.is_active === false ? true : false}
            onChange={(ev) => {
              setData(index, ev.target.value);
            }}
          />
        ),
      },
    ];

    return columns;
  };

  const renderLabel = (data) => {
    const renderLabelColumn = (title, value, span) => {
      return (
        <Col span={span}>
          <Typography.Text style={{ fontWeight: 'bold', display: 'block' }}>
            {title}
          </Typography.Text>
          {title === 'Item Description' ? (
            value ? (
              <Tooltip title={value} overlayClassName='tooltip'>
                <Typography.Text ellipsis>{value}</Typography.Text>
              </Tooltip>
            ) : (
              '--'
            )
          ) : (
            <Typography.Text>{value ? value : '--'}</Typography.Text>
          )}
        </Col>
      );
    };

    return (
      <Row>
        {renderLabelColumn('ItemID', data.item_id, 4)}
        {renderLabelColumn('Item Description', data.description, 12)}
        {renderLabelColumn('MPN', data.vendor_part_num, 4)}
        {renderLabelColumn('SPN', data.spn, 4)}
      </Row>
    );
  };

  const renderCollapseItems = () => {
    const items = _get(badCpnData, 'data', []).map((data) => {
      return {
        key: data.item_id,
        label: renderLabel(data),
        children: (
          <Table
            dataSource={badCpnDataItems}
            columns={renderColumns()}
            rowKey={(record) => {
              return record.id + record.sync_connector_id;
            }}
            pagination={false}
            virtual={true}
            scroll={{
              x: 600,
              y: 400,
            }}
            rowClassName={'table-child-row'}
            className='bad-cpn-modal'
          />
        ),
      };
    });

    return items;
  };

  const onCollapseChange = (key) => {
    if (key.length) {
      setActiveKey(key);
      if (modalName === BATCH) {
        dispatch(setBadCpnDataItems([]));
        dispatch(getBadCpnDataItems(key[0], rectifiedBadCpnArr));
      } else if (modalName === CATALOG) {
        let tempArr = [...badCpnData.data];
        tempArr.forEach((item) => {
          if (item.item_id === parseInt(key[0])) {
            dispatch(setBadCpnDataItems(item.sc_data));
          }
        });
      }
    } else {
      setActiveKey([]);
    }
  };

  const confirmCancel = () => {
    Modal.confirm({
      title: 'Are you sure?',
      icon: <ExclamationCircleOutlined />,
      content:
        'Do you want to cancel? All unsaved items will be made inactive.',
      okText: 'Yes',
      className: 'confirm-modal',
      onOk: () => {
        onSave(true);
      },
    });
  };

  const onSave = (cancel) => {
    dispatch(changeApiLoader(true));
    let successFn = (results) => {
      setRectifiedBadCpnArr([]);
      setActiveKey([]);
      dispatch(getBadCpnData({ limit: 10, offset: 0 }));
      dispatch(changeApiLoader(false));
      navigate('/batch');
    };

    let errorFn = (error) => {
      console.log('error in onSave', error);
      dispatch(changeApiLoader(false));
    };

    let data;
    if (cancel) {
      data = { is_cancel: true, items: [] };
    } else {
      let items = [...rectifiedBadCpnArr];
      data = {
        items: items.map((item) => {
          if (!item.is_active) {
            delete item.connector_part_number;
          }
          return item;
        }),
      };
    }

    postAPI(RECTIFY_BAD_CPN_BATCHES, data, successFn, errorFn);
  };

  const editCatalogCallCancel = () => {
    dispatch(setBadCpnData({}));
    dispatch(setBadCpnDataItems([]));
    setRectifiedBadCpnArr([]);
    setActiveKey([]);
    onCancel();
  };

  const execCallback = (results) => {
    const {
      UPDATE_CATALOGS_DATA,
      GET_CATALOGS_DATA,
      GET_CATALOG_SYNC_CONNECTOR_DATA,
    } = BAD_CPN_CALLBACK_FUNC_NAMES;
    switch (callbackFuncName) {
      case UPDATE_CATALOGS_DATA:
        const { id, catalogs, syncConnectorsCheckboxData, rowIndex, syncId } =
          callbackFuncParams;
        dispatch(
          updateCatalogsData(
            id,
            catalogs,
            syncConnectorsCheckboxData,
            get(results, 'active_item_sync_connector_ids', []),
            rowIndex
          )
        );
        dispatch(
          setSyncConnAllCheckbox({ ...syncConnAllCheckbox, [syncId]: false })
        );
        break;
      case GET_CATALOGS_DATA:
        dispatch(getCatalogsData({ ...callbackFuncParams }));
        dispatch(
          setSyncConnAllCheckbox({
            ...syncConnAllCheckbox,
            [badCpnData.syncConnectorId]: true,
          })
        );
        break;
      case GET_CATALOG_SYNC_CONNECTOR_DATA:
        dispatch(getCatalogConnectorData(badCpnData.catalogId));
        break;
      default:
        break;
    }
  };

  const updateBadCpnDataEditCatalog = (results, sentArr) => {
    const { _recData } = results;
    let badCpnDataArr = [...badCpnData.data];
    const formSentArrObj = (arr) => {
      let obj = {};
      arr.forEach((item) => {
        obj[`${item.item_id}-${item.sync_connector_id}`] = {
          is_active: item.is_active,
          connector_part_number: item.connector_part_number,
        };
      });
      return obj;
    };
    const formRecDataSCDataObj = (arr) => {
      let obj = {};
      arr.forEach((item) => {
        item.sc_data.forEach((scItem) => {
          obj[`${scItem.item_id}-${scItem.sync_connector_id}`] = scItem;
        });
      });
      return obj;
    };
    const updateBadCpnDataItem = (oldArr, item, itemId, scId) => {
      badCpnDataArr = oldArr.map((oaItem) => {
        return {
          ...oaItem,
          sc_data: oaItem.sc_data.map((scItem) => {
            return scItem.item_id === itemId &&
              scItem.sync_connector_id === scId
              ? item
              : scItem;
          }),
        };
      });
    };
    const deleteBadCpnDataItem = (oldArr, itemId, scId) => {
      let arr = oldArr.map((oaItem) => {
        return {
          ...oaItem,
          sc_data: oaItem.sc_data.filter((scItem) =>
            scItem.item_id === itemId && scItem.sync_connector_id === scId
              ? false
              : true
          ),
        };
      });
      badCpnDataArr = arr.filter((item) => item.sc_data.length > 0);
    };
    const updateBadCpnDataItems = (arr) => {
      let check = false;
      arr.forEach((item) => {
        if (item.item_id === parseInt(activeKey[0])) {
          check = true;
          dispatch(setBadCpnDataItems(item.sc_data));
        }
      });
      if (check === false) {
        setActiveKey([]);
      }
    };
    let sentArrObj = formSentArrObj(sentArr);
    if (_recData.length > 0) {
      let recDataSCDataObj = formRecDataSCDataObj(_recData);
      sentArr.forEach((saItem) => {
        if (recDataSCDataObj[`${saItem.item_id}-${saItem.sync_connector_id}`]) {
          recDataSCDataObj[`${saItem.item_id}-${saItem.sync_connector_id}`] = {
            ...recDataSCDataObj[
              `${saItem.item_id}-${saItem.sync_connector_id}`
            ],
            ...sentArrObj[`${saItem.item_id}-${saItem.sync_connector_id}`],
          };
          updateBadCpnDataItem(
            badCpnDataArr,
            recDataSCDataObj[`${saItem.item_id}-${saItem.sync_connector_id}`],
            saItem.item_id,
            saItem.sync_connector_id
          );
        } else {
          deleteBadCpnDataItem(
            badCpnDataArr,
            saItem.item_id,
            saItem.sync_connector_id
          );
        }
      });
      dispatch(
        setBadCpnDataEditCatalog(
          { ...results, _recData: badCpnDataArr },
          badCpnData.catalogId,
          badCpnData.syncConnectorId
        )
      );
      updateBadCpnDataItems(badCpnDataArr);
    } else {
      sentArr.forEach((saItem) => {
        deleteBadCpnDataItem(
          badCpnDataArr,
          saItem.item_id,
          saItem.sync_connector_id
        );
      });
      if (badCpnDataArr.length > 0) {
        dispatch(
          setBadCpnDataEditCatalog(
            { ...results, _recData: badCpnDataArr },
            badCpnData.catalogId,
            badCpnData.syncConnectorId
          )
        );
        updateBadCpnDataItems(badCpnDataArr);
      } else {
        setRectifiedBadCpnArr([]);
        setActiveKey([]);
        execCallback(results);
        onCancel();
      }
    }
  };

  const editCatalogCallSave = () => {
    let items = rectifiedBadCpnArr.filter((rItem) => {
      return (
        rItem.is_active === false ||
        (rItem.is_active && rItem.connector_part_number !== '')
      );
    });
    let successFn = (results) => {
      if (results._recData) {
        updateBadCpnDataEditCatalog(results, items);
      } else {
        execCallback(results);
        onCancel();
      }
      dispatch(changeApiLoader(false));
    };
    let errorFn = (error) => {
      dispatch(changeApiLoader(false));
      console.log('error in editCatalogCallSave', error);
    };
    setRectifiedBadCpnArr(items);

    dispatch(changeApiLoader(true));
    let data;
    if (badCpnData.catalogId) {
      data = {
        connectors: items.map((item) => {
          let obj = {
            is_active: item.is_active,
            item_master_sync_connector_id: item.sync_connector_id,
          };
          if (item.is_active) {
            obj = { ...obj, connector_part_number: item.connector_part_number };
          }
          return obj;
        }),
        cost_standard: undefined,
      };
    } else if (badCpnData.syncConnectorId) {
      data = {
        data: items.map((item) => {
          let obj = {
            bad_item_id: item.item_id,
            is_active: item.is_active,
            sync_connector_id: item.sync_connector_id,
          };
          if (item.is_active) {
            obj = { ...obj, connector_part_number: item.connector_part_number };
          }
          return obj;
        }),
      };
    }

    if (badCpnData.catalogId) {
      putAPI(
        interpolate(CATALOG_CONNECTOR_UPDATE, [badCpnData.catalogId]),
        data,
        successFn,
        errorFn
      );
    } else {
      postAPI(CATALOG_RECTIFY_BAD_CPN, data, successFn, errorFn);
    }
  };

  const changePage = (page, limit) => {
    if (rectifiedBadCpnArr.length === 0) {
      setCurrentPage(page);
      dispatch(getBadCpnData({ limit: limit, offset: (page - 1) * limit }));
    } else {
      dispatch(
        setMessage({
          type: TYPE_WARNING,
          msg: 'Please rectify the changed items by clicking on Save',
        })
      );
    }
  };

  const title = (
    <Row>
      <Col span={3}>
        <Typography.Title
          level={2}
          style={{ color: PRIMARY_COLOR, marginTop: 0 }}
        >
          <ExclamationCircleOutlined />
        </Typography.Title>
      </Col>
      <Col>
        <Typography.Title
          level={2}
          style={{ marginTop: 0, marginBottom: '8px' }}
        >
          {modalName === BATCH
            ? 'Bad CPN Batch Items Found'
            : 'Invalid Connector Part Number(s) Found'}
        </Typography.Title>
        <Typography.Text style={{ fontWeight: 400 }}>
          {`Please ${
            modalName === BATCH
              ? 'rectify all bad cpn batch items'
              : 'provide valid CPN(s) for all connectors to proceed'
          }`}
        </Typography.Text>
      </Col>
    </Row>
  );

  return (
    <ModalCom
      title={title}
      open={open}
      width={window.innerWidth - 300 < 800 ? 800 : window.innerWidth - 300}
      okText={'Save'}
      onCancel={() =>
        modalName === BATCH ? confirmCancel() : editCatalogCallCancel()
      }
      onOk={() => (modalName === BATCH ? onSave() : editCatalogCallSave())}
      centered={true}
      className='bad-cpn-modal'
      {...restProps}
    >
      <Spin spinning={apiLoader}>
        <Collapse
          items={renderCollapseItems()}
          activeKey={activeKey}
          onChange={(key) => onCollapseChange(key)}
          accordion={true}
        />
        <br />
        {badCpnData.total !== undefined &&
        badCpnData.limit !== undefined &&
        badCpnData.total > badCpnData.limit ? (
          <Pagination
            size='small'
            total={badCpnData.total}
            showTotal={(total) => `Total ${total} Items`}
            current={currentPage}
            defaultPageSize={badCpnData.limit}
            showSizeChanger={false}
            onChange={(page) => changePage(page, badCpnData.limit)}
          />
        ) : (
          <div />
        )}
      </Spin>
    </ModalCom>
  );
};
