import {
  Checkbox,
  Modal,
  Space,
  Table,
  Tag,
  Tooltip,
  Typography,
  Avatar,
} from 'antd';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { ArrowLeftOutlined } from '@ant-design/icons';
import React, { useCallback, useEffect, useState } from 'react';
import AppBase from '../../../base/AppBase';
import { _debounce, _filter, _get } from '../../../../utils/lodashUtils';
import map from 'lodash/map';
import isEmpty from 'lodash/isEmpty';
import isNull from 'lodash/isNull';
import mapValues from 'lodash/mapValues';
import {
  DESCRIPTION_PARAGRAPH_ELLIPSIS,
  PAGES_CODE as PAGES,
} from '../../../../constants/hardData';
import {
  ARCHIVED_BATCH_SINGLE_DATA,
  BATCH_SINGLE_DATA_DISCONTINUED,
  BATCH_SINGLE_DATA_NEW,
  BATCH_SINGLE_DATA_UNCHANGED,
  BATCH_SINGLE_DATA_UPDATED,
  MANUFACTURER_LIST,
  POST_BATCH,
  UPDATE_BATCH_ITEM_EXCLUDE,
  UPDATE_BATCH_ITEM_LABOR,
  UPDATE_BATCH_ITEM_SYNC_CONNECTORS,
  PRODUCT_CATEGORY_LIST,
  ARCHIVED_BATCH_SINGLE_DATA_NEW,
  ARCHIVED_BATCH_SINGLE_DATA_UPDATED,
  ARCHIVED_BATCH_SINGLE_DATA_UNCHANGED,
} from '../../../../constants/api';
import { patchAPI, postAPI } from '../../../../utils/apiRequest';
import { checkGotoInput, interpolate } from '../../../../utils/common';
import ManufacturerCodeDrawer from '../../manufacturers/ManufacturerCodeDrawer';
import getSymbolFromCurrency from 'currency-symbol-map';
import { useDispatch, useSelector } from 'react-redux';
import {
  globalPagesFilterData,
  globalPagesFilterToggle,
  globalPagesPagination,
} from '../../../../redux/actions/globalReducActions';
import {
  GLOBAL_PAGES_ACTIVE_FILTER_DEFAULT_STATE,
  GLOBAL_PAGES_PAGINATION_DEFAULT_STATE,
} from '../../../../redux/reducers/globalDefaultStates';
import { getProductCategoryTree } from '../../../../constants/hardData';
import BatchDetailTableTitle from './BatchDetailTableTitle';
import {
  changeBadCpnModalName,
  changeBadCpnModalState,
} from '../../../../redux/Slices/badCpnBatchesSlice';
import { ARCHIVE, BATCH } from '../../../../constants/constants';
import { setMessage } from '../../../../redux/Slices/messageSlice';
import { TYPE_ERROR } from '../../../../constants/message';

const { Title, Paragraph } = Typography;

function BatchDetail(props) {
  const { id } = useParams();
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState(
    props.type === ARCHIVE
      ? PAGES.BATCH_ITEMS_NEW_ARCHIVED
      : PAGES.BATCH_ITEMS_NEW
  );
  const [batchData, setBatchData] = useState({});
  const [batchDataLoading, toggleBatchDataLoading] = useState(false);
  const [batchPostActionDataLoading, toggleBatchPostActionDataLoading] =
    useState(false);
  const [batchLaborDataActionLoading, toggleBatchLaborDataActionLoading] =
    useState(false);
  const [batchExcludesDataActionLoading, toggleBatchExcludesDataActionLoading] =
    useState(false);
  const [
    batchSyncConnectorsDataActionLoading,
    toggleBatchSyncConnectorsDataActionLoading,
  ] = useState(false);
  const [laborsToUpdate, setLaborsToUpdate] = useState({});
  const [excludeAllCheckbox, setExcludeAllCheckbox] = useState(false);
  const [syncConnAllCheckbox, setSyncConnAllCheckbox] = useState({});
  const [syncConnectorsCheckboxData, setSyncConnectorsCheckboxData] = useState(
    {}
  );
  const [manufacturerData, setManufacturerData] = useState({});
  const [categoryData, setCategoryData] = useState([]);
  const [gotoPage, setGotoPage] = useState('');
  const tablePaginationParams = useSelector(
    (state) => _get(state, 'global.globalPagesPagination')[activeTab]
  );
  const toggleFilters = useSelector(
    (state) => _get(state, 'global.globalPagesFilterToggle')[activeTab]
  );
  const activeFilters = useSelector(
    (state) => _get(state, 'global.globalPagesFilterData')[activeTab]
  );
  const dispatch = useDispatch();
  const setTablePaginationParams = (param) => {
    if (param && typeof param == 'function') {
      dispatch(globalPagesPagination(activeTab, param(tablePaginationParams)));
    } else {
      dispatch(globalPagesPagination(activeTab, param));
    }
  };
  const setToggleFilters = (param) => {
    if (param && typeof param == 'function') {
      dispatch(globalPagesFilterToggle(activeTab, param(toggleFilters)));
    } else {
      dispatch(globalPagesFilterToggle(activeTab, param));
    }
  };
  const setActiveFilters = (param) => {
    if (param && typeof param == 'function') {
      dispatch(globalPagesFilterData(activeTab, param(activeFilters)));
    } else {
      dispatch(globalPagesFilterData(activeTab, param));
    }
  };
  const onChange = (value, node, extra) => {
    const productCategory = {
      category_code: [],
      subcategory_code: [],
    };
    map(value, (val) => {
      const type = _get(val.split('-'), '0');
      const value = _get(val.split('-'), '1');
      if (type === 'category') {
        _get(productCategory, 'category_code').push(value);
        return;
      }
      _get(productCategory, 'subcategory_code').push(value);
    });
    setActiveFilters((prev) => {
      return {
        ...prev,
        productCategory:
          isEmpty(_get(productCategory, 'category_code')) &&
          isEmpty(_get(productCategory, 'subcategory_code'))
            ? undefined
            : productCategory,
      };
    });
  };
  useEffect(() => {
    if (!batchLaborDataActionLoading) loadBatchData();
    setExcludeAllCheckbox(false);
    setSyncConnAllCheckbox({});
  }, [tablePaginationParams, batchLaborDataActionLoading]);
  useEffect(() => {
    setBatchData({});
    loadBatchData();
  }, [activeTab]);
  useEffect(() => {
    if (_get(activeFilters, 'batch_id') != id) {
      setActiveFilters({
        ...GLOBAL_PAGES_ACTIVE_FILTER_DEFAULT_STATE[activeTab],
        batch_id: id,
      });
      setTablePaginationParams({
        ...GLOBAL_PAGES_PAGINATION_DEFAULT_STATE[activeTab],
        filters: {
          ...GLOBAL_PAGES_ACTIVE_FILTER_DEFAULT_STATE[activeTab],
          batch_id: id,
        },
      });
    }
  }, [id]);
  useEffect(() => {
    loadManufacturersData();
    loadCategoryData();
  }, []);

  const updatePaginationParams = (param) => {
    if (Object.keys(laborsToUpdate).length)
      Modal.confirm({
        title: 'You have unsaved data. Do you want to save your changes?',
        onCancel: function () {
          setLaborsToUpdate({});
          setTablePaginationParams(param);
        },
        cancelText: 'Discard Changes',
        onOk: function () {
          updateLabors();
          setTablePaginationParams(param);
        },
        okText: 'Save',
        type: 'warn',
      });
    else setTablePaginationParams(param);
  };
  const storeLaborToUpdate = (itemId, value) => {
    setLaborsToUpdate((prevState) => {
      let obj = {
        ...prevState,
        [itemId]: { batch_item_id: itemId, value: value || 0 },
      };
      return obj;
    });
  };
  const debouncedStoreLaborToUpdate = useCallback(
    _debounce(storeLaborToUpdate, 500),
    []
  );

  const updateSyncConnectors = (
    itemId,
    syncId,
    value,
    is_labor_required = false
  ) => {
    setSyncConnectorsCheckboxData({
      ...syncConnectorsCheckboxData,
      [itemId]: mapValues(
        _get(syncConnectorsCheckboxData, `${itemId}`),
        () => false
      ),
    });

    if (!itemId) {
      toggleBatchSyncConnectorsDataActionLoading(true);
    }
    let reqData = { items: [] };
    if (itemId)
      reqData = {
        items: [
          {
            batch_item_id: itemId,
            sync_connector_id: syncId,
            is_checked: !!value,
          },
        ],
      };
    else {
      _get(batchData, 'data', []).forEach((record) => {
        if (record.status !== 'V')
          reqData.items.push({
            batch_item_id: record.id,
            sync_connector_id: syncId,
            is_checked: !!value,
          });
      });
    }
    let successFn = function (result) {
      const response = _get(result, 'data.0');

      toggleBatchSyncConnectorsDataActionLoading(false);
      if (itemId) {
        setBatchData((prevState) => {
          return {
            ...prevState,
            data: [
              ..._get(prevState, 'data', []).map((record) => {
                return itemId === record.id
                  ? {
                      ...record,
                      is_labor_required: record.SyncConnectors?.map(
                        (sync) =>
                          sync.require_labor &&
                          !record.labor &&
                          (syncId === sync.id
                            ? value
                            : syncConnectorsCheckboxData[itemId][sync.id])
                      ).includes(true),
                    }
                  : record;
              }),
            ],
          };
        });
        setSyncConnAllCheckbox((prevState) => {
          return { ...prevState, [syncId]: false };
        });
        const selectedChecboxes = {};
        _get(response, 'checked_sync_connectors').length &&
          _get(response, 'checked_sync_connectors').forEach(
            (checked_sync_connector_id) => {
              selectedChecboxes[checked_sync_connector_id] = true;
            }
          );
        setSyncConnectorsCheckboxData((prevState) => {
          return {
            ...prevState,
            [_get(response, 'batch_item_id')]: {
              ..._get(prevState, `${_get(response, 'batch_item_id')}`),
              ...selectedChecboxes,
            },
          };
        });
      } else {
        setSyncConnAllCheckbox((prevState) => {
          return { ...prevState, [syncId]: !!value };
        });
        loadBatchData();
      }
    };
    let errorFn = function () {
      toggleBatchSyncConnectorsDataActionLoading(false);
    };
    if (reqData.items.length)
      patchAPI(
        interpolate(UPDATE_BATCH_ITEM_SYNC_CONNECTORS, [id]),
        reqData,
        successFn,
        errorFn
      );
    else toggleBatchSyncConnectorsDataActionLoading(false);
  };

  const postBatch = () => {
    toggleBatchPostActionDataLoading(true);
    let successFn = function (result) {
      toggleBatchPostActionDataLoading(false);
      navigate('/batch');
    };
    let errorFn = function (response) {
      toggleBatchPostActionDataLoading(false);
      if (response.bad_cpn_flag) {
        dispatch(changeBadCpnModalState(true));
        dispatch(changeBadCpnModalName(BATCH));
      }
      if (!response.success && _get(response, 'data.missing_scs', []).length) {
        setActiveFilters((prev) => {
          return {
            checked_sc: _get(response, 'data.missing_scs', []),
            missing_labor_category: true,
            status: 'NV',
          };
        });
        setTablePaginationParams({
          ...tablePaginationParams,
          offset: 0,
          filters: {
            checked_sc: _get(response, 'data.missing_scs', []),
            missing_labor_category: true,
            status: 'NV',
          },
        });
      }
    };
    postAPI(interpolate(POST_BATCH, [id]), {}, successFn, errorFn);
  };
  const updateLabors = () => {
    let reqData = {
      items: _filter(Object.values(laborsToUpdate), function (item) {
        return item;
      }),
    };
    if (!reqData.items.length) {
      return;
    }
    toggleBatchLaborDataActionLoading(true);
    let successFn = function (result) {
      toggleBatchLaborDataActionLoading(false);
      setLaborsToUpdate({});
    };
    let errorFn = function () {
      toggleBatchLaborDataActionLoading(false);
    };
    patchAPI(
      interpolate(UPDATE_BATCH_ITEM_LABOR, [id]),
      reqData,
      successFn,
      errorFn
    );
  };
  const updateExclude = (recordId, value) => {
    toggleBatchExcludesDataActionLoading(true);
    let reqData = {};
    if (recordId)
      reqData = { items: [{ batch_item_id: recordId, is_checked: !!value }] };
    else
      reqData = {
        items: [
          ..._get(batchData, 'data', []).map((record) => {
            return { batch_item_id: record.id, is_checked: !!value };
          }),
        ],
      };
    let successFn = function (result) {
      toggleBatchExcludesDataActionLoading(false);
      if (recordId) {
        setExcludeAllCheckbox(false);
        setBatchData((prev) => {
          return {
            ...prev,
            data: [
              ..._get(batchData, 'data', []).map((record) => {
                return {
                  ...record,
                  exclude: record.id === recordId ? !!value : record.exclude,
                };
              }),
            ],
          };
        });
      } else {
        setExcludeAllCheckbox(!!value);
        loadBatchData();
      }
    };
    let errorFn = function () {
      toggleBatchExcludesDataActionLoading(false);
    };
    patchAPI(
      interpolate(UPDATE_BATCH_ITEM_EXCLUDE, [id]),
      reqData,
      successFn,
      errorFn
    );
  };
  const loadManufacturersData = () => {
    let successFn = function (result) {
      setManufacturerData(result);
    };
    let errorFn = function () {};
    postAPI(
      MANUFACTURER_LIST,
      { limit: 0, sortBy: 'name', sortDir: 'ASC' },
      successFn,
      errorFn
    );
  };

  const loadCategoryData = () => {
    let successFn = function (result) {
      setCategoryData(getProductCategoryTree(_get(result, 'data')));
    };
    let errorFn = function () {};
    postAPI(PRODUCT_CATEGORY_LIST, {}, successFn, errorFn);
  };

  const tableColumns = () => {
    let columns = [
      {
        title: 'Line',
        key: 'sequence_number',
        dataIndex: 'sequence_number',
        width: 120,
        fixed: true,
        align: 'right',
        // sorter: true,
        sortOrder:
          _get(batchData, 'sortBy') === 'sequence_number'
            ? _get(batchData, 'sortDir') === 'DESC'
              ? 'descend'
              : 'ascend'
            : false,
        render: (value) => value || '-',
      },
      {
        title: 'Manufacturer',
        key: 'manufacturer',
        dataIndex: 'Manufacturer',
        ellipsis: true,
        width: 300,
        render: (value, record) => (
          <Space>
            {!isNull(_get(record, 'item_image_url')) ? (
              <Avatar size='large' src={_get(record, 'item_image_url')} />
            ) : (
              <Avatar style={{ backgroundColor: '#FAA500' }} size='large'>
                {' '}
                {_get(value, 'name', '-').charAt(0)}
              </Avatar>
            )}
            {_get(value, 'name', '-') || '-'}
          </Space>
        ),
      },
      {
        title: (
          <Tooltip placement='top' title='Manufacturer Part Number'>
            MPN
          </Tooltip>
        ),
        key: 'vendor_part_num',
        dataIndex: 'vendor_part_num',
        ellipsis: true,
        width: 180,
        render: (value) => value || '-',
      },
      {
        title: (
          <Tooltip placement='top' title='Distributor Part Number'>
            DPN
          </Tooltip>
        ),
        key: 'item_num',
        dataIndex: 'item_num',
        ellipsis: true,
        width: 180,
        render: (value) => value || '-',
      },
      {
        title: 'Description',
        key: 'description',
        dataIndex: 'description',
        ellipsis: true,
        width: 300,
        render: (value, record) => value || '-',
      },
      ...(activeTab !== PAGES.BATCH_ITEMS_UPDATED
        ? [
            {
              title: 'MSRP',
              dataIndex: 'msrp',
              key: 'msrp',
              width: 100,
              align: 'right',
              // sorter: true,
              sortOrder:
                _get(batchData, 'sortBy') === 'msrp'
                  ? _get(batchData, 'sortDir') === 'DESC'
                    ? 'descend'
                    : 'ascend'
                  : false,
              render: (value) => (value ? (+value).toFixed(2) : '-'),
            },
            {
              title: 'Cost',
              dataIndex: 'price',
              key: 'price',
              width: 100,
              align: 'right',
              // sorter: true,
              sortOrder:
                _get(batchData, 'sortBy') === 'price'
                  ? _get(batchData, 'sortDir') === 'DESC'
                    ? 'descend'
                    : 'ascend'
                  : false,
              render: (value, record) =>
                value
                  ? `${
                      getSymbolFromCurrency(
                        _get(batchData, 'Batch.currency_code')
                      ) || _get(batchData, 'Batch.currency_code')
                    }${(+value).toFixed(2)}`
                  : '-',
            },
          ]
        : []),
    ];
    switch (activeTab) {
      case PAGES.BATCH_ITEMS_NEW:
        columns = [
          ...columns,
          // {
          //   title: 'Labor',
          //   dataIndex: 'labor',
          //   key: 'labor',
          //   width: 100,
          //   align: 'right',
          //   render: (value, record) => (
          //     <InputNumber
          //       size={'small'}
          //       style={{ width: 80 }}
          //       className={'right-aligned'}
          //       key={
          //         record.id +
          //         'labor' +
          //         batchLaborDataActionLoading +
          //         batchDataLoading
          //       }
          //       disabled={batchLaborDataActionLoading || record.status === 'V'}
          //       min={0}
          //       defaultValue={_get(laborsToUpdate[record.id], 'value', value)}
          //       step='1'
          //       onPressEnter={updateLabors}
          //       onChange={(value) =>
          //         debouncedStoreLaborToUpdate(record.id, value)
          //       }
          //     />
          //   ),
          // },
          // ..._get(batchData, 'SyncConnectors', []).map((item, index) => {
          //   return {
          //     title: (
          //       <Checkbox
          //         checked={syncConnAllCheckbox[item.id]}
          //         disabled={batchSyncConnectorsDataActionLoading}
          //         key={item.id}
          //         onChange={(e) =>
          //           updateSyncConnectors(null, item.id, e.target.checked)
          //         }
          //       >
          //         {item.name}
          //       </Checkbox>
          //     ),
          //     key: item.id,
          //     dataIndex: 'SyncConnectors',
          //     width: _get(item, 'name', ' ').length * 8 + 60,
          //     render: (value, record) => (
          //       <Tooltip
          //         title={
          //           item.require_labor && record.labor === null
          //             ? 'Labor Hours should be 0 or more'
          //             : null
          //         }
          //       >
          //         <Checkbox
          //           checked={
          //             syncConnectorsCheckboxData[_get(record, 'id')][
          //               _get(item, 'id')
          //             ]
          //           }
          //           key={`${record.id}-${item.id}`}
          //           disabled={
          //             batchSyncConnectorsDataActionLoading ||
          //             record.status === 'V'
          //           }
          //           onChange={(e) =>
          //             updateSyncConnectors(
          //               record.id,
          //               item.id,
          //               e.target.checked,
          //               !!(item.require_labor && record.labor === null)
          //             )
          //           }
          //         />
          //       </Tooltip>
          //     ),
          //   };
          // }),
          {
            title: 'Category',
            dataIndex: 'category_name',
            key: 'category_name',
            width: 300,
            sortOrder:
              _get(batchData, 'sortBy') === 'category_name'
                ? _get(batchData, 'sortDir') === 'DESC'
                  ? 'descend'
                  : 'ascend'
                : false,
            ellipsis: true,
            render: (value, record) => value || '-',
          },
          {
            title: 'Sub Category',
            dataIndex: 'subcategory_name',
            key: 'subcategory_name',
            width: 300,
            sortOrder:
              _get(batchData, 'sortBy') === 'subcategory_name'
                ? _get(batchData, 'sortDir') === 'DESC'
                  ? 'descend'
                  : 'ascend'
                : false,
            render: (value, record) =>
              value ? (
                <Paragraph
                  ellipsis={{
                    ...DESCRIPTION_PARAGRAPH_ELLIPSIS,
                    tooltip: value,
                  }}
                  style={{ maxWidth: 260 }}
                  key={record.id}
                >
                  {value}
                </Paragraph>
              ) : (
                '-'
              ),
          },
          {
            title: 'RMR',
            dataIndex: 'rmr',
            key: 'rmr',
            width: 100,
            align: 'right',
            render: (value) => (value ? (+value).toFixed(2) : '-'),
          },
          {
            title: 'RMR Frequency',
            dataIndex: 'rmr_frequency',
            key: 'rmr_frequency',
            width: 200,
            render: (value) => value || '-',
          },
        ];
        break;
      case PAGES.BATCH_ITEMS_UPDATED:
        columns = [
          ...columns,
          {
            title: 'Old Cost',
            key: 'old_price',
            dataIndex: 'old_price',
            width: 100,
            align: 'right',
            render: (value, record) =>
              value
                ? `${
                    getSymbolFromCurrency(
                      _get(batchData, 'Batch.currency_code')
                    ) || _get(batchData, 'Batch.currency_code')
                  }${(+value).toFixed(2)}`
                : '-',
          },
          {
            title: 'New Cost',
            key: 'new_price',
            dataIndex: 'new_price',
            width: 100,
            align: 'right',
            render: (value, record) =>
              value
                ? `${
                    getSymbolFromCurrency(
                      _get(batchData, 'Batch.currency_code')
                    ) || _get(batchData, 'Batch.currency_code')
                  }${(+value).toFixed(2)}`
                : '-',
          },
          {
            title: 'Change',
            key: 'price_diff',
            dataIndex: 'price_diff',
            className: 'highlight',
            width: 100,
            align: 'right',
            render: (value, record) =>
              `${
                getSymbolFromCurrency(_get(batchData, 'Batch.currency_code')) ||
                _get(batchData, 'Batch.currency_code')
              }${value}`,
          },
          {
            title: 'Change Percent',
            key: 'price_diff_percentage',
            dataIndex: 'price_diff_percentage',
            className: 'highlight',
            width: 150,
            align: 'right',
            render: (value, record) => `${value}%`,
          },
          {
            title: 'Category',
            dataIndex: 'category_name',
            key: 'category_name',
            width: 300,
            sortOrder:
              _get(batchData, 'sortBy') === 'category_name'
                ? _get(batchData, 'sortDir') === 'DESC'
                  ? 'descend'
                  : 'ascend'
                : false,
            ellipsis: true,
            render: (value, record) => value || '-',
          },
          {
            title: 'Sub Category',
            dataIndex: 'subcategory_name',
            key: 'subcategory_name',
            width: 300,
            sortOrder:
              _get(batchData, 'sortBy') === 'subcategory_name'
                ? _get(batchData, 'sortDir') === 'DESC'
                  ? 'descend'
                  : 'ascend'
                : false,
            render: (value, record) =>
              value ? (
                <Paragraph
                  ellipsis={{
                    ...DESCRIPTION_PARAGRAPH_ELLIPSIS,
                    tooltip: value,
                  }}
                  style={{ maxWidth: 260 }}
                  key={record.id}
                >
                  {value}
                </Paragraph>
              ) : (
                '-'
              ),
          },
          {
            title: 'RMR',
            dataIndex: 'rmr',
            key: 'rmr',
            width: 100,
            align: 'right',
            render: (value) => (value ? (+value).toFixed(2) : '-'),
          },
          {
            title: 'RMR Frequency',
            dataIndex: 'rmr_frequency',
            key: 'rmr_frequency',
            width: 200,
            render: (value) => value || '-',
          },
        ];
        break;
      default:
        columns = [
          ...columns,
          ..._get(batchData, 'SyncConnector', []).map((item, index) => {
            return {
              title: (
                <Checkbox
                  checked={syncConnAllCheckbox[item.id]}
                  disabled={batchSyncConnectorsDataActionLoading}
                  key={item.id}
                  onChange={(e) =>
                    updateSyncConnectors(null, item.id, e.target.checked)
                  }
                >
                  {item.name}
                </Checkbox>
              ),
              key: item.id,
              dataIndex: 'SyncConnectors',
              width: _get(item, 'name', ' ').length * 8 + 60,
              render: (value, record) => (
                <Tooltip
                  title={
                    item.require_labor && record.labor === null
                      ? 'Labor Hours should be 0 or more'
                      : null
                  }
                >
                  <Checkbox
                    checked={
                      syncConnectorsCheckboxData[_get(record, 'id')][
                        _get(item, 'id')
                      ]
                    }
                    key={`${record.id}-${item.id}`}
                    disabled={
                      batchSyncConnectorsDataActionLoading ||
                      record.status === 'V'
                    }
                    onChange={(e) =>
                      updateSyncConnectors(
                        record.id,
                        item.id,
                        e.target.checked,
                        !!(item.require_labor && record.labor === null)
                      )
                    }
                  />
                </Tooltip>
              ),
            };
          }),
          {
            title: 'Category',
            dataIndex: 'category_name',
            key: 'category_name',
            width: 300,
            sortOrder:
              _get(batchData, 'sortBy') === 'category_name'
                ? _get(batchData, 'sortDir') === 'DESC'
                  ? 'descend'
                  : 'ascend'
                : false,
            ellipsis: true,
            render: (value, record) => value || '-',
          },
          {
            title: 'Sub Category',
            dataIndex: 'subcategory_name',
            key: 'subcategory_name',
            width: 300,
            sortOrder:
              _get(batchData, 'sortBy') === 'subcategory_name'
                ? _get(batchData, 'sortDir') === 'DESC'
                  ? 'descend'
                  : 'ascend'
                : false,
            render: (value, record) =>
              value ? (
                <Paragraph
                  ellipsis={{
                    ...DESCRIPTION_PARAGRAPH_ELLIPSIS,
                    tooltip: value,
                  }}
                  style={{ maxWidth: 260 }}
                  key={record.id}
                >
                  {value}
                </Paragraph>
              ) : (
                '-'
              ),
          },
          {
            title: 'RMR',
            dataIndex: 'rmr',
            key: 'rmr',
            width: 100,
            align: 'right',
            render: (value) => (value ? (+value).toFixed(2) : '-'),
          },
          {
            title: 'RMR Frequency',
            dataIndex: 'rmr_frequency',
            key: 'rmr_frequency',
            width: 200,
            render: (value) => value || '-',
          },
        ];
        break;
    }
    return columns;
  };

  const loadBatchData = () => {
    toggleBatchDataLoading(true);
    let params = {
      ...tablePaginationParams,
    };

    delete params.current;

    let successFn = function (result) {
      let data = { ...result };
      if (activeTab === PAGES.BATCH_ITEMS_DISCONTINUED)
        data = {
          ...result,
          data: _get(result, 'data', []).map((item) => {
            return {
              ...item,
              ..._get(item, 'ItemMasterDistributor', {}),
              price: _get(item, 'ItemMasterDistributor.current_cost'),
              ..._get(item, 'ItemMasterDistributor.ItemMaster', {}),
              vendor_part_num: _get(
                item,
                'ItemMasterDistributor.ItemMaster.manufacturer_item_number'
              ),
            };
          }),
        };
      setBatchData(data);
      toggleBatchDataLoading(false);
      let syncConnectorStates = {};
      _get(result, 'data', []).forEach(function (record) {
        syncConnectorStates[record.id] = {};
        _get(record, 'SyncConnectors', []).forEach(function (syncConn) {
          syncConnectorStates[_get(record, 'id')][_get(syncConn, 'id')] =
            syncConn.is_checked;
        });
      });
      setSyncConnectorsCheckboxData(syncConnectorStates);
    };
    let errorFn = function () {
      toggleBatchDataLoading(false);
    };
    let api = null;
    switch (activeTab) {
      case PAGES.BATCH_ITEMS_NEW:
        api = BATCH_SINGLE_DATA_NEW;
        break;
      case PAGES.BATCH_ITEMS_UPDATED:
        api = BATCH_SINGLE_DATA_UPDATED;
        break;
      case PAGES.BATCH_ITEMS_UNCHANGED:
        api = BATCH_SINGLE_DATA_UNCHANGED;
        break;
      case PAGES.BATCH_ITEMS_DISCONTINUED:
        api = BATCH_SINGLE_DATA_DISCONTINUED;
        break;
      case PAGES.BATCH_ITEMS_ARCHIVED:
        api = ARCHIVED_BATCH_SINGLE_DATA;
        break;
      case PAGES.BATCH_ITEMS_NEW_ARCHIVED:
        api = ARCHIVED_BATCH_SINGLE_DATA_NEW;
        break;
      case PAGES.BATCH_ITEMS_UPDATED_ARCHIVED:
        api = ARCHIVED_BATCH_SINGLE_DATA_UPDATED;
        break;
      case PAGES.BATCH_ITEMS_UNCHANGED_ARCHIVED:
        api = ARCHIVED_BATCH_SINGLE_DATA_UNCHANGED;
        break;
      default:
        api = BATCH_SINGLE_DATA_NEW;
    }
    postAPI(interpolate(api, [id]), params, successFn, errorFn);
  };
  const setTableSortingParams = (
    field,
    order,
    current,
    offset,
    paginationObj
  ) => {
    setTablePaginationParams((prevState) => {
      return {
        ...prevState,
        current,
        offset,
        limit: paginationObj.pageSize,
        sortBy: order ? field : undefined,
        sortDir: order ? (order === 'ascend' ? 'ASC' : 'DESC') : undefined,
      };
    });
  };

  const renderTabs = (type) => {
    const items = [
      {
        key:
          type === ARCHIVE
            ? PAGES.BATCH_ITEMS_NEW_ARCHIVED
            : PAGES.BATCH_ITEMS_NEW,
        label: (
          <>
            New{' '}
            <Tag style={{ color: 'black', borderRadius: 8 }} color={'#E0E0E0'}>
              {_get(batchData, 'Batch.new_count', '-')}
            </Tag>
          </>
        ),
        disabled:
          batchLaborDataActionLoading ||
          batchSyncConnectorsDataActionLoading ||
          batchExcludesDataActionLoading,
      },
      {
        key:
          type === ARCHIVE
            ? PAGES.BATCH_ITEMS_UPDATED_ARCHIVED
            : PAGES.BATCH_ITEMS_UPDATED,
        label: (
          <>
            Updated{' '}
            <Tag style={{ color: 'black', borderRadius: 8 }} color={'#E0E0E0'}>
              {_get(batchData, 'Batch.updated_count', '-')}{' '}
            </Tag>
          </>
        ),
        disabled:
          batchLaborDataActionLoading ||
          batchSyncConnectorsDataActionLoading ||
          batchExcludesDataActionLoading,
      },
      {
        key: PAGES.BATCH_ITEMS_DISCONTINUED,
        label: (
          <>
            Discontinued{' '}
            <Tag style={{ color: 'black', borderRadius: 8 }} color={'#E0E0E0'}>
              {_get(batchData, 'Batch.discontinued_count', '-')}{' '}
            </Tag>
          </>
        ),
        disabled:
          batchLaborDataActionLoading ||
          batchSyncConnectorsDataActionLoading ||
          batchExcludesDataActionLoading,
      },
      {
        key:
          type === ARCHIVE
            ? PAGES.BATCH_ITEMS_UNCHANGED_ARCHIVED
            : PAGES.BATCH_ITEMS_UNCHANGED,
        label: (
          <>
            Unchanged{' '}
            <Tag style={{ color: 'black', borderRadius: 8 }} color={'#E0E0E0'}>
              {_get(batchData, 'Batch.unchanged_count', '-')}{' '}
            </Tag>
          </>
        ),
        disabled:
          batchLaborDataActionLoading ||
          batchSyncConnectorsDataActionLoading ||
          batchExcludesDataActionLoading,
      },
    ];

    if (type === ARCHIVE) {
      items.splice(2, 1);
    }

    return items;
  };
  return (
    <AppBase>
      {props.type === ARCHIVE ? null : (
        <ManufacturerCodeDrawer batchId={id} callback={loadBatchData} />
      )}
      <Title className={'page-title'}>Batch Management</Title>
      <Typography className={'page-title mb-0'} secondary>
        Batch List/
      </Typography>

      <Title className={'page-title mt-0'} level={3}>
        <Link
          to={`/batch?tab=${
            props.type === ARCHIVE ? PAGES.BATCH_ARCHIVED : PAGES.BATCH_ALL
          }`}
        >
          <ArrowLeftOutlined className='mr-10' />
        </Link>
        {props.type === ARCHIVE ? 'Archive ' : null}Batch - {id}{' '}
      </Title>
      <BatchDetailTableTitle
        renderTabs={renderTabs}
        onTabClick={(tab) =>
          Object.keys(laborsToUpdate).length
            ? Modal.confirm({
                title:
                  'You have unsaved data. Do you want to save your changes?',
                onCancel: function () {
                  setLaborsToUpdate({});
                  setActiveTab(tab);
                },
                cancelText: 'Discard Changes',
                onOk: function () {
                  updateLabors();
                  setActiveTab(tab);
                },
                okText: 'Save',
                type: 'warn',
                className: 'confirm-modal',
              })
            : setActiveTab(tab)
        }
        activeTab={activeTab}
        setToggleFilters={setToggleFilters}
        toggleFilters={toggleFilters}
        postBatch={postBatch}
        batchPostActionDataLoading={batchPostActionDataLoading}
        batchDataLoading={batchDataLoading}
        batchLaborDataActionLoading={batchLaborDataActionLoading}
        batchSyncConnectorsDataActionLoading={
          batchSyncConnectorsDataActionLoading
        }
        batchExcludesDataActionLoading={batchExcludesDataActionLoading}
        loadBatchData={loadBatchData}
        laborsToUpdate={laborsToUpdate}
        updateLabors={updateLabors}
        setTablePaginationParams={setTablePaginationParams}
        tablePaginationParams={tablePaginationParams}
        activeFilters={activeFilters}
        setActiveFilters={setActiveFilters}
        manufacturerData={manufacturerData}
        categoryData={categoryData}
        onChange={onChange}
        type={props.type}
      />
      <Table
        className='data-table batch-item-table full-height-table'
        size={'middle'}
        rowKey={'id'}
        virtual={
          batchData && batchData.data && batchData.data.length > 0
            ? true
            : false
        }
        showHeader={true}
        scroll={{ x: findTableLength(props.type), y: window.innerHeight - 450 }}
        rowClassName={(record) =>
          `${
            record?.price_diff_percentage && +record.price_diff_percentage < 0
              ? 'green'
              : 'red'
          } ${record?.status === 'V' ? 'voided' : ''} ${
            record?.is_labor_required ? 'labor-missing' : ''
          }`
        }
        pagination={{
          position: ['bottomRight'],
          hideOnSinglePage: false,
          showSizeChanger: true,
          pageSize: _get(batchData, 'limit', 0),
          total: _get(batchData, 'total', 0),
          current: _get(tablePaginationParams, 'current', 1),
          showQuickJumper: {
            goButton: (
              <input
                type='number'
                aria-label='Page'
                value={gotoPage}
                onChange={(e) => {
                  setGotoPage(e.target.value);
                }}
                style={{ width: '70px' }}
                onKeyDown={(e) => {
                  if (e.key === '.' || e.key === 'e') {
                    e.preventDefault();
                  }
                  if (e.key === 'Enter') {
                    if (
                      checkGotoInput(
                        gotoPage,
                        _get(batchData, 'total', 0),
                        _get(batchData, 'limit', 0)
                      )
                    ) {
                      setTableSortingParams(
                        _get(tablePaginationParams, 'sortBy', undefined),
                        'ascend',
                        gotoPage,
                        (gotoPage - 1) * _get(batchData, 'limit', 0),
                        { pageSize: _get(batchData, 'limit', 0) }
                      );
                    } else {
                      dispatch(
                        setMessage({
                          type: TYPE_ERROR,
                          msg: 'Please enter the correct value',
                        })
                      );
                    }
                  }
                }}
              />
            ),
          },
          locale: {
            jump_to: 'Go to ',
            jump_to_confirm: '',
          },
          pageSizeOptions: [25, 50, 100, 500],
        }}
        key={props.type}
        onChange={(paginationObj, b, sorting) => {
          setTableSortingParams(
            sorting.field,
            sorting.order,
            _get(paginationObj, 'current'),
            (_get(paginationObj, 'current') - 1) * _get(batchData, 'limit'),
            paginationObj
          );
        }}
        dataSource={_get(batchData, 'data', [])}
        columns={tableColumns()}
        loading={
          batchDataLoading ||
          batchSyncConnectorsDataActionLoading ||
          batchExcludesDataActionLoading
        }
      />
    </AppBase>
  );
}

export default BatchDetail;

function findTableLength(type) {
  switch (type) {
    case PAGES.BATCH_ITEMS_NEW:
      return 2600;
    default:
      return 2000;
  }
}
