import { createSlice } from '@reduxjs/toolkit';
import { BAD_CPN_BATCHES } from '../reduxConstant';
import { changeApiLoader } from './apiLoaderSlice';
import { postAPI } from '../../utils/apiRequest';
import {
  BAD_CPN_BATCHES_DATA,
  BAD_CPN_BATCHES_DATA_ITEMS,
} from '../../constants/api';
import { interpolate } from '../../utils/common';
import { isEmpty } from 'lodash';

export const badCpnBatchesSlice = createSlice({
  name: BAD_CPN_BATCHES,
  initialState: {
    modalState: false,
    modalName: '',
    data: {},
    dataItems: [],
    callbackFuncName: '',
    callbackFuncParams: {},
  },
  reducers: {
    changeBadCpnModalState: (state, action) => {
      state.modalState = action.payload;
    },
    changeBadCpnModalName: (state, action) => {
      state.modalName = action.payload;
    },
    setBadCpnData: (state, action) => {
      state.data = action.payload;
    },
    setBadCpnDataItems: (state, action) => {
      state.dataItems = action.payload;
    },
    setBadCpnCallbackFuncName: (state, action) => {
      state.callbackFuncName = action.payload;
    },
    setBadCpnCallbackFuncParams: (state, action) => {
      state.callbackFuncParams = action.payload;
    },
  },
});

export const getBadCpnData = (params) => (dispatch) => {
  dispatch(changeApiLoader(true));

  let successFn = (results) => {
    dispatch(changeApiLoader(false));
    dispatch(setBadCpnData(results));
    if (results.data.length === 0) {
      dispatch(changeBadCpnModalState(false));
    }
  };

  let errorFn = (error) => {
    dispatch(changeApiLoader(false));
  };

  postAPI(BAD_CPN_BATCHES_DATA, params, successFn, errorFn);
};

const updateBadCpnDataItems = (data, arr) => {
  data.forEach((item) => {
    arr.forEach((i) => {
      if (item.id === i.id) {
        item['is_active'] = i.is_active;
        if (i.is_active && !isEmpty(i.connector_part_number)) {
          item['connector_part_number'] = i.connector_part_number;
        }
      }
    });
  });
  return data;
};

export const getBadCpnDataItems = (id, arr) => (dispatch) => {
  dispatch(changeApiLoader(true));

  let successFn = (results) => {
    dispatch(changeApiLoader(false));
    let temp = [...results.data];
    if (arr.length) {
      temp = updateBadCpnDataItems(temp, arr);
    }
    dispatch(setBadCpnDataItems(temp));
  };

  let errorFn = (error) => {
    dispatch(changeApiLoader(false));
  };

  postAPI(
    interpolate(BAD_CPN_BATCHES_DATA_ITEMS, [id]),
    {},
    successFn,
    errorFn
  );
};

export const setBadCpnDataEditCatalog =
  (result, catalogId, syncConnectorId) => (dispatch) => {
    dispatch(
      setBadCpnData({ data: result._recData, catalogId, syncConnectorId })
    );
  };

export const {
  setBadCpnData,
  setBadCpnDataItems,
  changeBadCpnModalState,
  changeBadCpnModalName,
  setBadCpnCallbackFuncName,
  setBadCpnCallbackFuncParams,
} = badCpnBatchesSlice.actions;

export default badCpnBatchesSlice.reducer;
