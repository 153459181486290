import { createSlice } from "@reduxjs/toolkit";
import { CATEGORY_FILTER_DATA } from "../reduxConstant";
import { PRODUCT_CATEGORY_LIST } from "../../constants/api";
import { postAPI } from "../../utils/apiRequest";

export const categoryFilterDataSlice = createSlice({
    name: CATEGORY_FILTER_DATA,
    initialState: {
        data: []
    },
    reducers: {
        setCategoryFilterData: (state, action) => {
            state.data = action.payload
        }
    }
})

export const getCategoryFilterData = () => dispatch => {
    let params = {};
    let successFn = function(result) {
      dispatch(setCategoryFilterData(result.data));
    };
    let errorFn = function() {
    };
    postAPI(PRODUCT_CATEGORY_LIST, params, successFn, errorFn);
}

export const { setCategoryFilterData } = categoryFilterDataSlice.actions;

export default categoryFilterDataSlice.reducer