import React, { useEffect } from 'react';
import { Layout, Row, Col, Tabs } from 'antd';
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router';
import { useSelector } from 'react-redux';
import { _get, _map } from '../../../utils/lodashUtils';
import { hasPermission } from '../../../utils/helper';
import { HeaderTabs } from '../../../constants/headerTabs';
import { ROLE_CATALOG_READ_ONLY_USER } from '../../../constants/roles';

const { Header } = Layout;

function SecondaryHeader(props) {
  let location = useLocation();
  let navigate = useNavigate();
  const roleCode = useSelector((state) => _get(state, 'auth.Role.role_code'));

  const onHandleLink = (event) => {
    navigate(event, { replace: true });
  };

  return (
    <Header
      className='site-layout-background'
      style={{
        top: 63,
        background: '#161616',
        boxShadow: 'rgba(38, 50, 69, 0.2) 0px 2px 4px 0px',
        padding: '0 10px',
        position: 'fixed',
        zIndex: 1,
        width: '100%',
      }}
    >
      <Row>
        <Col>
          <Tabs
            size={'large'}
            onChange={onHandleLink}
            className={'secondary-head-tabs'}
            activeKey={
              location.pathname === '/'
                ? roleCode === ROLE_CATALOG_READ_ONLY_USER
                  ? '/catalog'
                  : '/batch'
                : location.pathname
            }
            items={_map(HeaderTabs, (menu) => {
              return hasPermission(menu.allowedRoles, roleCode) ? menu : null;
            })}
          ></Tabs>
        </Col>
      </Row>
    </Header>
  );
}

export default SecondaryHeader;
