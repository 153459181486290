import React from 'react';
import {
  Row,
  Button,
  Col,
  Form,
  Input,
  Select,
  Space,
  Tabs,
  TreeSelect,
} from 'antd';
import {
  CloseOutlined,
  SlidersOutlined,
  SyncOutlined,
  SaveOutlined,
  SendOutlined,
} from '@ant-design/icons';
import { _get } from '../../../../utils/lodashUtils';
import {
  BATCH_ITEMS_STATUS_FILTER_LIST,
  PAGES_CODE as PAGES,
} from '../../../../constants/hardData';
import ModalCom from '../../../common/Modal';

const { SHOW_CHILD } = TreeSelect;

function BatchDetailTableTitle(props) {
  const {
    renderTabs,
    onTabClick,
    activeTab,
    setToggleFilters,
    toggleFilters,
    postBatch,
    batchPostActionDataLoading,
    batchDataLoading,
    batchLaborDataActionLoading,
    batchSyncConnectorsDataActionLoading,
    batchExcludesDataActionLoading,
    loadBatchData,
    laborsToUpdate,
    updateLabors,
    setTablePaginationParams,
    tablePaginationParams,
    activeFilters,
    setActiveFilters,
    manufacturerData,
    categoryData,
    onChange,
  } = props;

  return (
    <Row justify={'space-between'} style={{ backgroundColor: '#f4f4f4' }}>
      <Col span={18}>
        <Tabs
          className={'system-config-tabs'}
          type={'card'}
          items={renderTabs(props.type)}
          onTabClick={(tab) => onTabClick(tab)}
          activeKey={activeTab}
        />
        <ModalCom />
      </Col>
      <Col>
        <Button size={'large'} onClick={() => setToggleFilters(!toggleFilters)}>
          {toggleFilters ? <CloseOutlined /> : <SlidersOutlined />}
        </Button>
        {activeTab === PAGES.BATCH_ITEMS_NEW ? (
          <Button
            size={'large'}
            type={'secondary'}
            icon={<SendOutlined />}
            style={{ float: 'right' }}
            onClick={postBatch}
            loading={batchPostActionDataLoading}
            disabled={
              batchDataLoading ||
              batchLaborDataActionLoading ||
              batchSyncConnectorsDataActionLoading ||
              batchExcludesDataActionLoading
            }
          >
            Post
          </Button>
        ) : (
          <Button
            size={'large'}
            type={'secondary'}
            icon={<SyncOutlined />}
            onClick={loadBatchData}
          >
            Load
          </Button>
        )}
        {Object.keys(laborsToUpdate).length ? (
          <Button
            size={'large'}
            type={'secondary'}
            icon={<SaveOutlined />}
            onClick={() => {
              updateLabors();
            }}
          >
            Save
          </Button>
        ) : null}
      </Col>
      {toggleFilters && (
        <Col span={24}>
          <Form
            className={'filter-form'}
            onFinish={() =>
              setTablePaginationParams({
                ...tablePaginationParams,
                offset: 0,
                filters: { ...activeFilters },
              })
            }
          >
            <Space className={'mt-10 mb-10 ml-10 mr-10'} wrap={true}>
              <Input
                value={activeFilters.sequence_number}
                placeholder='Line'
                style={{ width: 110 }}
                onChange={(e) =>
                  setActiveFilters((prev) => {
                    return { ...prev, sequence_number: e.target.value };
                  })
                }
              />
              <Select
                optionFilterProp='children'
                showSearch
                value={activeFilters.manufacturer_id}
                placeholder={'Manufacturer'}
                style={{ width: 285 }}
                allowClear
                onChange={(value) =>
                  setActiveFilters((prev) => {
                    return { ...prev, manufacturer_id: value };
                  })
                }
              >
                {_get(manufacturerData, 'data', []).map((item) => (
                  <Select.Option value={item.id} key={item.id}>
                    {item.name}
                  </Select.Option>
                ))}
              </Select>
              <Input
                value={activeFilters.part_number}
                placeholder='Part Number'
                style={{ width: 355 }}
                onChange={(e) =>
                  setActiveFilters((prev) => {
                    return { ...prev, part_num: e.target.value };
                  })
                }
              />
              <Input
                value={activeFilters.description}
                placeholder='Description'
                style={{ width: 290 }}
                onChange={(e) =>
                  setActiveFilters((prev) => {
                    return { ...prev, description: e.target.value };
                  })
                }
              />
              {/* {activeTab === PAGES.BATCH_ITEMS_NEW ?
                     <Select optionFilterProp='children' showSearch value={activeFilters.checked_sc}
                             placeholder={'Sync Connectors'} style={{ width: 200 }} allowClear mode={'multiple'}
                             onChange={(value) => setActiveFilters(prev => {
                               return { ...prev, checked_sc: value };
                             })}>
                       {_get(batchData, 'SyncConnectors', []).map(item => <Select.Option
                         value={item.id}>{item.name}</Select.Option>)}
                     </Select> : null} */}
              {/* {activeTab === PAGES.BATCH_ITEMS_NEW ?
                     <Select optionFilterProp='children' showSearch value={activeFilters.missing_labor_category}
                             placeholder={'Labor Hours'} style={{ width: 200 }} allowClear
                             onChange={(value) => setActiveFilters(prev => {
                               return { ...prev, missing_labor_category: value };
                             })}>
                       <Select.Option value={true}>Missing Labor Category</Select.Option>
                       <Select.Option value={false}>All</Select.Option>
                     </Select> : null} */}
              {activeTab !== PAGES.BATCH_ITEMS_DISCONTINUED ? (
                <Select
                  optionFilterProp='children'
                  showSearch
                  value={activeFilters.status || null}
                  placeholder={'Status'}
                  style={{ width: 200 }}
                  onChange={(value) =>
                    setActiveFilters((prev) => {
                      return {
                        ...prev,
                        status: value ? value : undefined,
                      };
                    })
                  }
                >
                  {BATCH_ITEMS_STATUS_FILTER_LIST.map((item) => (
                    <Select.Option value={item.value} key={item.value}>
                      {item.label}
                    </Select.Option>
                  ))}
                </Select>
              ) : null}
              <TreeSelect
                treeData={categoryData}
                treeCheckable
                placeholder='Select category or Subcategory'
                showCheckedStrategy={SHOW_CHILD}
                onChange={(value, node, extra) => {
                  onChange(value, node, extra);
                }}
                style={{ width: '270px' }}
              />
              <Form.Item>
                <Button type={'primary'} htmlType={'submit'}>
                  Apply
                </Button>
              </Form.Item>
              <Button
                type={'secondary'}
                onClick={() => {
                  setActiveFilters({});
                  setTablePaginationParams({
                    ...tablePaginationParams,
                    filters: {},
                  });
                }}
              >
                Clear
              </Button>
            </Space>
          </Form>
        </Col>
      )}
    </Row>
  );
}

export default BatchDetailTableTitle;
