import { createSlice } from '@reduxjs/toolkit';
import { MESSAGE } from '../reduxConstant';

export const messageSlice = createSlice({
  name: MESSAGE,
  initialState: {
    data: {},
  },
  reducers: {
    setMessage: (state, action) => {
      state.data = action.payload;
    },
    resetMessage: (state, action) => {
      state.data = {};
    },
  },
});

export const { setMessage, resetMessage } = messageSlice.actions;

export default messageSlice.reducer;
