import { createSlice } from '@reduxjs/toolkit';
import { CATALOGS } from '../reduxConstant';
import { postAPI } from '../../utils/apiRequest';
import { CATALOG_LIST } from '../../constants/api';
import { changeApiLoader } from './apiLoaderSlice';
import { _get } from '../../utils/lodashUtils';
import {
  changeBadCpnModalName,
  changeBadCpnModalState,
} from './badCpnBatchesSlice';
import { BATCH } from '../../constants/constants';

export const catalogSlice = createSlice({
  name: CATALOGS,
  initialState: {
    data: {},
    syncConnectorCheckboxData: {},
    syncConnAllCheckbox: {},
  },
  reducers: {
    setCatalogsData: (state, action) => {
      state.data = action.payload;
    },
    setSyncConnectorCheckboxData: (state, action) => {
      state.syncConnectorCheckboxData = action.payload;
    },
    setSyncConnAllCheckbox: (state, action) => {
      state.syncConnAllCheckbox = action.payload;
    },
    resetSyncConnectorCheckboxData: (state, action) => {
      state.syncConnectorCheckboxData = {};
    },
  },
});

export const updateCatalogsData =
  (id, catalogData, syncConnectorsData, activeSyncConnectors, index) =>
  (dispatch) => {
    let obj = { ...syncConnectorsData };
    const catalogRow = catalogData.data[index];
    catalogRow.SyncConnectors.forEach((sc) => {
      obj[`${id}-${sc.sync_connector_id}`] = false;
    });
    activeSyncConnectors.forEach((scID) => {
      obj[`${id}-${scID}`] = true;
    });
    dispatch(setSyncConnectorCheckboxData({ ...obj }));
  };

export const getCatalogsData = (params) => (dispatch) => {
  delete params.current;
  dispatch(changeApiLoader(true));

  let successFn = function (result) {
    dispatch(setCatalogsData(result));
    dispatch(changeApiLoader(false));
    let syncConnectorStates = {};
    _get(result, 'data', []).forEach(function (record) {
      _get(record, 'SyncConnectors', []).forEach(function (syncConn) {
        syncConnectorStates[`${record.id}-${syncConn.sync_connector_id}`] =
          syncConn.is_active;
      });
    });
    dispatch(setSyncConnectorCheckboxData(syncConnectorStates));
    if (result.bad_cpn_flag) {
      dispatch(changeBadCpnModalState(true));
      dispatch(changeBadCpnModalName(BATCH));
    }
  };
  let errorFn = function () {};

  postAPI(CATALOG_LIST, params, successFn, errorFn);
};

export const {
  setCatalogsData,
  setSyncConnectorCheckboxData,
  setSyncConnAllCheckbox,
  resetSyncConnectorCheckboxData,
  setOnlyCatalogsData,
} = catalogSlice.actions;

export default catalogSlice.reducer;
