import React, { useEffect, useState } from 'react';
import AppBase from '../../base/AppBase';
import { Button, Modal, Space, Spin, Table, Typography } from 'antd';
import { postAPI, putAPI } from '../../../utils/apiRequest';
import * as API from '../../../constants/api';
import { _get } from '../../../utils/lodashUtils';
import { maintenanceTableColumns } from '../../../constants/maintenance';
import { generatePaginationObj } from '../../../utils/helper';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { useDispatch } from 'react-redux';
import { setMessage } from '../../../redux/Slices/messageSlice';
import { TYPE_SUCCESS } from '../../../constants/message';
import ModalCom from '../../common/Modal';

const { Title } = Typography;

const Maintenance = () => {
  const [loading, setLoading] = useState(false);
  const [rectificationData, setRectificationData] = useState({});
  const [maintenanceList, setMaintenanceList] = useState({
    data: [],
    pagination: {
      offset: 0,
      limit: 10,
      total: 0,
      current: 1,
    },
  });

  const dispatch = useDispatch();

  const fetchMaintenanceList = (page = 1, limit = 10) => {
    setLoading(true);

    const pageObj = generatePaginationObj(page, limit);

    const successFn = function (res) {
      setLoading(false);
      if (_get(res, 'success')) {
        setMaintenanceList({
          data: _get(res, 'data'),
          pagination: {
            ...pageObj,
            current: page,
            total: _get(res, 'total'),
          },
        });
      }
    };

    const errorFn = function () {
      setLoading(false);
    };

    postAPI(API.MAINTENANCE_LIST, { ...pageObj }, successFn, errorFn);
  };

  const onRectifyCPN = (page) => {
    setLoading(true);
    const data = [];
    for (let key in rectificationData) {
      data.push(rectificationData[key]);
    }

    const successFn = function (res) {
      setLoading(false);
      if (_get(res, 'success')) {
        dispatch(
          setMessage({
            type: TYPE_SUCCESS,
            msg: 'Connector Part Number rectified successfully!',
          })
        );
        fetchMaintenanceList(page || 1);
        setRectificationData({});
      }
    };

    const errorFn = function (error) {
      setLoading(false);
      console.log(error);
    };

    postAPI(API.RECTIFY_CPN_LIST, { data }, successFn, errorFn);
  };

  const updateStatus = (value, id) => {
    // console.log(value);
    setLoading(true);

    const successFn = function (res) {
      setLoading(false);
      // console.log(res);
      fetchMaintenanceList(
        _get(maintenanceList, 'pagination.current'),
        _get(maintenanceList, 'pagination.limit')
      );
    };

    const errorFn = function (err) {
      setLoading(false);
    };

    putAPI(
      API.UPDATE_MAINTENANCE_STATUS,
      { is_active: !value, id },
      successFn,
      errorFn
    );
  };

  const saveCPN = (page) => {
    Modal.confirm({
      title: 'Save Connector Part Number(s)',
      icon: <ExclamationCircleOutlined />,
      content: 'Do you want to save these Connector Part Number(s)?',
      okText: 'Save',
      className: 'confirm-modal',
      onOk: () => {
        fetchMaintenanceList(page);
        onRectifyCPN(page);
      },
      onCancel: () => {
        setRectificationData({});
        fetchMaintenanceList(page);
      },
    });
  };

  useEffect(() => {
    fetchMaintenanceList();
  }, []);

  return (
    <AppBase>
      <Title className={'page-title'}>Validate Connector Part Numbers</Title>
      <Spin spinning={loading}>
        <Table
          rowKey={'id'}
          size={'small'}
          className='data-table full-height-table'
          dataSource={_get(maintenanceList, 'data')}
          columns={maintenanceTableColumns(setRectificationData, updateStatus)}
          pagination={{
            position: ['bottomRight'],
            hideOnSinglePage: true,
            pageSize: _get(maintenanceList, 'pagination.limit'),
            total: _get(maintenanceList, 'pagination.total'),
            current: _get(maintenanceList, 'pagination.current'),
          }}
          onChange={(paginationObj) => {
            if (Object.keys(rectificationData).length) {
              saveCPN(_get(paginationObj, 'current'));
            } else {
              fetchMaintenanceList(_get(paginationObj, 'current'));
            }
          }}
        />
      </Spin>

      <Space size={'middle'} style={{ marginTop: 14 }}>
        <Button
          type='primary'
          size='large'
          onClick={() => onRectifyCPN(1)}
          disabled={_get(maintenanceList, 'data').length < 1}
        >
          Rectify
        </Button>
      </Space>
      <ModalCom />
    </AppBase>
  );
};

export default Maintenance;
