import map from 'lodash/map';
import get from 'lodash/get';

export const DEFAULT_TABLE_PAGINATION_DROPDOWN_PAGE_COUNT = [
  {
    label: 10,
    key: 10,
  },
  {
    label: 20,
    key: 20,
  },
  {
    label: 50,
    key: 50,
  },
  {
    label: 100,
    key: 100,
  },
  {
    label: 500,
    key: 500,
  },
  {
    label: 1000,
    key: 1000,
  },
];

export const BATCH_STATUS_COLOR_CODE = {
  N: { font: '#0D6316', color: '#9EF0A3' },
  E: { font: '#640710', color: '#F89BA4' },
  I: { font: '#1E6F8F', color: '#C3E5F4' },
  RC: { font: '#494504', color: '#F8F120' },
  P: { font: '#0E200E', color: '#47BD4F' },
  S: { font: '#112A04', color: '#7EF43E' },
  R: { font: '#494504', color: '#F8F120' },
  V: { font: '#3A6456', color: '#CBE2DB' },
  PC: { color: '#47BD4F', font: '#0E200E' },
};

export const DESCRIPTION_PARAGRAPH_ELLIPSIS = {
  rows: 1,
  expandable: false,
  symbol: 'More',
};

export const ALL_BATCHES_STATUS_FILTER_LIST = [
  {
    value: 'N',
    label: 'New',
  },
  {
    value: 'E',
    label: 'Error',
  },
  {
    value: 'I',
    label: 'Identified',
  },
  {
    value: 'RC',
    label: 'Receiving',
  },
];
export const ARCHIVED_BATCHES_STATUS_FILTER_LIST = [
  {
    value: 'S',
    label: 'Sent',
  },
  {
    value: 'V',
    label: 'Void',
  },
  {
    value: 'R',
    label: 'Received',
  },
  {
    value: 'P',
    label: 'Processed',
  },
  {
    value: 'N',
    label: 'New',
  },
  {
    value: 'E',
    label: 'Error',
  },
  {
    value: 'I',
    label: 'Identified',
  },
];

export const BATCH_ITEMS_STATUS_FILTER_LIST = [
  {
    value: 'V',
    label: 'Invalid',
  },
  {
    value: 'NV',
    label: 'Valid',
  },
  {
    value: null,
    label: 'All',
  },
];
export const TWO_FA_TYPE_LIST = [
  { label: 'No 2FA', value: 'NONE' },
  { label: 'OTP', value: 'OTP' },
  {
    label: 'TOTP',
    value: 'TOTP',
  },
];

export const PAGES_CODE = {
  BATCH_ALL: 'BATCH_ALL',
  BATCH_ARCHIVED: 'BATCH_ARCHIVE',
  BATCH_ITEMS_ARCHIVED: 'BATCH_ITEMS_ARCHIVE',
  BATCH_ITEMS_NEW: 'BATCH_ITEMS_NEW',
  BATCH_ITEMS_UPDATED: 'BATCH_ITEMS_UPDATED',
  BATCH_ITEMS_DISCONTINUED: 'BATCH_ITEMS_DISCONTINUED',
  BATCH_ITEMS_UNCHANGED: 'BATCH_ITEMS_UNCHANGED',
  BATCH_ITEMS_NEW_ARCHIVED: 'BATCH_ITEMS_NEW_ARCHIVED',
  BATCH_ITEMS_UPDATED_ARCHIVED: 'BATCH_ITEMS_UPDATED_ARCHIVED',
  BATCH_ITEMS_UNCHANGED_ARCHIVED: 'BATCH_ITEMS_UNCHANGED_ARCHIVED',
  CATALOG: 'CATALOG',
  CATAGORIES_MAPPING: 'CATAGORIES_MAPPING',
  CATAGORIES_LABOR: 'CATAGORIES_LABOR',
  MANUFACTURER: 'MANUFACTURER',
  USERS: 'USERS',
  SYSTEM_API_KEYS: 'SYSTEM_API_KEYS',
  SYSTEM_SYNC_CONNECTORS: 'SYSTEM_SYNC_CONNECTORS',
};

export const ACTIVE_INACTIVE_FILTER_LIST = [
  { label: 'Active', value: true },
  {
    label: 'Inactive',
    value: false,
  },
];

export const currencyFormatter = (currency) =>
  new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: currency,
  });

export const getProductCategoryTree = (ProductCategory) =>
  map(ProductCategory, (pc) => ({
    title: get(pc, 'name'),
    value: `category-${get(pc, 'id')}`,
    children: map(get(pc, 'items'), (item) => ({
      title: get(item, 'name'),
      value: `subCategory-${get(item, 'id')}`,
    })),
  }));
