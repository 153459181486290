import React from 'react';
import { connect } from 'react-redux';
import _get from 'lodash/get';
import { Button, Col, Divider, Form, Input, Row, Spin } from 'antd';
import { Link } from 'react-router-dom';
import { loginUserAuthAction, userOrgAction } from '../../../../redux/actions/authReduxActions';
import { globalSiderMenu } from '../../../../redux/actions/globalReducActions';
import { REQUIRED_FIELD_MESSAGE } from '../../../../constants/message';
import AuthBase from '../AuthBase';
import { getOuterAPI, postAPI, postOuterAPI } from '../../../../utils/apiRequest';
import {
  ADMIN_USER_LOGIN,
  ADMIN_VERIFY_OTP,
  ADMIN_VERIFY_TOTP, PORTAL_INFO_BY_UUID,
  VERIFY_OTP,
  VERIFY_TOTP,
} from '../../../../constants/api';

import { withParamsAndNavigate } from '../../../../utils/hoc';
import { set } from 'lockr';
import { APPLICATION_URL, AUTH_ORG_UUID } from '../../../../constants/dataKeys';
import { interpolate } from '../../../../utils/common';

class AdminLogin extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      userName: null,
    };
  }
  checkOrganisation = (values) => {
    let that = this;
    let successFn = function(result) {
      that.props.userOrgAction(result.data);
    };
    let errorFn = function() {
    };
    getOuterAPI(process.env.REACT_APP_MASTER_BACKEND + interpolate(PORTAL_INFO_BY_UUID, [values.org_id]), successFn, errorFn);
  };
  onFinish = (values) => {
    let that = this;
    that.setState({
      loading: true,
    });
    let successFn = function (result) {
      if(result.data.org_id)
        that.checkOrganisation(result.data)
      if(_get(result, 'data.multi_factor_auth_type')==="NONE") {
        that.props.loginUserAuthAction(result.data);
        that.props.history.push('/');
      }
      that.setState({
        loading: false,
        twoFAType:_get(result, 'data.multi_factor_auth_type'),
        loginData:result.data
      });
      that.props.resetGlobalPagesFilter();
    };
    let errorFn = function (error) {
      that.setState({
        loading: false,
      });
    };
    postOuterAPI(process.env.REACT_APP_MASTER_BACKEND + ADMIN_USER_LOGIN, values, successFn, errorFn);
  };
  verifyOtp = (values)=>{
    let that = this;
    that.setState({
      loading: true,
    });
    let reqData = {
      ...values,
      otp_token: this.state.loginData.otp_token
    }
    let successFn = function(result){
      that.props.loginUserAuthAction(result.data);
      that.props.history.push('/');
      that.setState({
        loading: false,
      });
      that.props.resetGlobalPagesFilter();
    }
    let errorFn = function(){
      that.setState({
        loading: false,
      });
    }
    postOuterAPI(process.env.REACT_APP_MASTER_BACKEND + ADMIN_VERIFY_OTP,reqData,successFn,errorFn);
  }
  verifyTotp = (values)=>{
    let that = this;
    that.setState({
      loading: true,
    });
    let reqData = {
      ...values,
      totp_token: this.state.loginData.totp_token
    }
    let successFn = function(result){
      that.props.loginUserAuthAction(result.data);
      that.props.history.push('/');
      that.setState({
        loading: false,
      });
      that.props.resetGlobalPagesFilter();
    }
    let errorFn = function(){
      that.setState({
        loading: false,
      });
    }
    postOuterAPI(process.env.REACT_APP_MASTER_BACKEND + ADMIN_VERIFY_TOTP,reqData,successFn,errorFn);
  }
  render() {
    let { loading, twoFAType } = this.state;

    return (
      <AuthBase>
        <Row>
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <h2 className={'auth-type-title'}>Login to Connect Portal</h2>
            <p className='mt-10-n'>Login as Root User</p>
            {!twoFAType ? <Spin spinning={loading}>
              <Form
                id={'components-form-demo-normal-login'}
                name='normal_login'
                size={'large'}
                className='login-form'
                validateMessages={validateMessages}
                // initialValues={{username: 'admin@admin.com', password: "Password@###"}}
                onFinish={this.onFinish}
                layout='vertical'
              >
                <Form.Item
                  label='Email Id'
                  name='email'
                  rules={[ {
                    type: 'email',
                    message: 'Email ID is not valid',
                  },
                    { required: true, message: 'Email ID is required!' },
                  ]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  name='password'
                  label='Password'
                  rules={[
                    { required: true, message: 'Password is required!' },
                  ]}
                >
                  <Input.Password visibilityToggle={false}/>
                </Form.Item>

                <Form.Item>
                  <Button
                    type={'secondary'}
                    htmlType='submit'
                    className='login-form-button theme-color'
                  >
                    Login
                  </Button>
                </Form.Item>
              </Form>
              <Divider />
              <Link to='/login'> Back</Link>
            </Spin> :null}
            {twoFAType === "OTP" ? <Spin spinning={loading}>
              <p className='mt-10-n'>Please enter the OTP sent to your mail</p>
              <Form
                id={'components-form-demo-normal-login'}
                name='normal_login'
                size={'large'}
                className='login-form'
                validateMessages={validateMessages}
                onFinish={this.verifyOtp}
                layout='vertical'
              >
                <Form.Item
                  label='OTP'
                  name='code'
                  rules={[
                    {required: true, message: REQUIRED_FIELD_MESSAGE},
                  ]}
                >
                  <Input/>
                </Form.Item>

                <Form.Item>
                  <Button
                    type={'secondary'}
                    htmlType='submit'
                    className='login-form-button theme-color'
                  >
                    Login
                  </Button>
                </Form.Item>
              </Form>
            </Spin> : null}
            {twoFAType === "TOTP" ? <Spin spinning={loading}>
              <p className='mt-10-n'>Please enter the TOTP from authenticator app</p>
              <Form
                id={'components-form-demo-normal-login'}
                name='normal_login'
                size={'large'}
                className='login-form'
                validateMessages={validateMessages}
                onFinish={this.verifyTotp}
                layout='vertical'
              >
                <Form.Item
                  label='TOTP'
                  name='code'
                  rules={[
                    {required: true, message: REQUIRED_FIELD_MESSAGE},
                  ]}
                >
                  <Input/>
                </Form.Item>

                <Form.Item>
                  <Button
                    type={'secondary'}
                    htmlType='submit'
                    className='login-form-button theme-color'
                  >
                    Login
                  </Button>
                </Form.Item>
              </Form>
            </Spin> : null}
          </Col>
        </Row>
      </AuthBase>
    );
  }
}

const validateMessages = {
  required: REQUIRED_FIELD_MESSAGE,
};
const mapStateToProps = (state) => {
  return {
    loggedIn: _get(state, 'auth.loggedIn'),
  };
};

const mapDispatchToProps = {
  loginUserAuthAction,
  globalSiderMenu,
  userOrgAction
};

const Wrapper = withParamsAndNavigate(AdminLogin);

export default connect(mapStateToProps, mapDispatchToProps)(Wrapper);
