/***
 * Messages Status Constants
 * */

export const SUCCESS_MSG_TYPE = 'success';
export const WARNING_MSG_TYPE = 'warning';
export const ERROR_MSG_TYPE = 'error';
export const INFO_MSG_TYPE = 'info';
export const SUCCESS_MESSAGE = 'success';
export const AUTH_TOKEN = 'token';
export const AUTH_ORG_UUID = 'org_uuid';
export const APPLICATION_URL = 'portal_backend_url';


/** *
 * Form Data Types
 * */
export const INPUT_FIELD = 'input';
export const RADIO_FIELD = 'radio';
export const SELECT_FIELD = 'select';
export const SEARCH_FIELD = 'search_select';
export const MULTI_SELECT_FIELD = 'multiselect';
export const CHECKBOX_FIELD = 'checkbox';
export const SINGLE_CHECKBOX_FIELD = 'singlecheckbox';
export const NUMBER_FIELD = 'number';
export const DATE_PICKER = 'datepicker';
export const DATE_TIME_PICKER = 'datetimepicker';
export const TEXT_FIELD = 'textfield';
export const TIME_PICKER = 'timepicker';
export const COLOR_PICKER = 'colorpicker';
export const QUILL_TEXT_FIELD = 'quilltextfield';
export const SINGLE_IMAGE_UPLOAD_FIELD = 'singleimageupload';
export const MULTI_IMAGE_UPLOAD_FIELD = 'multiimageupload';
export const COUNTRY_FIELD = 'country';
export const STATE_FIELD = 'state';
export const CITY_FIELD = 'city';
export const PASSWORD_FIELD = 'password';
export const EMAIL_FIELD = 'email';
export const SMS_FIELD = 'sms_field';
export const DIVIDER_FIELD = 'divider_field';
export const MAIL_TEMPLATE_FIELD = 'mail_field';
export const LABEL_FIELD = 'label_field';
export const RATE_FIELD = 'rate_field';
export const FRAME_VIEW = 'frame';

/** *
 * Form Action Type Constants
 * */
export const FORM_POST_ACTION = 'post';
export const FORM_PUT_ACTION = 'put';

