import {
  Button,
  Card,
  Col,
  Form,
  InputNumber,
  Row,
  Spin,
  Typography,
} from 'antd';
import React, { useEffect, useState } from 'react';
import { getAPI, patchAPI } from '../../../utils/apiRequest';
import { GENERAL_SYSTEM } from '../../../constants/api';
import { REQUIRED_FIELD_WITH_NAME_MESSAGE } from '../../../constants/message';

const { Title } = Typography;

function GeneralSystem() {
  // Keeping the code of the General Tab
  const [generalData, setGeneralData] = useState({});
  const [generalDataLoading, toggleGeneralDataLoading] = useState(false);
  useEffect(() => {
    loadConfiguration();
  }, []);
  const loadConfiguration = () => {
    toggleGeneralDataLoading(true);
    let successFn = function (result) {
      setGeneralData(result.data || {});
      toggleGeneralDataLoading(false);
    };
    let errorFn = function () {
      toggleGeneralDataLoading(false);
    };
    getAPI(GENERAL_SYSTEM, successFn, errorFn);
  };
  const saveConfiguration = (values) => {
    let successFn = function (result) {
      toggleGeneralDataLoading(false);
    };
    let errorFn = function () {
      toggleGeneralDataLoading(false);
    };
    patchAPI(GENERAL_SYSTEM, values, successFn, errorFn);
  };
  return (
    <Card>
      <Row>
        <Col xs={24} md={6}>
          <Title level={2} className={'page-title'}>
            General
          </Title>
          <Spin spinning={generalDataLoading}>
            <Form
              layout='vertical'
              initialValues={{ ...generalData }}
              key={Object.values(generalData).join(',')}
              onFinish={saveConfiguration}
            >
              <Form.Item
                label={'ID Seed'}
                name={'id_seed'}
                rules={[
                  { required: true, message: REQUIRED_FIELD_WITH_NAME_MESSAGE },
                ]}
              >
                <InputNumber min={1} style={{ width: '100%' }} />
              </Form.Item>
              {/*<Form.Item label={'Automatic Batch Import Interval'} key={'seed'}>*/}
              {/*  <InputNumber style={{ width: '100%' }} />*/}
              {/*</Form.Item>*/}
              {/*<Form.Item label={'Automatic Batch Import'} key={'seed'}>*/}
              {/*  <Switch />*/}
              {/*</Form.Item>*/}
              <Form.Item>
                <Button type={'primary'} htmlType='submit'>
                  Save
                </Button>
              </Form.Item>
            </Form>
          </Spin>
        </Col>
      </Row>
    </Card>
  );
}

export default GeneralSystem;
