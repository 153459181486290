import AppBase from '../../base/AppBase';
import { Tabs, Typography } from 'antd';
import React from 'react';
import ProductCategoriesList from './ProductCategories';
import LaborManagement from './LaborCategories';
import DistributorCategories from './DistributorCategories';

const { Title } = Typography;

function ProductCategories() {
  return (
    <AppBase>
      <Title className={'page-title'}>Categories</Title>
      <Tabs
        type='card'
        className={'system-config-tabs'}
        destroyInactiveTabPane={true}
        items={[
          {
            key: 'product',
            label: 'Product',
            children: <ProductCategoriesList />,
          },
          {
            key: 'mapping',
            label: 'Product Map',
            children: <DistributorCategories />,
          },
          {
            key: 'labor',
            label: 'Labor',
            children: <LaborManagement />,
          },
        ]}
      />
    </AppBase>
  );
}

export default ProductCategories;
